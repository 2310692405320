import { Divider } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/Images/magarsmajLogo.png";
import FinancialDisclaimer from "./FinancialDisclaimer";
// import ShareBtn from "react-share-button";

// const url = "magar_society_mainpage/general_meeting_notice"
// const text="Click the link below to see Invitaion/Announcement for upcoming General Meeting."

export default function GeneralMeetingNotice() {
  return (
    <div className="generalMeetingMain_container">
      <Link to="/magar_society_mainpage" onClick={() => window.scrollTo(0, 0)}>
        <button className="back_button">Back to Magar Society Page</button>
      </Link>
      <div className="generalMeetingNoticeHeader_container">
        <img src={logo} alt="" className="generalMeetingNotice_logoImage" />
        <div>
          <h1 className="generalMeetingNoticeHeaderText">
            ह्यारिसबर्ग मगर गुमस्ठ
          </h1>
        </div>
      </div>
      <h3 className="generalMeetingNoticeSubHeaderText">
        *** Board Meeting ***
      </h3>
      <Divider
        style={{
          backgroundColor: "white",
          maxWidth: "1124px",
          margin: "20px auto",
        }}
      />
      <p className="generalMeetingNotice_para">आदरणीय गुमस्ठ बोर्ड सदस्यहरु,</p>
      <p className="generalMeetingNotice_para">
        हामीलाई अहिले समयमै सदस्य शुल्क र अन्त्येष्टि संकलन गर्न गाह्रो भइरहेको
        छ । सबै सदस्यहरूलाई सदस्य शुल्क र अन्त्येष्टि सङ्कलन गर्न सजिलो तरिका
        कसरी बनाउने भन्ने बारे छलफल गर्न हामीले चाडो बैठक गर्न आवश्यक छ। म यो
        सोमबार पछि शहर बाहिर हुनेछु र म कति चाँडो फर्कन्छु भन्ने निश्चित छैन।
        त्यसैले भोलि <strong style={{ color: "orange" }}>दिउँसो २ बजे</strong>{" "}
        कोषाध्यक्षको घरमा{" "}
        <strong style={{ color: "orange" }}>
          (5661 Fordham Ave. Harrisburg, PA-17111)
        </strong>{" "}
        हुने बैठकमा उपस्थित हुनको लागि दुई घण्टा समय निकल्नुहोला। आशा छ हामी
        सबैको उपस्थिति हुनेछ। छोटो सूचनाको लागि माफ गर्नुहोस्।
      </p>

      <p className="generalMeetingNotice_para">धन्यवाद।</p>
      <p className="generalMeetingNotice_para">बल आले मगर</p>
      <p className="generalMeetingNotice_para">महासचिव</p>
      <p className="generalMeetingNotice_para">ह्यारिसबर्ग मगर गुमस्ठ</p>
      <p className="generalMeetingNotice_para">ह्यारिसबर्ग, पेन्सिलभेनिया</p>
      {/* <ShareBtn url={url} text={text} className="ib" displayText="Share this Announcement" /> */}
      <Divider
        style={{
          backgroundColor: "white",
          maxWidth: "1124px",
          margin: "20px auto",
        }}
      />
      {/* <FinancialDisclaimer /> */}
    </div>
  );
}
