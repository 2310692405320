import { StickyShareButtons } from "sharethis-reactjs";
export default function App() {
  return (
    <div className="App">
      <div>
        <StickyShareButtons
          config={{
            alignment: "right", // alignment of buttons (left, right)
            color: "social", // set the color of buttons (social, white)
            enabled: true, // show/hide buttons (true, false)
            font_size: 16, // font size for the buttons
            hide_desktop: false, // hide buttons on desktop (true, false)
            labels: "counts", // button labels (cta, counts, null)
            language: "en", // which language to use (see LANGUAGES)
            min_count: 0, // hide react counts less than min_count (INTEGER)
            networks: [
              // which networks to include (see SHARING NETWORKS)
              "linkedin",
              "facebook",
              "twitter",
              "pinterest",
              "email"
            ],
            padding: 12, // padding within buttons (INTEGER)
            radius: 4, // the corner radius on each button (INTEGER)
            show_total: true, // show/hide the total share count (true, false)
            show_mobile: true, // show/hide the buttons on mobile (true, false)
            show_toggle: true, // show/hide the toggle buttons (true, false)
            size: 45, // the size of each button (INTEGER)
            top: 100, // offset in pixels from the top of the page

            // OPTIONAL PARAMETERS
            url: "https://magarbhasalipi.com/vowels", // (defaults to current url)
            image: "https://github.com/rob2rhyme/My-Portfolio/blob/master/webShot.png?raw=true", // (defaults to og:image or twitter:image)
            description: "Checkout this Wonderful website about Akkha-Rika Vowels and many more", // (defaults to og:description or twitter:description)
            title: "Checkout this Wonderful website about Akkha-Rika Vowels and many more ", // (defaults to og:title or twitter:title)
            message: "Checkout this Wonderful website about Akkha-Rika Vowels and many more https://magarbhasalipi.com/vowels", // (only for email sharing)
            subject: "Webite-Akkha Rika Vowels", // (only for email sharing)
            // username: "custom twitter handle", // (only for twitter sharing)
            // quote: "Checkout this Wonderful website about Akkha-Rika Vowels and many more",
          }}
        />
      </div>
    </div>
  );
}
