import "./msHarrisburg.css";
import React, { Component } from "react";
import buddha_jayanti from "../assets/Images/buddha-jayanti.jpeg";
import dhiraj_samal from "../assets/Images/dhiraj.JPG";
import kul_darlami from "../assets/Images/kuldarlami.JPG";
import pampaLamichhane_Image from "../assets/Images/pampa_lamichhane.jpg";
import Lamichhane_BabyGirl from "../assets/Images/lamichhane-baby-girl.jpg";
import GeneralMeetingImage from "../assets/Images/General Meeting_500x230.jpg";
import FestivalImage from "../assets/Images/festival_500x230.jpg";
import dhanMayaLungeli from "../assets/Images/dhanMayaLungeli.jpg";
import bamaConvention from "../assets/Images/bama_Convention.jpg";
import feeCollection from "../assets/Images/fee-collection.png";

// import Divider from "@material-ui/core/Divider";
import { Link } from "react-router-dom";

export default class Card extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  handleOpenModal() {
    this.setState({ showModal: true });
  }
  handleCloseModal() {
    this.setState({ showModal: false });
  }
  render() {
    return (
      <>
        <div className="eventcard_maincontainer">
          <h3
            style={{
              fontFamily: "Arial, Helvetica, sans-serif",
              marginBottom: "15px",
            }}
          >
            Upcoming Event
          </h3>
          <div className="eventcards">
          <Link
              to="/magar_society_mainpage/GeneralMeetingNoticeforMaghaySakranti2023"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img
                  src={GeneralMeetingImage}
                  alt=""
                  className="eventcard_image"
                />
                <div className="eventcard-body">
                  <p className="eventTitle">General Meeting</p>
                  <p className="eventDate">December 25, 2022 @ 10.30 AM</p>

                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
          </div>
        </div>

        <div className="eventcard_maincontainer">
          <h3
            style={{
              fontFamily: "Arial, Helvetica, sans-serif",
              marginBottom: "15px",
            }}
          >
            Past Events
          </h3>
          <div className="eventcards">
            <Link
              to="/magar_society_mainpage/general_meeting_notice_Sept24"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img
                  src={GeneralMeetingImage}
                  alt=""
                  className="eventcard_image"
                />
                <div className="eventcard-body">
                  <p className="eventTitle">Board Meeting</p>
                  <p className="eventDate">Nov 19, 2022 @ 9 AM</p>

                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
          <Link
              to="/magar_society_mainpage/general_meeting_notice_Sept24"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img
                  src={GeneralMeetingImage}
                  alt=""
                  className="eventcard_image"
                />
                <div className="eventcard-body">
                  <p className="eventTitle">General Meeting</p>
                  <p className="eventDate">September 24, 2022 @10.30 AM</p>

                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
          <Link
              to="/magar_society_mainpage/pampa_Lamichhane_Obituary"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img src={pampaLamichhane_Image} alt="" className="eventcard_image" />
                <div className="eventcard-body">
                  <p className="eventTitle">Obituary</p>
                  <p style={{ color: "gray" }}>Pampa Lamichhane Magar</p>
                  {/* <p className="eventDate">Passed away: August 1, 2022</p> */}
                  <p className="eventDate">
                    <strong>Passed Away:08/01/2022</strong>
                    <i>***90 Years old***</i>
                  </p>

                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
            <Link
              to="/magar_society_mainpage/Member_Fee_Notification"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img src={feeCollection} alt="" className="eventcard_image" />
                <div className="eventcard-body">
                  <p className="eventTitle">Notification</p>
                  <p style={{ color: "gray" }}>Member Fee Collection</p>

                  <p className="eventDate">July 21th, 2022</p>

                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
          <Link
              to="/magar_society_mainpage/HarrisburgMagarGumusthaBoardMeetingNotice"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img
                  src={GeneralMeetingImage}
                  alt=""
                  className="eventcard_image"
                />
                <div className="eventcard-body">
                  <p className="eventTitle">
                    Board Meeting (Board Members only)
                  </p>
                  <p style={{ color: "gray" }}>Some Important discussion</p>

                  <p className="eventDate">July 24, 2022</p>

                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
            <Link
              to="/magar_society_mainpage/bama_ConventionInvitation"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img src={bamaConvention} alt="" className="eventcard_image" />
                <div className="eventcard-body">
                  <p className="eventTitle">Invitation</p>
                  <p style={{ color: "gray" }}>3rd Magar National Convention</p>

                  <p className="eventDate">July 16 & 17th, 2022 @ 8am-4pm</p>

                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
            <Link
              to="/magar_society_mainpage/general_meeting_notice_july2022"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img
                  src={GeneralMeetingImage}
                  alt=""
                  className="eventcard_image"
                />
                <div className="eventcard-body">
                  <p className="eventTitle">General Meeting</p>
                  <p className="eventDate">July 3, 2022</p>

                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
            <Link
              to="/magar_society_mainpage/lamichhane_baby_girl"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img
                  src={Lamichhane_BabyGirl}
                  alt=""
                  className="eventcard_image"
                />
                <div className="eventcard-body">
                  <p className="eventTitle">Obituary</p>
                  <p style={{ color: "gray" }}>Lamichhane Baby-Girl</p>
                  {/* <p className="eventDate">Passed away: Jan 16, 2022</p> */}
                  <p className="eventDate">
                    <strong>Funeral Service:06/13/2022</strong>
                    <br />
                    <i>2100 Linglestown Rd. Harrisburg, PA 17110</i>
                  </p>

                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
            <Link
              to="/magar_society_mainpage/kul_darlami_obituary"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img src={kul_darlami} alt="" className="eventcard_image" />
                <div className="eventcard-body">
                  <p className="eventTitle">Obituary</p>
                  <p style={{ color: "gray" }}>Kul Darlami Magar</p>
                  {/* <p className="eventDate">Passed away: Jan 16, 2022</p> */}
                  <p className="eventDate">
                    <strong>Passed Away:05/14/2022</strong>
                    <i>***72 Years old***</i>
                  </p>

                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
            <Link
              to="/magar_society_mainpage/buddha_jayanti_invitation_May_2022"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img src={buddha_jayanti} alt="" className="eventcard_image" />
                <div className="eventcard-body">
                  <p className="eventTitle">Buddha Jayanti</p>
                  <p className="eventDate">May 15th, 2022</p>

                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
            <Link
              to="/magar_society_mainpage/general_meeting_notice_April2022"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img
                  src={GeneralMeetingImage}
                  alt=""
                  className="eventcard_image"
                />
                <div className="eventcard-body">
                  <p className="eventTitle">General Meeting</p>
                  <p className="eventDate">April 24, 2022</p>

                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
            <Link
              to="/magar_society_mainpage/dhiraj_samal_obituary"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img src={dhiraj_samal} alt="" className="eventcard_image" />
                <div className="eventcard-body">
                  <p className="eventTitle">Obituary</p>
                  <p style={{ color: "gray" }}>Dhiraj Samal Magar</p>
                  {/* <p className="eventDate">Passed away: Jan 16, 2022</p> */}
                  <p className="eventDate">
                    Birth:<strong>09/27/1987</strong>-<i>***34 Years***</i>-
                    <strong>Passed Away:01/18/2022</strong>
                  </p>

                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
            <Link
              to="/magar_society_mainpage/obituary_dhanMayaLungeli"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img
                  // src={GeneralMeetingImage}
                  src={dhanMayaLungeli}
                  alt=""
                  className="eventcard_image"
                />
                <div className="eventcard-body">
                  <p className="eventTitle">Obituary</p>
                  <p style={{ color: "gray" }}>Dhan Maya Lungeli</p>
                  {/* <p className="eventDate">Passed away: Jan 16, 2022</p> */}
                  <p className="eventDate">
                    Funeral Service: Jan 18, 2022 @ 1.30pm
                  </p>

                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
            <Link
              to="/magar_society_mainpage/maghaysakranti_2022_invitation"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img src={FestivalImage} alt="" className="eventcard_image" />
                <div className="eventcard-body">
                  <p className="eventTitle">Maghay Sakranti</p>
                  <p style={{ color: "red" }}>Cancelled due to COVID</p>
                  <p className="eventDate">January 16, 2022</p>
                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
            <Link
              to="/magar_society_mainpage/general_meeting_notice"
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="eventcard">
                <img
                  src={GeneralMeetingImage}
                  alt=""
                  className="eventcard_image"
                />
                <div className="eventcard-body">
                  <p className="eventTitle">General Meeting</p>
                  <p className="eventDate">January 2, 2022</p>

                  <button className="event_button">More Details here</button>
                </div>
              </div>
            </Link>
            {/* <Link
            to="/magar_society_mainpage/maghaysakranti_2022_invitation"
            onClick={() => window.scrollTo(0, 0)}
          >
            <div className="eventcard">
              <img src={FestivalImage} alt="" className="eventcard_image" />
              <div className="eventcard-body">
                <p className="eventTitle">Maghay Sakranti</p>
                <p className="eventDate">January 16, 2022</p>
                <button className="event_button">More Details here</button>
              </div>
            </div>
          </Link> */}
          </div>
        </div>
      </>
    );
  }
}
