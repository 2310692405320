import React from "react";
import "./Punctuation.css";

function GridRowPunctuationApp({
  akkha_Words,
  Nep_Words,
  Nep_Meaning,
  Eng_Meaning
}) {
  return (
    <tr className="Punctuation_Words_tableRow">
        <td className="Punctuation_akkha_Words">{akkha_Words}</td>
        <td className="Punctuation_Nep_Words">{Nep_Words}</td>
      <td className="Punctuation_Nep_Meaning">{Nep_Meaning}</td>
      <td className="Punctuation_Eng_Meaning">{Eng_Meaning}</td>
    </tr>
  );
}

export default GridRowPunctuationApp;
