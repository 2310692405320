import React from "react";
import './Okaar.css'
import GridRowVowelOkaar from './GridRowVowelOkaar';
import VowelOkaarData from './VowelOkaarData.json';

export default function VowelOkaar() {
  const VowelOkarContent = VowelOkaarData.map(
    ({ id, akkha_VowelOkar, kantipur_VowelOkar, Nep_Meaning, Eng_Meaning }) => (
      <GridRowVowelOkaar
        id={id}
        akkha_VowelOkar={akkha_VowelOkar}
        kantipur_VowelOkar={kantipur_VowelOkar}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );
  return (
    <div className="Vowel_Okar_Container">
      <h3 className="Vowel_Okar_Heading">
        {/* <p className="matra">q</p> */}
        "ाे" ओकरलाई प्रयोग गर्दा बनिने शब्दहरु:</h3>
      <div className="Okar_table_div">
        <table align="center">
        <thead className="Okar_tableHeader">
            <tr>
              <th className="Okar_heading_table">Akkha</th>
              <th className="Okar_heading_table">Nepali Trans</th>
              <th className="Okar_heading_table">Nepali Meaning</th>
              <th className="Okar_heading_table">English Meaning</th>
            </tr>
          </thead>
          <tbody className="eachCells">{VowelOkarContent}</tbody>
        </table>
      </div>
    </div>
  );
}
