import React from "react";
import "./NumeralAdjective.css";

function GridRowNumeralsAdjApp({
  id,
  akkha_Words,
  Nep_Words,
  Nep_Meaning,
  Eng_Meaning
}) {

  return (
    <tr className="NumeralsAdj_Words_tableRow">
        <td className="NumeralsAdj_akkha_Words">{akkha_Words}</td>
        <td className="NumeralsAdj_Nep_Words">{Nep_Words}</td>
      <td className="NumeralsAdj_Nep_Meaning">{Nep_Meaning}</td>
      <td className="NumeralsAdj_Eng_Meaning">{Eng_Meaning}</td>
    </tr>
  );
}

export default GridRowNumeralsAdjApp;
