import React from "react";
import '../Halfforms.css'
import Half_Form_ReeData from './Half_forms_ReeData.json';
import Half_Form_ReeWordsData from './Half_forms_ReeWordsData.json';
import GridRowHalfFormsRee from "./GridRowHalf_forms_Ree";
import GridRowHalfFormsReeWords from "./GridRowHalf_forms_ReeWords";


export default function Half_Forms_HaApp() {
  const Half_forms_ReeContent = Half_Form_ReeData.map(
    ({ id, akkha, nepali, akkha1, nepali1, akkha2, nepali2 }) => (
      <GridRowHalfFormsRee
        id={id}
        akkha={akkha}
        nepali={nepali}
        akkha1={akkha1}
        nepali1={nepali1}
        akkha2={akkha2}
        nepali2={nepali2}
      />
    )
  );

  const Half_forms_ReeWordsContent = Half_Form_ReeWordsData.map(
    ({ id, akkha_Words, Nep_Words, Nep_Meaning, Eng_Meaning }) => (
      <GridRowHalfFormsReeWords
        id={id}
        akkha_Words={akkha_Words}
        Nep_Words={Nep_Words}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );

  return (
    <div className="HalfFormsRee_bcg">
      <h2 className="halfForms_Heading_text">अर्ध अक्षर "र + इ/ई"= "ृ"</h2>
      <div className="halfForms_top_part">
        <table align="center">
        <thead className="halfForms_tableHeader">
            <tr>
              <th className="halfForms_heading_table">Akkha</th>
              <th className="halfForms_heading_table">Nepali Trans</th>
              <th className="halfForms_heading_table">Akkha</th>
              <th className="halfForms_heading_table">Nepali Trans</th>
              <th className="halfForms_heading_table">Akkha</th>
              <th className="halfForms_heading_table">Nepali Trans</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_first_eachCells">{Half_forms_ReeContent}</tbody>
        </table>
      </div>
      <h2 className="halfForms_Heading_text">अर्ध अक्षर "ृ" द्वारा बनिने शब्दहरु:</h2>
      <div className="halfForms_bottom_part">
        <table align="center">
        <thead className="halfForms_tableHeader">
            <tr>
              <th className="halfForms_heading_table1">Akkha Word</th>
              <th className="halfForms_heading_table1">Nepali Trans</th>
              <th className="halfForms_heading_table1">Nepali Meaning</th>
              <th className="halfForms_heading_table1">English Meaning</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_second_eachCells">{Half_forms_ReeWordsContent}</tbody>
        </table>
      </div>
    </div>
  );
}
