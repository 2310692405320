import React from "react";
import './SevenDays.css'
import SevenDays_Data from './SevenDaysData.json';
import GridRowSevenDaysWords from "./GridRowSevenDays";


export default function SevenDaysApp() {
  const SevenDaysContent = SevenDays_Data.map(
    ({ id, akkha_Words, Nep_Words, Nep_Meaning, Eng_Meaning }) => (
      <GridRowSevenDaysWords
        id={id}
        akkha_Words={akkha_Words}
        Nep_Words={Nep_Words}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );

  return (
    <div className="SevenDays_bcg">
      <h2 className="basicTerms_headingText">7 Days-Week (७ बारका नाम)</h2>
      <div className="basicTerms_table_div">
        <table align="center">
        <thead className="basicTerms_tableHeader">
            <tr>
              <th className="SevenDays_heading_table">Akkha Words</th>
              <th className="SevenDays_heading_table">Nepali Trans</th>
              <th className="SevenDays_heading_table">Nepali Meaning</th>
              <th className="SevenDays_heading_table">English Meaning</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_second_eachCells">{SevenDaysContent}</tbody>
        </table>
      </div>
    </div>
  );
}
