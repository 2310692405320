import React from 'react'
import clsx from 'clsx'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/styles'
import { Divider, Drawer } from '@material-ui/core'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import KeyboardIcon from '@material-ui/icons/Keyboard'
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary'
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary'
import TimelineIcon from '@mui/icons-material/Timeline'
// import HomeIcon from "@material-ui/icons/Home";
import FormatListNumberedRtlIcon from '@material-ui/icons/FormatListNumberedRtl'
import AppleIcon from '@material-ui/icons/Apple'
import AndroidIcon from '@material-ui/icons/Android'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import DashboardIcon from '@material-ui/icons/Dashboard'
import { SidebarNav } from './components'
import iOSphoneDisplay from '../../../../assets/Images/ios.PNG'
import AndroidphoneDisplay from '../../../../assets/Images/android.png'
import Drawer_Background from '../../../../assets/Images/carbon-fibre-1920x1080.png'
// import ShareButton from "../../../../components/Sharebuttons";

// const iframe ='<iframe style="width:118px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=magarbhasalip-20&marketplace=amazon&amp;region=US&placement=B078Z4HBFG&asins=B078Z4HBFG&linkId=330ef655451f54cdf325fd07d0ba13d1&show_border=true&link_opens_in_new_window=true&price_color=4ae6c2&title_color=e4eaf2&bg_color=665a5a"></iframe>';
const iframe =
  '<iframe src="//rcm-na.amazon-adsystem.com/e/cm?o=1&p=9&l=ez&f=ifr&linkID=e9a4e6d783b35ca3238adaeb9f2472c6&t=magarbhasalip-20&tracking_id=magarbhasalip-20" width="200" height="200" scrolling="no" border="0" marginwidth="0" style="border:none;" frameborder="0"></iframe>'
// const iframe2 = '<iframe style="width:118px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=magarbhasalip-20&marketplace=amazon&amp;region=US&placement=B07S1HKJY6&asins=B07S1HKJY6&linkId=11156258361ec5e53adaeb18c1a037eb&show_border=true&link_opens_in_new_window=true&price_color=4ae6c2&title_color=e4eaf2&bg_color=665a5a"></iframe>'
// const iframe3 = '<iframe style="width:118px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=magarbhasalip-20&marketplace=amazon&amp;region=US&placement=B00FRSYS12&asins=B00FRSYS12&linkId=2b75712b036e92b15e4748676fe1f949&show_border=true&link_opens_in_new_window=true&price_color=4ae6c2&title_color=e4eaf2&bg_color=665a5a"></iframe>'
// const iframe4 = '<iframe style="width:118px;height:240px;" marginwidth="0" marginheight="0" scrolling="no" frameborder="0" src="//ws-na.amazon-adsystem.com/widgets/q?ServiceVersion=20070822&OneJS=1&Operation=GetAdHtml&MarketPlace=US&source=ac&ref=tf_til&ad_type=product_link&tracking_id=magarbhasalip-20&marketplace=amazon&amp;region=US&placement=B00DNBI7GM&asins=B00DNBI7GM&linkId=a98416833aed512c071db27b5f365073&show_border=true&link_opens_in_new_window=true&price_color=4ae6c2&title_color=e4eaf2&bg_color=665a5a"></iframe>'

const iOSUrl =
  'https://apps.apple.com/us/app/magar-dhut-akkha-rika/id1577904020'
const androidUrl =
  'https://play.google.com/store/apps/details?id=com.rob2rhyme.akkharikalipi'
const mailUrl =
  'mailto:magarbhasalipi@gmail.com?subject=Website-Akkha-Rika-Lipi-Sipal&body=your text here..'

function Iframe(props) {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }}
      // dangerouslySetInnerHTML={{ __html: props.iframe2 ? props.iframe2 : "" }}
    />
  )
}

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: 240,
    [theme.breakpoints.up('lg')]: {
      marginTop: 64,
      height: 'calc(100% - 64px)',
    },
    backgroundImage: `url(${Drawer_Background})`,
    // backgroundColor: "#174444",
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: theme.spacing(1.5),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  last_iframe: {
    padding: 30,
  },
  nav: {
    marginBottom: theme.spacing(1),
    fontSize: '35',
  },
  version: {
    color: 'aliceblue',
    fontFamily: 'arial',
  },

  menuHeading: {
    color: '#a5a500',
    fontFamily: 'fantasy',
    textAlign: 'center',
    fontWeight: 900,
  },

  disclaimer_container: {
    backgroundColor: 'dodgerblue',
    borderRadius: 10,
    padding: 8,
    color: 'white',
  },
  disclaimer_headingText: {
    color: 'gold',
    fontFamily: 'Tahoma',
    fontSize: 12,
  },
  disclaimer_para: {
    color: 'aliceblue',
    fontFamily: 'Tahoma',
    fontSize: 13,
    textAlign: 'justify',
  },
  appstore_icons: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'darkcyan',
    padding: 10,
    borderRadius: '0 0 15px 15px',
  },
  storeIcons: {
    height: '25px',
    color: 'aliceblue',
    marginRight: 15,
    marginLeft: 15,
    webkitAnimation: 'pulse 1.5s infinite',
    '&:hover': {
      color: 'orange',
    },
  },

  iOSphonedisplay: {
    borderRadius: 5,
    height: '100%',
    width: '31%',
    margin: 5,
  },
  androidPhonedisplay: {
    borderRadius: 5,
    height: '85%',
    width: '25%',
    margin: 5,
  },
  phoneDisplay_Container: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  storeTexts: {
    color: 'aliceblue',
    fontWeight: '900',
    fontFamily: 'Tahoma',
    marginBottom: 8,
    padding: 10,
  },
  storeTexts1: {
    color: 'aliceblue',
    fontWeight: '700',
    fontFamily: 'Tahoma',
    textAlign: 'center',
    // marginBottom: 8,
    // padding: 10,
  },
  appShare_container: {
    backgroundColor: '#ad66a9',
    borderRadius: '0 0 15px 15px',
  },

  communicationContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    backgroundColor: 'darkcyan',
    padding: 3,
    lineHeight: 1.8,
    borderRadius: '10px',
    fontFamily: 'Arial',
  },
  mediaIcon: {
    color: 'aliceblue',
    marginRight: 15,
    marginLeft: 15,
    webkitAnimation: 'pulse 1.5s infinite',
    '&:hover': {
      color: 'tomato',
    },
  },
}))

const SideBar = (props) => {
  const { open, variant, onClose, className, ...rest } = props

  const classes = useStyles()

  const pages = [
    {
      title: 'Dashboard',
      href: '/home',
      icon: <DashboardIcon />,
    },
    {
      title: 'Consonant',
      href: '/consonant',
      icon: <MenuBookIcon />,
    },
    {
      title: 'Vowels',
      href: '/Vowels',
      icon: <MenuBookIcon />,
    },
    {
      title: 'Gyarakhari',
      href: '/gyarakhari',
      icon: <MenuBookIcon />,
    },
    {
      title: 'Akkha Numbers',
      href: '/numbers',
      icon: <FormatListNumberedRtlIcon />,
    },
    {
      title: 'Half Forms',
      href: '/halfForms',
      icon: <MenuBookIcon />,
    },
    {
      title: 'Basic Common Terms',
      href: '/basicTerms',
      icon: <MenuBookIcon />,
    },
    {
      title: 'Reading Practice',
      href: '/readingPractice',
      icon: <MenuBookIcon />,
    },
    {
      title: 'Typing Practice.',
      href: '/typingPractice',
      icon: <KeyboardIcon />,
    },
  ]

  const archivepages = [
    {
      title: 'Magar History/Timeline',
      href: 'hisAndTimeline',
      icon: <TimelineIcon />,
    },
    {
      title: 'Video Library',
      href: 'videoLibrary',
      icon: <VideoLibraryIcon />,
    },
    {
      title: 'Magar Books & Sahitya',
      href: 'booksAndSahitya',
      icon: <LocalLibraryIcon />,
    },
  ]

  return (
    <Drawer
      anchor="left"
      classes={{ paper: classes.drawer }}
      onClose={onClose}
      open={open}
      variant={variant}
      className="drawerbcg"
    >
      <div {...rest} className={clsx(classes.root, className)}>
        <p className={classes.version}>Version: 0.0.6</p>
        <p className={classes.version}>Last Updated: Nov 25, 2021</p>
        {/* <Profile /> */}
        <Divider className={classes.divider} />
        <h4
          style={{
            fontFamily: 'arial',
            marginBottom: 8,
            color: '#0fff',
            lineHeight: 1.3,
          }}
        >
          Send us feedback or willing to contribute creative content?
        </h4>
        <div className={classes.communicationContainer}>
          <h4>Email us: </h4>
          <a
            href={mailUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={classes.mediaIcon}
          >
            <MailOutlineIcon fontSize="medium" color="success" />
          </a>
        </div>
        <Divider className={classes.divider} />

        <h3 className={classes.menuHeading}>Akkha-Sipal Menu</h3>
        <SidebarNav className={classes.nav} pages={pages} />
        <Divider className={classes.divider} />
        {/* <h3 className={classes.menuHeading}>Magar Archives</h3>
        <SidebarNav className={classes.nav} pages={archivepages} /> */}
        <Divider className={classes.divider} />
        <div className={classes.appShare_container}>
          <p className={classes.storeTexts}>We have beautiful Apps too.</p>
          <div className={classes.phoneDisplay_Container}>
            <img
              src={iOSphoneDisplay}
              className={classes.iOSphonedisplay}
              alt="screenshots"
            />
            <img
              src={AndroidphoneDisplay}
              className={classes.androidPhonedisplay}
              alt="screenshots"
            />
          </div>
          <p className={classes.storeTexts1}>Check it out</p>
          <div className={classes.appstore_icons}>
            <a
              href={iOSUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.storeIcons}
            >
              <AppleIcon />
            </a>
            <a
              href={androidUrl}
              target="_blank"
              rel="noopener noreferrer"
              className={classes.storeIcons}
            >
              <AndroidIcon />
            </a>
          </div>
        </div>
        <Divider className={classes.divider} />
        <div className={classes.disclaimer_container}>
          <h3 className={classes.disclaimer_headingText}>Disclaimer:</h3>
          <h5 className={classes.version}>Last updated Oct 14, 2021</h5>
          <br />
          <h3 className={classes.disclaimer_headingText}>
            WEBSITE DISCLAIMER:
          </h3>
          <p className={classes.disclaimer_para}>
            The Information provided by Akkha-Rika-Lipi Sipal ("we", "us", or
            "our") on https://magarbhasalipi.com (the "Site") is for general
            Information as well as Magar language education purpose only. All
            Information on the Site is provided in good faith, however we make
            no representation or warranty of any kind, express or implied,
            regarding the accuracy, adequacy, validity, reliability,
            availability or completeness of any Information on the Site.
          </p>
        </div>
        <Divider className={classes.divider} />

        <Iframe iframe={iframe} />
        <div className={classes.last_iframe}></div>
        {/* <div style={{display: 'flex', flexDirection: 'row'}}>
        <Iframe iframe={iframe} />
        <Iframe iframe={iframe2} />
        </div>
        <div style={{display: 'flex', flexDirection: 'row'}}>
        <Iframe iframe={iframe3} />
        <Iframe iframe={iframe4} />
        </div> */}
        {/* <Divider className={classes.last_divider} /> */}
      </div>
    </Drawer>
  )
}

SideBar.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool.isRequired,
  variant: PropTypes.string.isRequired,
}

export default SideBar
