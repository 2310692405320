import React from "react";
import './Oukaar.css'
import GridRowVowelOukaar from './GridRowVowelOukaar';
import VowelOukaarData from './VowelOukaarData.json';

export default function VowelOukaarApp() {
  const VowelOukarContent = VowelOukaarData.map(
    ({ id, akkha_VowelOukar, kantipur_VowelOukar, Nep_Meaning, Eng_Meaning }) => (
      <GridRowVowelOukaar
        id={id}
        akkha_VowelOukar={akkha_VowelOukar}
        kantipur_VowelOukar={kantipur_VowelOukar}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );
  return (
    <div className="Vowel_Oukar_Container">
      <h3 className="Vowel_Oukar_Heading">
        {/* <p className="matra">q</p> */}
        "ाै" औकरलाई प्रयोग गर्दा बनिने शब्दहरु:</h3>
      <div className="Oukar_table_div">
        <table align="center">
        <thead className="Oukar_tableHeader">
            <tr>
              <th className="Oukar_heading_table">Akkha</th>
              <th className="Oukar_heading_table">Nepali Trans</th>
              <th className="Oukar_heading_table">Nepali Meaning</th>
              <th className="Oukar_heading_table">English Meaning</th>
            </tr>
          </thead>
          <tbody className="eachCells">{VowelOukarContent}</tbody>
        </table>
      </div>
    </div>
  );
}
