import React from 'react'
import WelcomePage from '../../pages/HomePage/CoverHomePage'
// import HeaderApp from './Header'

export default function HomePageApp() {
  return (
    <div>
      {/* <HeaderApp/> */}
      <WelcomePage/>
    </div>
  )
}
