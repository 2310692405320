import React from "react";
import Accordion from '../Accordion'

import "../ExerciseAccordion.css";

function Exercise3AccordionApp() {
  return (
      <Accordion>
        <div className="accor">
          <div className="head noselect">Nepali Transliteration</div>
          <div className="body">माजा छाना ड  बाडाक्कोलाक आडर जाट्की परडिस्ले।  ढलिङ ज्योछ ज्यानाङ खाच छानले। टै डनी ङा लाहूर नुङ्ले।</div>
        </div>
      </Accordion>
  );
}

export default Exercise3AccordionApp;
