import React from "react";
import "./Eekaar.css";

function GridRowVowelAakaar({
  id,
  akkha_VowelEekar,
  kantipur_VowelEekar,
  Nep_Meaning,
  Eng_Meaning
}) {
  return (
    <tr className="vowelEekar_tableRow">
      <td className="akkha_vowelEekar">{akkha_VowelEekar}</td>
      <td className="kantipur_vowelEekar">{kantipur_VowelEekar}</td>
      <td className="nep_meaning_vowelEekar">{Nep_Meaning}</td>
      <td className="eng_meaning_vowelEekar">{Eng_Meaning}</td>
    </tr>
  );
}

export default GridRowVowelAakaar;
