import { Divider } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/Images/GHMO_LOGO.PNG'
import FinancialDisclaimer from './FinancialDisclaimer'
// import ShareBtn from "react-share-button";

// const url = "magar_society_mainpage/general_meeting_notice"
// const text="Click the link below to see Invitaion/Announcement for upcoming General Meeting."

export default function GeneralMeetingNotice() {
  return (
    <div className="generalMeetingMain_container">
      <Link to="/magar_society_mainpage" onClick={() => window.scrollTo(0, 0)}>
        <button className="back_button">Back to Magar Society Page</button>
      </Link>
      <div className="generalMeetingNoticeHeader_container">
        <img src={logo} alt="" className="generalMeetingNotice_logoImage" />
        <div>
          <h1 className="generalMeetingNoticeHeaderText">ग्रेटर ह्यारिसबर्ग मगर संगठन</h1>
        </div>
      </div>
      <h3 className="generalMeetingNoticeSubHeaderText">
        *** साधारण सभा (General Meeting) सूचना ***
      </h3>
      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />
      <p className="generalMeetingNotice_para">पट्टकी मिझोर,</p>
      <p className="generalMeetingNotice_para">
        सम्पूर्ण ग्रेटर ह्यारिसबर्ग मगर संगठन सदस्यहरुमा यो सूचित गरिन्छ कि, हामी{' '}
        <strong style={{ color: 'orange' }}>
          आउँदो December 25, 2022{' '}, आइतबारको दिन,
        </strong>{' '}
        मा साधारण सभा (general meeting) आयोजना गर्दैछौं। यही साधारण सभामा हामी माघे संक्रान्तिको बारेमा छलफल र योजना बनाउँछौं। त्यसैले संक्रान्ति खर्च र व्यवस्थापनका लागि सबै सदस्यहरूलाई $30 साथमा ल्याउन र कृपया सबै सदस्यहरू तोकिएको समयमा उपस्थित भइदिनुहोला। 
      </p>
      
      <p className="generalMeetingNotice_para">
        भेला ठेगाना:{' '}
        <strong style={{ color: 'orange' }}>
          5661 Fordham Ave. Harrisburg, PA-17111
        </strong>
      </p>
      <p className="generalMeetingNotice_para">
        भेला हुने समय: <strong style={{ color: 'orange' }}>10:30 AM.</strong>
      </p>
      <p className="generalMeetingNotice_para">हामीले हाम्रो बोर्ड सदस्यहरूबाट प्रत्येक सदस्य समूहको लागि एक प्रतिनिधि नियुक्त गरेका छौं जसले तपाईंलाई कहिलेकाहीँ फोन कल रिमाइन्डरहरू दिनेछ। हामीले यो हाम्रो समाजको राम्रोको लागि गरिरहेका छौं। कृपया आफ्नो प्रतिनिधिलाई विनम्रतापूर्वक बोलेर सहयोग गर्नुहोस्।</p>
      
      <p className="generalMeetingNotice_para">
        यदि तपाइँसँग कुनै प्रश्न वा जिज्ञासा छ भने, कृपया Bal Monger
        (717-480-6730) लाई सम्पर्क गर्न नहिचकिचाउनुहोस्।
      </p>

      <p className="generalMeetingNotice_para">धन्यवाद।</p>
      <p className="generalMeetingNotice_para">बल आले मगर</p>
      <p className="generalMeetingNotice_para">महासचिव</p>
      <p className="generalMeetingNotice_para">ग्रेटर ह्यारिसबर्ग मगर संगठन</p>
      <p className="generalMeetingNotice_para">हेरिसबर्ग</p>
      {/* <ShareBtn url={url} text={text} className="ib" displayText="Share this Announcement" /> */}
      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />
      {/* <FinancialDisclaimer /> */}
    </div>
  )
}
