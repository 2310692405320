import React, { Component } from 'react'
import Exercise4App from './Exercise4'

export default class Cover_Exercise4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sportsAndFitnessID: "amzn-assoc-ad-8596ed20-28ea-453c-af4f-d3732438d987",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=8596ed20-28ea-453c-af4f-d3732438d987";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <Exercise4App/>
                {/* <div id={this.state.sportsAndFitnessID}/>  */}
            </div>
        )
    }
}