import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";
// import MusicalAdsApp from "../../AdsContainer/GourmetAdsApp";
import "../../pages/Half-Forms/Halfforms.css";
import CoverHalfFormsYaApp from "../../pages/Half-Forms/Half-Forms_Ya/Cover_HalfForms_Ya";
import CoverHalfFormsHaApp from "../../pages/Half-Forms/Half-Forms_Ha/CoverHalfForms_Ha";
import CoverHalfFormsWaApp from "../../pages/Half-Forms/Half-Forms_Wa/CoverHalfForms_Wa";
import CoverHalfFormsReeApp from "../../pages/Half-Forms/Half-Forms_Ree/CoverHalfForm_Ree";
import CoverHalfFormsRaApp from "../../pages/Half-Forms/Half-Forms_Ra/CoverHalfForms_Ra";
import CoverHalfFormsGeneralApp from "../../pages/Half-Forms/Half-Forms_General/CoverHalfForms_General";
import AffiliateDisclaimer from "../../pages/AffiliateDisclaimer";
// import AudibleAdsApp from '../../AdsContainer/AudibleAdsApp'
// import BeautyAdsApp from '../../AdsContainer/BeautyAdsApp'
// import GourmetAdsApp from '../../AdsContainer/GourmetAdsApp'
// import HealthAndPersonalCareAdsApp from '../../AdsContainer/HealthAndPersonalCareAdsApp'
// import HomeAndGardenAdsApp from '../../AdsContainer/HomeAndGardenAdsApp'
// import KindleAdsApp from '../../AdsContainer/KindleAdsApp'
// import KitchenAndHouseWareAdsApp from '../../AdsContainer/KitchenAndHouseWareAdsApp'
// import OutletAdsApp from '../../AdsContainer/OutletAdsApp'
// import PrimeSubsAds from '../../AdsContainer/PrimeSubsAds'
// import PrimeVideoAdsApp from '../../AdsContainer/PrimeVideoAdsApp'
// import SoftwareAdsApp from '../../AdsContainer/SoftwareAdsApp'
// import EmblaCarousel from '../../AdCarousel/js/EmblaCarousel'

// Carousel start
// const SLIDE_COUNT = 5;
// const slides = Array.from(Array(SLIDE_COUNT).keys());
// Carousel end

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
    // display: "flex",
    // flexDirection: "column",
    // justifyContent: "center",
  },
  linkContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 5,
  },
  nav_buttons: {
    padding: 5,
    borderRadius: 5,
    fontSize: 15,
    color: "gray",
    fontWeight: "bold",
    cursor: "pointer",
  },
}));

const HalfForms = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();

  return (
    <div id="topofpage" {...rest} className={clsx(classes.root, className)}>
      {/* <AffiliateDisclaimer /> */}

      <div className={classes.linkContainer}>
        <Link to="/numbers" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Prev: Numbers</button>
        </Link>
        <Link to="/basicTerms" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Next: Common Words</button>
        </Link>
      </div>
      {/* <MusicalAdsApp /> */}
      {/* <EmblaCarousel slides={slides} /> */}
      <h1 className="halfForms_Heading_text">Half-Forms (अर्ध अक्षर)</h1>
      <CoverHalfFormsWaApp />
      {/* <AudibleAdsApp /> */}
      <CoverHalfFormsYaApp />
      {/* <BeautyAdsApp /> */}
      <CoverHalfFormsHaApp />
      {/* <HomeAndGardenAdsApp /> */}
      <CoverHalfFormsReeApp />
      {/* <KitchenAndHouseWareAdsApp /> */}
      <CoverHalfFormsRaApp />
      {/* <PrimeVideoAdsApp /> */}
      <CoverHalfFormsGeneralApp />
      {/* <SoftwareAdsApp /> */}
      <div className={classes.linkContainer}>
        <Link to="/numbers" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Prev: Numbers</button>
        </Link>
        <Link to="/basicTerms" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Next: Common Words</button>
        </Link>
      </div>
      <ScrollLink to="topofpage" smooth={true} className="to-top-btn">
        <button className="to-top-button">Back to top ↑</button>
      </ScrollLink>
    </div>
  );
};

HalfForms.propTypes = {
  className: PropTypes.string,
};

export default HalfForms;
