// image should be of 0:2.1 ratio with dimension of 950x450 pixel

import lumbini from "./lumbini.jpg";
import heritageSite from "./heritageSite.jpg";
import peaceflame from "./peaceflame.jpg";
import taktsang from './taksangMonastery.jpg';
import NepaliKitchen from './nepaliKitchen.jpg';
import thaliFood from './thaliFood.jpg'
import magarUtensils from './magarUtensils.JPG'


const lumbiniLink1 = "https://unsplash.com/@ashokjkshetri?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const lumbiniLink2 = "https://unsplash.com/s/photos/lumbini?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const lumbiniLink_Photographer = "Ashok Acharya"
const lumbiniSourceName = "Unsplash"

const heritageSiteLink1 = "https://unsplash.com/@calepinaud?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const heritageSiteLink2 ="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const heritageSiteLink_Photographer = "Ashok Acharya"
const heritageSiteSourceName = "Unsplash"

const peaceflameLink1 = "https://unsplash.com/@itskabita?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const peaceflameLink2 = "https://unsplash.com/photos/7uSwdSWjSVU?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const peaceflameLink_Photographer = "Kabita Darlami"
const peaceflameSourceName = "Unsplash"

const taktsangLink1 = "https://unsplash.com/@raimondklavins?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const taktsangLink2 = "https://unsplash.com/s/photos/bhutan-buddhist?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const taktsangLink_Photographer = "Raimond Klavins"
const taktsangSourceName = "Unsplash"

const nepKitchenLink1 = "https://unsplash.com/@khadkamhn?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const nepKitchenLink2 = "https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const nepKitchenLink_Photographer = "Mohan Khadka"
const nepKitchenSourceName = "Unsplash"

const thaliFoodLink1 = "https://unsplash.com/@abhishek_sanwa?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const thaliFoodLink2 = "https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const thaliFoodLink_Photographer = "Abhishek Sanwa Limbu"
const thaliFoodSourceName = "Unsplash"

const magarUtensilsLink1 = "https://www.facebook.com/Indigenous-unity-nepal-231246370781455/?__tn__=-UC*F";
const magarUtensilsLink2 = "https://www.facebook.com/231246370781455/photos/a.235359703703455/998677027371715/?type=3";
const magarUtensilsLink_Photographer = "Indigenous Unity-Nepal"
const magarUtensilsSourceName = "Facebook"
  
const lumbini_Description = "Birth place of Lord Buddha";
const lumbini_Location = "Lumbini, Nepal"

const heritageSite_Description = "World Heritage Site";
const heritageSite_Location = "Lumbini, Nepal"

const peaceflame_Description= "Eternal peace flame";
const peaceflame_Location = "Lumbini, Nepal"

const taktsang_Description= "Taktsang Monastery";
const taktsang_Location = "Paro, Bhutan"

const nepKitchen_Description= "Typical Nepali Kitchen";
const nepKitchen_Location = "Buddhabhumi, Kapilvastu, Nepal"

const thaliFood_Description= "Thali Set (Dal Bhat Tarkari)";
const thaliFood_Location = "Kathmandu, Nepal"

const magarUtensils_Description= "Original/Ancient Everyday tools and crafts";
const magarUtensils_Location = "Known widely used in Nepal, Bhutan and India"

export const media = [lumbini, heritageSite, peaceflame, taktsang, NepaliKitchen, thaliFood, magarUtensils];
export const mediaByIndex = index => media[index % media.length];

export const link1 = [lumbiniLink1, heritageSiteLink1,  peaceflameLink1, taktsangLink1, nepKitchenLink1, thaliFoodLink1, magarUtensilsLink1];
export const linkByIndex1 = index => link1[index % link1.length];

export const link2 = [lumbiniLink2, heritageSiteLink2, peaceflameLink2, taktsangLink2, nepKitchenLink2, thaliFoodLink2, magarUtensilsLink2];
export const linkByIndex2 = index => link2[index % link2.length];

export const linkPhotographer = [lumbiniLink_Photographer, heritageSiteLink_Photographer, peaceflameLink_Photographer, taktsangLink_Photographer, nepKitchenLink_Photographer, thaliFoodLink_Photographer, magarUtensilsLink_Photographer];
export const linkPhotographerByIndex = index => linkPhotographer[index % linkPhotographer.length];


export const description = [lumbini_Description, heritageSite_Description, 
peaceflame_Description, taktsang_Description, nepKitchen_Description, thaliFood_Description, magarUtensils_Description];
export const DescriptionByIndex = index => description[index % description.length];

export const location = [lumbini_Location, heritageSite_Location, 
peaceflame_Location, taktsang_Location, nepKitchen_Location, thaliFood_Location, magarUtensils_Location];
export const LocationByIndex = index => location[index % location.length];

export const SourceName = [lumbiniSourceName,
    heritageSiteSourceName,
    peaceflameSourceName,
    taktsangSourceName,
    nepKitchenSourceName,
    thaliFoodSourceName,
    magarUtensilsSourceName]
export const SourceNameByIndex = index => SourceName[index % SourceName.length]