import React from "react";
import "./GyaraKharistyles.css";

function GridRowVowelsAndSymbols({
  id,
  akkha_GyaraKhariFirstColumn,
  kantipur_GyaraKhariFirstColumn,
  Akkha_GyaraKhariSecondColumn,
  kantipur_GyaraKhariSecondColumn
}) {
  return (
    <tr className="gyaraKhari_tableRow">
        <td className="gyarakhari_akkha">{akkha_GyaraKhariFirstColumn}</td>
        <td className="gyarakhari_kantipur">{kantipur_GyaraKhariFirstColumn}</td>
        <td className="gyarakhari_akkha">{Akkha_GyaraKhariSecondColumn}</td>
        <td className="gyarakhari_kantipur">{kantipur_GyaraKhariSecondColumn}</td>
    </tr>
  );
}

export default GridRowVowelsAndSymbols;
