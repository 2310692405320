import React from "react";
import { Link } from "react-router-dom";

const mailUrl =
  "mailto:bhutanesemagar@gmail.com?subject=BAMA ELECTION 2022&body=your message here..";

export default function MaghaySakrantiNotice() {
  return (
    <div className="bama_invitation_container">
      <h2 className="generalMeetingNoticeHeading">हार्दिक निमन्त्रणा !! </h2>

      <p className="generalMeetingNoticeDate_toAll">April 20, 2022</p>
      <p className="generalMeetingNotice_para">नमस्कार !</p>
      <p className="generalMeetingNotice_para">
        ह्यारिस्वर्ग मगर गुमस्ठ, पेन्सिलभेनिया राज्य अन्तर्गत बसोबास गर्ने
        ईस्क्र्यन्टन, पिट्ट्स्बर्ग, इरी, ह्यरिसबर्गले आयोजना गर्ने ‘भुट्निज
        अमेरिकन मगर संघ’ (BAMA) को तेस्रो महाधिवेशन यहि आउदो जुलाई महिनाको मिति
        ०७-१६-२०२२, ०७-१७-२०२२ (July 16th & 17th 2022), शनिवार र आईतबार दुईदिने
        कार्यक्रम अमेरिकाको पेन्सिलभेनिया राज्य  ह्यारिस्वर्ग (HARRISBURG) शहरमा
         भब्यताको साथ सुसम्पन्न हुन गईरहेको हुदा यहाँ लगायत हजुर बसोबास गर्ने
        शहरका  सम्पूर्ण मगर दाजु-भाई, दिदि-बहिनि, आमा-बुवा र बुद्धिजिबीहरुलाई
        यसपालिको सम्मेलनलाई सफल बनाउनको निम्ति उपस्थित भईदिन हुन भुट्निज मगर
        समाज पेन्सिलभेनिया बिशेष निमन्त्रणा गर्दछ।
      </p>
      <p className="generalMeetingNotice_para">
        यस सम्मेलन अति महत्वपूर्ण रहनेछ किन कि, अमेरिकाको पचासै राज्यहरुबाट र
        अमेरिका बाहिरबाट पनि सम्पूर्ण मगरहरूको सहभागिता रहने छ ।
      </p>

      <div className="program_details">
        <h4 className="generalMeetingNotice_para">
          कार्यक्रमका कार्य झलकहरु निम्न प्रकारका छन्ः-
        </h4>
        <ol className="ordered_list">
          <li>मगर वापामापाहरुबाट प्रवचन वा पाठपूजा गरिने ।</li>
          <li>( BAMA ) को ‘Bylaws’ (उपनियामहरु ) घोषणा गरिने ।</li>
          <li>मगर अग्रजहरुबाट छोटो मन्तव्य राखिने ।</li>
          <li>
            प्रत्येक राज्य वा शहरहरुबाट आएका प्रतिनिधिहरुबाट आ-आफ्नो छोटो
            विचारविमर्श राखिने ।
          </li>
          <li>बाह्र मगरहरुको पुस्तक विमोचन गरिने ।</li>
          <li>वापा-मापाहरुको निर्देशिका लोकार्पण गरिने ।</li>
          <li>
            मगर जातिहरुका पहिचान र सॅास्कृतिक आभास दिने खाल्का नाचगानहरु
            प्रस्तुत गरिने ।
          </li>
          <li>
            (BAMA) ‘भूटानिस अमेरिकन मगर संघ’ का नयॅा कार्यकर्ताहरुको छनोट गरिने
            ।
          </li>
          <li>
            शैक्षिक क्षेत्रबाट उत्तीर्णा गरेका बालबालिकाहरुलाई प्रमाण पत्र वितरण
            गरिने छ ।
          </li>
          <li>BAMA को बजेट रिपोर्ट पेस गरिने छ ।</li>
        </ol>
      </div>

      <p className="generalMeetingNotice_para">
        यस्तै अरु थुप्रै रोचक लाभदायक प्रकारका कार्यहरु पनि रहने छन् ।
      </p>
      <p className="generalMeetingNotice_para">
        यो हाम्रै आफ्नै ‘भुट्निज अमेरिकन मगर संघ’ हो, यस ऐतिहासिक सम्मेलनलाई
        सौहार्दपूर्ण सफल पार्नुमा हजुरहरुको ओजस्वी हात रहने छ ।
      </p>

      <div className="important_points">
        <h4 className="generalMeetingNotice_para">Important/महत्वपूर्ण:</h4>
        <ol className="ordered_list">
          <li>
            आफ्नो राज्यबाट कसलाई जिताएर बोर्डमा पठाउनु चाहनु हुन्छ, उम्मेदवारको
            नाम ,ठेगाना र फोन न: June 1st 2022 to July 13th 2022 रात्री समय
            ११:५९ (11.59pm) सम्ममा email (bhutanesemagar@gmail.com) मार्फत
            पठाइसक्नु पर्ने छ{" "}
            <a
              href={mailUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="email_button"
            >
              Click here to Email
            </a>{" "}
            । (उमेत्द्वर को उमेर १८ बर्ष भन्दा माथि र सो देश को नगरिक्त हुनु
            जरुरी छ){" "}
          </li>
          <li>
            आफ्नो भाषा,भेष,धर्म र सॅास्कृतिको सम्मान र उजागर गराऊ भन्ने नारालाई
            टेवा पुर्याउन सबै जनहरुलाई आह्वान अनि अनुरोध गर्न चाहन्छौं ।छोरा
            मान्छेले घल्याङ ( सेतो पोसाक )मगर ड्रेस र छोरी माछेले लुङ्गी लगाइ
            मगर हुं भन्ने आफ्नो पहिचान आफै दिनु होला ।
          </li>
        </ol>
      </div>

      <p className="generalMeetingNotice_para">धन्यवाद।</p>

      <div className="event_details">
        <p className="generalMeetingNotice_para">
          कार्यक्रम स्थान:{" "}
          <strong>
            Scottish Rite Theatre & Ballroom, 2701 North 3rd Street, Harrisburg,
            PA-17110
          </strong>
        </p>
        <p className="generalMeetingNotice_para">
          कार्यक्रम समय:{" "}
          <strong>July 16th & 17th, 2022 from 8am to 4pm.</strong>
        </p>
        <p className="generalMeetingNotice_para">
          आयोजक:{" "}
          <strong>ह्यारिस्वर्ग मगर गुमस्ठ, ह्यारिस्वर्ग पेन्सिलभेनिया</strong>
        </p>
        <p className="generalMeetingNotice_para">
          सह-आयोजक: <strong>भुट्निज अमेरिकन मगर एसोसिएसन (बामा)</strong>
        </p>
      </div>

      <p className="generalMeetingNotice_para">
        अध्यक्ष: <strong>नर बलम्पाकी मगर</strong>
      </p>
      <p className="generalMeetingNotice_para">
        महासचिव: <strong>बल आले मगर</strong>
      </p>
      <p className="generalMeetingNotice_para">ह्यारिस्वर्ग मगर गुमस्ठ</p>
      <p className="generalMeetingNotice_para">हेरिसबर्ग, पेन्सिलभेनिया</p>

      <div className="contact_persons">
        <p className="generalMeetingNotice_para">सम्पर्क व्यक्तिहरुः-</p>
        <ol className="ordered_list">
          <li>नर बलाम्पाकीमगर ७१७-३५०-८००९ /717-350-8009 </li>
          <li>बल आलेमगर ७१७-४८०-६७३०/717-480-6730</li>
          <li>सर्मन समालमगर ६७८-८८७-०४७८/678-887-0478</li>
          <li>पुर्ण बालम्पकीमगर ३३०-९९९-१९००/330-999-1900</li>
          <li>टहलमान पिठकोटेमगर ५१०-६०६-३९७५/510-606-3975</li>
        </ol>
      </div>
      <div className="invitation_buttonContainer">
        <p>Meanwhile...</p>
        <Link to="/home" onClick={() => window.scrollTo(0, 0)}>
          <button className="invitationBackBtn">Learn Magar Bhasa</button>
        </Link>
      </div>
    </div>
  );
}
