import React from "react";
import PropTypes from "prop-types";
import { Link as ScrollLink } from "react-scroll";
import clsx from "clsx";
import {Link} from 'react-router-dom';
import { makeStyles } from "@material-ui/styles";
import CoverPunctuationApp from "../../pages/Basic_Terms/Punctuation/Cover_Punctuation";
import CoverTimeAdjApp from "../../pages/Basic_Terms/Time-Adjective/Cover_TimeAdjective";
import CoverFourDirApp from "../../pages/Basic_Terms/Four-Directions/Cover_FourDirection";
import CoverNumeralAdjApp from "../../pages/Basic_Terms/Numeral-Adjective/Cover_NumeralsAdj";
import CoverSevenDaysApp from "../../pages/Basic_Terms/Seven_Days/Cover_SevenDays";
import CoverMonthsApp from "../../pages/Basic_Terms/Months/Cover_Months";
import "../../pages/Basic_Terms/BasicTerms.css";
import AffiliateDisclaimer from "../../pages/AffiliateDisclaimer";
// import MusicalAdsApp from '../../AdsContainer/GourmetAdsApp'
// import AudibleAdsApp from '../../AdsContainer/AudibleAdsApp'
// import BeautyAdsApp from "../../AdsContainer/BeautyAdsApp";
// import GourmetAdsApp from '../../AdsContainer/GourmetAdsApp'
// import HealthAndPersonalCareAdsApp from "../../AdsContainer/HealthAndPersonalCareAdsApp";
// import HomeAndGardenAdsApp from "../../AdsContainer/HomeAndGardenAdsApp";
// import KindleAdsApp from '../../AdsContainer/KindleAdsApp'
// import KitchenAndHouseWareAdsApp from '../../AdsContainer/KitchenAndHouseWareAdsApp'
// import OutletAdsApp from "../../AdsContainer/OutletAdsApp";
// import PrimeSubsAds from '../../AdsContainer/PrimeSubsAds'
// import PrimeVideoAdsApp from "../../AdsContainer/PrimeVideoAdsApp";
// import SoftwareAdsApp from '../../AdsContainer/SoftwareAdsApp'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  linkContainer: {
    display: "flex",
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 5
  },
  nav_buttons : {
    padding: 5,
    borderRadius: 5,
    fontSize: 15,
    color: 'gray',
    fontWeight: "bold",
    cursor: "pointer",
  },
  // scrollbtn_container: {
  //   position: 'fixed',
  //   // marginBottom: 25
  //   overflow: "auto",
  //   display: "flex",
  //   flexDirection: "row"
  // }, 
  timeAdj_btn: {
    marginLeft: 5,
    marginRight: 5,
  }
}));
const BasicCommonTerms = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  return (
    <div {...rest} className={clsx(classes.root, className)} id="topofpage">
      {/* <AffiliateDisclaimer/> */}
      <div className={classes.linkContainer}>
        <Link to="/halfForms" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Prev: Half Forms</button>
        </Link>
        <Link to="/readingPractice" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Next: Reading Practice</button>
        </Link>
      </div>
      {/* <div className={classes.scrollbtn_container}>
        <ScrollLink to="timeAdj" smooth={true} className={classes.timeAdj_btn}>
          <button className="to-top-button">Time Adjective</button>
        </ScrollLink>
        <ScrollLink
          to="DaysName"
          smooth={true}
          className={classes.DaysName_btn}
        >
          <button className="to-top-button">Days Name</button>
        </ScrollLink>
        <ScrollLink
          to="DaysName"
          smooth={true}
          className={classes.DaysName_btn}
        >
          <button className="to-top-button">Days Name</button>
        </ScrollLink>
        <ScrollLink
          to="DaysName"
          smooth={true}
          className={classes.DaysName_btn}
        >
          <button className="to-top-button">Days Name</button>
        </ScrollLink>
      </div> */}
      {/* <MusicalAdsApp/> */}
      <h1 className="basicTerms_Heading_text">Magar Basic Common Terms</h1>
      <CoverPunctuationApp />
      {/* <PrimeVideoAdsApp /> */}
      <CoverTimeAdjApp/>
      {/* <OutletAdsApp /> */}
      <CoverFourDirApp />
      {/* <HomeAndGardenAdsApp /> */}
      <CoverNumeralAdjApp />
      {/* <HomeAndGardenAdsApp /> */}
      <CoverSevenDaysApp/>
      {/* <HealthAndPersonalCareAdsApp /> */}
      <CoverMonthsApp />
      {/* <BeautyAdsApp /> */}
      <div className={classes.linkContainer}>
        <Link to="/halfForms" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Prev: Half Forms</button>
        </Link>
        <Link to="/readingPractice" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Next: Reading Practice</button>
        </Link>
      </div>
      <ScrollLink to="topofpage" smooth={true} className="to-top-btn">
        <button className="to-top-button">Back to top ↑</button>
      </ScrollLink>
    </div>
  );
};

BasicCommonTerms.propTypes = {
  className: PropTypes.string,
};

export default BasicCommonTerms;
