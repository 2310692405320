import React, { Component } from 'react'
import GyaraKhariWords from '../GyarakhariWords';

export default class CoverGyarakhariWords extends Component {constructor(props) {
    super(props);
    this.state = {
      giftCardBrandsID: "amzn-assoc-ad-ec3e3ad8-c047-4601-b60b-52d519d30066",
    };
  }

componentDidMount() {
    const script = document.createElement("script");

   script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=ec3e3ad8-c047-4601-b60b-52d519d30066";
   script.async = true;

   document.body.appendChild(script);
  }
render() {
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <GyaraKhariWords/>
            {/* <div id={this.state.giftCardBrandsID}></div>  */}
        </div>
    )
}
}