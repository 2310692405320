import React from "react";
import Accordion from '../Accordion'

import "../ExerciseAccordion.css";

function Exercise4AccordionApp() {
  return (
      <Accordion>
        <div className="accor">
          <div className="head noselect">Nepali Transliteration</div>
          <div className="body">चु याक छान्म भ्या मोई बोइ राछे।  निनि निब इमाङ डै खाटा राहा। मामा गुमा भौजिकी ङास्कि राहा। झौ इमाङ कुडिक ङुकी ? सिङाै म्यारटुङ फूसकी माछानने।</div>
        </div>
      </Accordion>
  );
}

export default Exercise4AccordionApp;
