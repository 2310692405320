import React, { Component } from 'react'
import VowelsAndSymbolsApp from './VowelsAndSymbols'
// import VowelShare from '../../views/Vowels/VowelShare'

export default class CoverVowelsAndSymbols extends Component {
    constructor(props) {
        super(props);
        this.state = {
          artsCraftSewingID: "amzn-assoc-ad-56802710-17cc-4b1b-9b92-3c3e7976724b",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=56802710-17cc-4b1b-9b92-3c3e7976724b";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                {/* <VowelShare/> */}
                <VowelsAndSymbolsApp/>
                {/* <div id={this.state.artsCraftSewingID}></div>  */}
            </div>
        )
    }
}
