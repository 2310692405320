import React from "react";
import './Numbers.css'
import NumberData from './NumberData.json';
import GridRowNumbers from "./GridRowNumbers";
// import CalcApp from './CalcComponents/CalcApp'



export default function GyaraKhariApp() {
  const NumberContent = NumberData.map(
    ({ id, akkha_Number, Eng_Number, Akkha_NumberPronounce, kantipur_NumPronounce, Eng_NumPronounce }) => (
      <GridRowNumbers
        id={id}
        akkha_Number={akkha_Number}
        Eng_Number={Eng_Number}
        Akkha_NumberPronounce={Akkha_NumberPronounce}
        kantipur_NumPronounce={kantipur_NumPronounce}
        Eng_NumPronounce={Eng_NumPronounce}
      />
    )
  );

  return (
    <div className="num_container">
      <h1 className="num_Heading_text">मगर गन्ती</h1>
      <div className="num_table_div">
        <table align="center">
        <thead className="num_tableHeader">
            <tr>
              <th className="num_heading_table">Akkha Number</th>
              <th className="num_heading_table">English Number</th>
              <th className="num_heading_table">Akkha Pronounciation</th>
              <th className="num_heading_table">Nepali Transliteration</th>
              <th className="num_heading_table">English Transliteration</th>
            </tr>
          </thead>
          <tbody className="Number_eachCells">{NumberContent}</tbody>
        </table>
      </div>
    </div>
  );
}
