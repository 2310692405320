import React from "react";
import './GyaraKharistyles.css'
import GyaraKhariWordData from './GyaraKhariWordData.json';
import GridRowGyaraKhariWord from './GridRowGyaraKhariWord'

export default function GyaraKhariApp() {
    const GyaraKhariWordContent = GyaraKhariWordData.map(
      ({ id, akkha_GyaraKhariWordFirstColumn, kantipur_GyaraKhariWordFirstColumn, Akkha_GyaraKhariWordSecondColumn, kantipur_GyaraKhariWordSecondColumn }) => (
        <GridRowGyaraKhariWord
          id={id}
          akkha_GyaraKhariWordFirstColumn={akkha_GyaraKhariWordFirstColumn}
          kantipur_GyaraKhariWordFirstColumn={kantipur_GyaraKhariWordFirstColumn}
          Akkha_GyaraKhariWordSecondColumn={Akkha_GyaraKhariWordSecondColumn}
          kantipur_GyaraKhariWordSecondColumn={kantipur_GyaraKhariWordSecondColumn}
        />
      )
    );
    return (
      <div className="gyaraKhari_container" id="topofpage">
        <div className="Gyarakhari_bcg">
        <p className="gyarakhariWord_para">पढ्न सिकौं आफ्नो लिपि र भाषामा:</p>
        <div className="gyaraKhari_table_div">
          <table align="center">
          <thead className="gyaraKhari_tableHeader1">
              <tr>
                <th className="gyaraKhari_heading_table1">Akkha Word</th>
                <th className="gyaraKhari_heading_table1">Nepali Transliteration</th>
                <th className="gyaraKhari_heading_table1">Nepali Meaning</th>
                <th className="gyaraKhari_heading_table1">English Meaning</th>
              </tr>
            </thead>
            <tbody className="gyaraKhari_eachCells">{GyaraKhariWordContent}</tbody>
          </table>
        </div>
        </div>
      </div>
    );
  }
  