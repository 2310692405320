import React from "react";
import Accordion from '../Accordion'

import "../ExerciseAccordion.css";

function Exercise5AccordionApp() {
  return (
      <Accordion>
        <div className="accor">
          <div className="head noselect">Nepali Transliteration</div>
          <div className="body">अशोककालिन अभिलेख र महापरिनिर्वाण पछि भगवान बुद्धको असठी राखको माटाका असठी कलशमा अक्खा रिका पिप्रको शाक्य इस्टुपबाट प्राप्ट। यसैले हामी मगरहरुले यो हाम्रो लिपिलाई विकासको शिखर पुर्याउन अवस्य मडत गर्नु हुन्छ। </div>
        </div>
      </Accordion>
  );
}

export default Exercise5AccordionApp;
