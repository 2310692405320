import React, { Component } from 'react'
import MonthsApp from './Months'

export default class Cover_Months extends Component {
    constructor(props) {
        super(props);
        this.state = {
            petFoodAndSuppliesID: "amzn-assoc-ad-18d63356-076e-4e75-ad1f-34634bafa102",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=18d63356-076e-4e75-ad1f-34634bafa102";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <MonthsApp/>
                {/* <div id={this.state.petFoodAndSuppliesID}/>  */}
            </div>
        )
    }
}
