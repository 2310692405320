import "./Cur_AnnStyles.css";
import axios from "axios";
import Card1 from "./Cur_IndividualAnnouncement";
import React, { Component } from "react";

export default class MessageApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isloading: true,
    };
  }

  async componentDidMount() {
    await axios.get("announcement1.json").then((res) => {
      const users = res;
      this.setState({ users, isloading: false });
    });
  }

  render() {
    let res = this.state.users;
    return (
      <div className="cur_AnnCards">
        {res?.data?.data?.map((x) => {
          return (
              <Card1
                img={x.avatar}
                announcementType={x.announcementType}
                announcementTypeEng={x.announcementTypeEng}
                address={x.address}
                name={x.name}
                nameEng={x.nameEng}
                akkha_name={x.akkha_name}
                dayOfDeath={x.dod}
                dodTime={x.dodTime}
                dob={x.dob}
                funeralServiceDate={x.funeralServiceDate}
                funeralServiceTime={x.funeralServiceTime}
                FuneralAddress={x.FuneralAddress}
                frontMessage={x.frontMessage}
                message={x.message}
                message_para2={x.message_para2}
                messageFrom={x.messageFrom}
                marriageDate={x.dom}
                children={x.children}
                birthPlace={x.birthPlace}
                deathplace={x.deathplace}
                sudhai={x.sudhai}
              />
          );
        })}
      </div>
    );
  }
}
