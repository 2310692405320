import { Divider } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/Images/magarsmajLogo.png";
import PampaLamichhane_Image from "../assets/Images/pampa_lamichhane.jpg";
import FinancialDisclaimer from "./FinancialDisclaimer";
// import ShareBtn from "react-share-button";

// const url = "magar_society_mainpage/general_meeting_notice"
// const text="Click the link below to see Invitaion/Announcement for upcoming General Meeting."

export default function GeneralMeetingNotice() {
  return (
    <div>
      <Link to="/magar_society_mainpage" onClick={() => window.scrollTo(0, 0)}>
        <button className="back_button">Back to Magar Society Page</button>
      </Link>
      <div className="generalMeetingNoticeHeader_container">
        <img src={logo} alt="" className="generalMeetingNotice_logoImage" />
        <div>
          <h2 className="generalMeetingNoticeHeaderText">
            हेरिसबर्ग मगर गुमस्ठ
          </h2>
          <h3 className="generalMeetingNoticeSubHeaderText">
            हेरिसबर्ग, पेन्सिलभेनिया
          </h3>
        </div>
      </div>

      <Divider
        style={{
          backgroundColor: "white",
          maxWidth: "1124px",
          margin: "20px auto",
        }}
      />
      <div className="obituaryMain_container">
        <div className="obituaryHeader">
          <img className="obituaryImage" src={PampaLamichhane_Image} alt="pampa_lamichhane" />
          <h3 style={{ fontSize: "25px" }}>*** हार्दिक संवेदना ***</h3>
        </div>
        <p className="generalMeetingNotice_para">पट्टकी मिझोर,</p>
        <p className="generalMeetingNotice_para">
          {" "}
          203 Dunbar Dr मेकानिक्सबर्ग, पेन्सिलभेनिया निवासी{" "}
          <strong>पम्पा लामिछाने मगर</strong> को गत सोमबार August 1, 2022 का
          दिन ९० वर्षको उमेरमा निधन भएको सबैलाई जानकारी गराउन चाहन्छौ। उहाँको
          दिवंगत आत्माको चीर शान्तिको कामनाका साथै भावपूर्ण श्रद्धाञ्जली व्यक्त
          गर्दछौं । यस दु:खद घडीमा शोक सन्तप्त लामिछाने परिवार र आफन्तजनमा धैर्य
          धारण गर्ने शक्ति प्राप्त होस् भनि हार्दिक संवेदना व्यक्त गर्दछौं । यो
          सबैलाई हुने प्राकृतिक घटना हो। त्यसैले हामी सबैलाई एक अर्काको सहयोगको
          खाँचो पर्दछ।
        </p>
        <p className="generalMeetingNotice_para">
          हेरिसबर्ग मगर गुमस्ठ नियम अनुसार सदाझैं यस्ता दुखद घडीमा लामिछाने
          परिवारको निम्ति आर्थिक सहयोग प्रत्येक सदस्य परिवार बाट $50 संकलन
          गर्दछौं । तसर्थ यो सूचना प्राप्त हुने बित्तिकै कोषाध्यक्षको ठेगानामा
          (5661 Fordham Ave. Harrisburg, PA-17111) आफ्नो सहयोग बुझाउनु हुनेछ
          भन्ने आशा गर्दछु।
        </p>

        <p className="generalMeetingNotice_para">
          Funeral Address:{" "}
          <strong style={{ color: "orange" }}>
            <i>2100 Linglestown Rd, Harrisburg, PA 17110</i>
          </strong>
        </p>
        <p className="generalMeetingNotice_para">
          Funeral date & Time:{" "}
          <strong style={{ color: "orange" }}>
            <i>Friday, August 5, 2022 @ 10.30 am (Arrival)</i>
          </strong>
        </p>
        <p className="generalMeetingNotice_para">
          यसैगरी उहाँको अन्तिम सुधाई 08/10/2022 बुधबारका दिन हुने भएको छ । यस
          सूचना बारे केहि जिज्ञासा भए 717-480-6730 मा सम्पर्क गर्न अनुरोध
          गर्दछौं।
        </p>
        <p className="generalMeetingNotice_salutation">धन्यवाद।</p>
        <p className="generalMeetingNotice_salutation">बल आले मगर</p>
        <p className="generalMeetingNotice_salutation">महासचिव</p>
        <p className="generalMeetingNotice_salutation">हेरिसबर्ग मगर गुमस्ठ</p>
        <p className="generalMeetingNotice_salutation">
          हेरिसबर्ग, पेन्सिलभेनिया
        </p>
        {/* <ShareBtn url={url} text={text} className="ib" displayText="Share this Announcement" /> */}
      </div>
      <Divider
        style={{
          backgroundColor: "white",
          maxWidth: "1124px",
          margin: "20px auto",
        }}
      />
      <FinancialDisclaimer />
    </div>
  );
}
