import React from "react";
import './Aekaar.css'
import GridRowVowelAekaar from './GridRowVowelAekaar';
import VowelAekaarData from './VowelAekaarData.json';

export default function VowelAekaar() {
  const VowelAekarContent = VowelAekaarData.map(
    ({ id, akkha_VowelAekar, kantipur_VowelAekar, Nep_Meaning, Eng_Meaning }) => (
      <GridRowVowelAekaar
        id={id}
        akkha_VowelAekar={akkha_VowelAekar}
        kantipur_VowelAekar={kantipur_VowelAekar}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );
  
  return (
    <div className="Vowel_Aekar_Container">
      <h3 className="Vowel_Aekar_Heading">
        {/* <p className="matra">q</p> */}
        "े" एकारलाई प्रयोग गर्दा बनिने शब्दहरु:</h3>
      <div className="Aekar_table_div">
        <table align="center">
        <thead className="Aekar_tableHeader">
            <tr>
              <th className="Aekar_heading_table">Akkha</th>
              <th className="Aekar_heading_table">Nepali Trans</th>
              <th className="Aekar_heading_table">Nepali Meaning</th>
              <th className="Aekar_heading_table">English Meaning</th>
            </tr>
          </thead>
          <tbody className="eachCells">{VowelAekarContent}</tbody>
        </table>
      </div>
    </div>
  );
}
