import React from "react";
import './FourDirection.css'
import FourDirection_Data from './FourDirectionData.json';
import GridRowFourDir from "./GridRowFourDirection";


export default function FourDirectionApp() {
  const FourDirectionContent = FourDirection_Data.map(
    ({ id, akkha_Words, Nep_Words, Nep_Meaning, Eng_Meaning }) => (
      <GridRowFourDir
        id={id}
        akkha_Words={akkha_Words}
        Nep_Words={Nep_Words}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );

  return (
    <div className="Direction_bcg">
      <h2 className="basicTerms_headingText">Four Directions (चार दिशा)</h2>
      <div className="basicTerms_table_div">
        <table align="center">
        <thead className="basicTerms_tableHeader">
            <tr>
              <th className="fourDir_heading_table">Akkha Words</th>
              <th className="fourDir_heading_table">Nepali Trans</th>
              <th className="fourDir_heading_table">Nepali Meaning</th>
              <th className="fourDir_heading_table">English Meaning</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_second_eachCells">{FourDirectionContent}</tbody>
        </table>
      </div>
    </div>
  );
}
