import React from 'react'
import "./ConsonantStyles.css";
import data from "./data.json";
import GridRow from "./GridRow";

export default function App() {

//   useEffect(() => {
// //   //   const script = document.createElement('script')
// //   //   script.text = `amzn_assoc_ad_type = "banner"; amzn_assoc_marketplace = "amazon"; amzn_assoc_region = "US"; amzn_assoc_placement =
// //   //  "assoc_banner_placement_default"; .................`

//     const script2 = document.createElement('script')
//     script2.src = '//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=a2752786-6aed-4c4f-8414-e4a63a339aed'
//     script2.async = true

//     const amazonAdd = document.querySelector('amzn-assoc-ad-a2752786-6aed-4c4f-8414-e4a63a339aed')
//     if (amazonAdd) {
//       //  amazonAdd.appendChild(script)
//        amazonAdd.appendChild(script2)
//     }
//  }, [])

  const ConsonantContent = data.map(
    ({ id, akkha_letter, kantipur_transliteration, eng_transliteration }) => (
      <GridRow
        id={id}
        akkha_letter={akkha_letter}
        kantipur_transliteration={kantipur_transliteration}
        eng_transliteration={eng_transliteration}
      />
    )
  );

  return (
    <div className="consonant_Alphabet_grid" id="topofpage">

      <h1 className="Consonant_Heading_text">Consonants (ब्यञ्जनवर्ण)</h1>
      <div className="consonant_table_div">
        <table align="center">
          <thead className="consonant_tableHeader">
            <tr>
              <th className="consonant_heading_table">Akkha Letter</th>
              <th className="consonant_heading_table">Nepali Transliteration</th>
              <th className="consonant_heading_table">English Transliteration</th>
            </tr>
          </thead>
          <tbody className="consonant_eachCells">{ConsonantContent}</tbody>
        </table>
      </div>
      {/* <div id="amzn-assoc-ad-a2752786-6aed-4c4f-8414-e4a63a339aed" /> */}
    </div>
  );
}
