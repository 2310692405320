import React, { Component } from 'react'
import NumeralAdjApp from './NumeralAdjective'

export default class Cover_NumeralsAdj extends Component {
    constructor(props) {
        super(props);
        this.state = {
            musicalInstrumentsID: "amzn-assoc-ad-74989a52-b3f4-4d1a-9a6f-3c4806038ec3",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=74989a52-b3f4-4d1a-9a6f-3c4806038ec3";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <NumeralAdjApp/>
                {/* <div id={this.state.musicalInstrumentsID}/>  */}
            </div>
        )
    }
}
