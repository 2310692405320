import React from "react";
import '../Halfforms.css'
import Half_Form_HaData from './Half_forms_HaData.json';
import Half_Form_HaWordsData from './Half_forms_HaWordsData.json';
import GridRowHalfFormsHa from "./GridRowHalf_forms_Ha";
import GridRowHalfFormsHaWors from "./GridRowHalf_forms_HaWords";


export default function Half_Forms_HaApp() {
  const Half_forms_HaContent = Half_Form_HaData.map(
    ({ id, akkha, nepali, akkha1, nepali1, akkha2, nepali2 }) => (
      <GridRowHalfFormsHa
        id={id}
        akkha={akkha}
        nepali={nepali}
        akkha1={akkha1}
        nepali1={nepali1}
        akkha2={akkha2}
        nepali2={nepali2}
      />
    )
  );

  const Half_forms_HaWordsContent = Half_Form_HaWordsData.map(
    ({ id, akkha_Words, Nep_Words, Nep_Meaning, Eng_Meaning }) => (
      <GridRowHalfFormsHaWors
        id={id}
        akkha_Words={akkha_Words}
        Nep_Words={Nep_Words}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );

  return (
    <div className="HalfFormsHa_bcg">
      <h2 className="halfForms_Heading_text">अर्ध अक्षर "ह्"</h2>
      <div className="halfForms_top_part">
        <table align="center">
        <thead className="halfForms_tableHeader">
            <tr>
              <th className="halfForms_heading_table">Akkha</th>
              <th className="halfForms_heading_table">Nepali Trans</th>
              <th className="halfForms_heading_table">Akkha</th>
              <th className="halfForms_heading_table">Nepali Trans</th>
              <th className="halfForms_heading_table">Akkha</th>
              <th className="halfForms_heading_table">Nepali Trans</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_first_eachCells">{Half_forms_HaContent}</tbody>
        </table>
      </div>
      <h2 className="halfForms_Heading_text">अर्ध अक्षर "ह्" द्वारा बनिने शब्दहरु:</h2>
      <div className="halfForms_bottom_part">
        <table align="center">
        <thead className="halfForms_tableHeader">
            <tr>
              <th className="halfForms_heading_table1">Akkha Word</th>
              <th className="halfForms_heading_table1">Nepali Trans</th>
              <th className="halfForms_heading_table1">Nepali Meaning</th>
              <th className="halfForms_heading_table1">English Meaning</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_second_eachCells">{Half_forms_HaWordsContent}</tbody>
        </table>
      </div>
    </div>
  );
}
