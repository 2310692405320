import React from "react";
import '../Halfforms.css'
import Half_Form_RaData from './Half_forms_RaData.json';
import Half_Form_RaWordsData from './Half_forms_RaWordsData.json';
import GridRowHalfFormsRa from "./GridRowHalf_forms_Ra";
import GridRowHalfFormsRaWords from "./GridRowHalf_forms_RaWords";


export default function Half_Forms_RaApp() {
  const Half_forms_RaContent = Half_Form_RaData.map(
    ({ id, akkha, nepali, akkha1, nepali1, akkha2, nepali2 }) => (
      <GridRowHalfFormsRa
        id={id}
        akkha={akkha}
        nepali={nepali}
        akkha1={akkha1}
        nepali1={nepali1}
        akkha2={akkha2}
        nepali2={nepali2}
      />
    )
  );

  const Half_forms_RaWordsContent = Half_Form_RaWordsData.map(
    ({ id, akkha_Words, Nep_Words, Nep_Meaning, Eng_Meaning }) => (
      <GridRowHalfFormsRaWords
        id={id}
        akkha_Words={akkha_Words}
        Nep_Words={Nep_Words}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );

  return (
    <div className="HalfFormsRa_bcg">
      <h2 className="halfForms_Heading_text">अर्ध अक्षर "र + अ"</h2>
      <div className="halfForms_top_part">
        <table align="center">
        <thead className="halfForms_tableHeader">
            <tr>
              <th className="halfForms_heading_table">Akkha</th>
              <th className="halfForms_heading_table">Nepali Trans</th>
              <th className="halfForms_heading_table">Akkha</th>
              <th className="halfForms_heading_table">Nepali Trans</th>
              <th className="halfForms_heading_table">Akkha</th>
              <th className="halfForms_heading_table">Nepali Trans</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_first_eachCells">{Half_forms_RaContent}</tbody>
        </table>
      </div>
      <h2 className="halfForms_Heading_text">अर्ध अक्षर "र + अ" द्वारा बनिने शब्दहरु:</h2>
      <div className="halfForms_bottom_part">
        <table align="center">
        <thead className="halfForms_tableHeader">
            <tr>
              <th className="halfForms_heading_table1">Akkha Word</th>
              <th className="halfForms_heading_table1">Nepali Trans</th>
              <th className="halfForms_heading_table1">Nepali Meaning</th>
              <th className="halfForms_heading_table1">English Meaning</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_second_eachCells">{Half_forms_RaWordsContent}</tbody>
        </table>
      </div>
    </div>
  );
}
