import React, { Component } from 'react'
import GyaraKhariExampleWords from '../GyarakhariExample';

export default class CoverGyarakhariExample extends Component {constructor(props) {
    super(props);
    this.state = {
      videoGamesID: "amzn-assoc-ad-e2fb62b6-fe55-4dfe-be9a-bd0f754dcc8f",
    };
  }

componentDidMount() {
    const script = document.createElement("script");

   script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=e2fb62b6-fe55-4dfe-be9a-bd0f754dcc8f";
   script.async = true;

   document.body.appendChild(script);
  }
render() {
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <GyaraKhariExampleWords/>
            {/* <div id={this.state.videoGamesID}></div>  */}
        </div>
    )
}
}
