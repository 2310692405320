import React from "react";
import './AllVowelWords.css'
import GridRowAllVowelWords from './GridRowAllVowelWords';
import AllVowelWordsData from './AllVowelWordsData.json';

export default function AllVowelWordsApp() {
  const AllVowelContent = AllVowelWordsData.map(
    ({ id, akkha_AllVowelWords, kantipur_AllVowelWords, Nep_Meaning, Eng_Meaning }) => (
      <GridRowAllVowelWords
        id={id}
        akkha_AllVowelWords={akkha_AllVowelWords}
        kantipur_AllVowelWords={kantipur_AllVowelWords}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );
  return (
    <div className="Vowel_AllVowelWords_Container">
      <h3 className="AllVowelWords_Heading">
        {/* <p className="matra">q</p> */}
        अभ्यासको निम्ति अ, आ, इ, ई, उ, ऊ, ए  स्वर-वर्णबाट  बनिने शब्दहरु:</h3>
      <div className="AllVowelWords_table_div">
        <table align="center">
        <thead className="AllVowelWords_tableHeader">
            <tr>
              <th className="AllVowelWords_heading_table">Akkha</th>
              <th className="AllVowelWords_heading_table">Nepali Trans</th>
              <th className="AllVowelWords_heading_table">Nepali Meaning</th>
              <th className="AllVowelWords_heading_table">English Meaning</th>
            </tr>
          </thead>
          <tbody className="eachCells">{AllVowelContent}</tbody>
        </table>
      </div>
    </div>
  );
}
