import React, { Component } from 'react'
import Exercise1App from './Exercise1'
// import AccordionExercise1 from '../../../../src/ACCORDION/AccordionEx1'

export default class Cover_Exercise1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            shoesHandbagsWalletsSunglassesID: "amzn-assoc-ad-1c38de0f-bb52-41ac-bb62-34709b57ccad",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=1c38de0f-bb52-41ac-bb62-34709b57ccad";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <Exercise1App/>
                {/* <AccordionExercise1/> */}
                {/* <div id={this.state.shoesHandbagsWalletsSunglassesID}/>  */}
            </div>
        )
    }
}