import React, { Component } from 'react'
import TimeAdjApp from './TimeAdjective'

export default class Cover_TimeAdjective extends Component {
    constructor(props) {
        super(props);
        this.state = {
            digitalMusicID: "amzn-assoc-ad-543a8fef-3a43-4dff-8e1a-d51bc29fb744",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=543a8fef-3a43-4dff-8e1a-d51bc29fb744";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <TimeAdjApp/>
                {/* <div id={this.state.digitalMusicID}/>  */}
            </div>
        )
    }
}