import React from "react";
import "./Oukaar.css";

function GridRowVowelOukaar({
  id,
  akkha_VowelOukar,
  kantipur_VowelOukar,
  Nep_Meaning,
  Eng_Meaning
}) {

  return (
    <tr className="vowelOukar_tableRow">
      <td className="akkha_vowelOukar">{akkha_VowelOukar}</td>
      <td className="kantipur_vowelOukar">{kantipur_VowelOukar}</td>
      <td className="nep_meaning_vowelOukar">{Nep_Meaning}</td>
      <td className="eng_meaning_vowelOukar">{Eng_Meaning}</td>
    </tr>
  );
}
export default GridRowVowelOukaar;
