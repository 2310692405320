import React, { Component } from 'react'
import HalfFormsHaApp from './Half_forms_Ha'

export default class CoverHalfForms_Ha extends Component {
    constructor(props) {
        super(props);
        this.state = {
            jewelryID: "amzn-assoc-ad-b36510d4-c327-47a8-bbcd-75be1070a309",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=b36510d4-c327-47a8-bbcd-75be1070a309";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <HalfFormsHaApp/>
                {/* <div id={this.state.jewelryID}/>  */}
            </div>
        )
    }
}
