import React from "react";
import "../Halfforms.css";

function GridRowHalf_forms_GeneralWords_NegationApp({
  id,
  akkha_Words,
  akkha_Meaning,
}) {
  return (
    <tr className="HalfForms_Words_tableRow_negation">
      <td className="akkha_Words_Negation">{akkha_Words}</td>
      <td className="akkha_Words_Negation_1">{akkha_Meaning}</td>
    </tr>
  );
}

export default GridRowHalf_forms_GeneralWords_NegationApp;
