import React from "react";
import './Punctuation.css'
import Punctuation_Data from './PunctuationData.json';
import GridRowPunctuationWords from "./GridRowPunctuation";


export default function PunctuationApp() {
  const PunctuationContent = Punctuation_Data.map(
    ({ id, akkha_Words, Nep_Words, Nep_Meaning, Eng_Meaning }) => (
      <GridRowPunctuationWords
        id={id}
        akkha_Words={akkha_Words}
        Nep_Words={Nep_Words}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );

  return (
    <div className="punctuation_bcg">
      <h2 className="basicTerms_headingText">Punctuation (बिराम चिन्ह)</h2>
      <div className="basicTerms_table_div">
        <table align="center">
        <thead className="basicTerms_tableHeader">
            <tr>
              <th className="punctuation_heading_table">Akkha Words</th>
              <th className="punctuation_heading_table">Nepali Trans</th>
              <th className="punctuation_heading_table">Nepali Meaning</th>
              <th className="punctuation_heading_table">English Meaning</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_second_eachCells">{PunctuationContent}</tbody>
        </table>
      </div>
    </div>
  );
}
