import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Typography} from '@material-ui/core';
import footerBackgroundImage from '../../../../assets/Images/washi-1920x1080.png'

// import "./Footer.css"

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    backgroundImage: `url(${footerBackgroundImage})`,
    marginBottom: "0",
  },
  footerText: {
    color: '#FFEF78',
    textDecoration: 'none',
    fontSize: "15px",
    textAlign: 'justify'
    // backgroundColor: 'darkcyan'
  }
}));

const Footer = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography variant="body1" className={classes.footerText}>
        
        {/* <p
          component="a"
          href="https://devias.io/"
          target="_blank"
        >
          Magar Bhasa Lipi Sipal  
        </p> */}
        {/* <p>. {getCurrentYear}</p> */}
        Magar Bhasa Lipi Sipal &copy;{' '}{new Date().getUTCFullYear()}
      </Typography>
      <Typography variant="caption" className={classes.footerText}>
      मगर भाषा र संस्कृतिको सँरक्षण तथा सम्बर्दनमा टेवा पुर्याउने अनि मगर भाषा जान्न इच्छुक सम्पूर्ण महानुभावहरु मा समर्पित ।  
      </Typography> 
      <p style={{fontFamily: 'sans-serif', color: 'aliceblue'}}>This site is developed and maintained by Robin Gurbachhan Magar.</p>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string
};

export default Footer;
