import { Divider } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../../assets/Images/magarsmajLogo.png'
// import FinancialDisclaimer from './FinancialDisclaimer'
// import ShareBtn from "react-share-button";
import WapaEndorsementVideo from './Wapa_endorsementVideo'

// const url = "magar_society_mainpage/general_meeting_notice"
// const text="Click the link below to see Invitaion/Announcement for upcoming General Meeting."


export default function GeneralMeetingNotice() {
  return (
    <div className="generalMeetingMain_container">
      <Link to="/magar_society_mainpage" onClick={() => window.scrollTo(0, 0)}>
        <button className="back_button">Back to Magar Society Page</button>
      </Link>
      <div className="generalMeetingNoticeHeader_container">
        <img src={logo} alt="" className="generalMeetingNotice_logoImage" />
        <div>
          <h1 className="generalMeetingNoticeHeaderText">
            हेरिसबर्ग मगर गुमस्ठ
          </h1>
        </div>
      </div>
      <h4 className="generalMeetingNoticeSubHeaderText">
        Buddham saranam gachhami,
      </h4>
      <h4 className="generalMeetingNoticeSubHeaderText">
        Dharma saranam gachhami,
      </h4>
      <h4 className="generalMeetingNoticeSubHeaderText">
        Sangham Saranam gachhami
      </h4>
      <h1 className="generalMeetingNoticeSubHeaderText">*** INVITATION ***</h1>
      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />
      <WapaEndorsementVideo/>
      {/* <p className="generalMeetingNotice_para">पट्टकी मिझोर,</p> */}
      <p className="generalMeetingNotice_para">
        Harrisburg Magar Gumustha Cordially Invites all our community members
        and well-wishers to celebrate 2022{' '}
        <strong style={{ color: 'lightgreen' }}>Lord Buddha Jayanti </strong>
        pooja and to receive wang from our Buddhist Monks, Lama Guru.
      </p>
      <p className="generalMeetingNotice_para">
        We Look forward to seeing you at the following date, venue, and time.{' '}
      </p>
      <p className="generalMeetingNotice_para">
        Venue:{' '}
        <strong style={{ color: 'orange' }}>
          5650 Lancaster st, Harrisburg pa 17111
        </strong>
      </p>
      <p className="generalMeetingNotice_para">
        Date:{' '}
        <strong style={{ color: 'orange' }}>Sunday, May 15th, 2022</strong>
      </p>
      <p className="generalMeetingNotice_para">
        Time: <strong style={{ color: 'orange' }}>8:00 am to 4:00 pm</strong>
      </p>
      <p className="generalMeetingNotice_para">
        Puspa-Prasaad Grahan is from:{' '}
        <strong style={{ color: 'orange' }}>1:00 pm to 2:00 pm</strong>
      </p>

      <p className="generalMeetingNotice_para">
        Should you have any question or concern, please feel free to contact us.
      </p>

      <p className="generalMeetingNotice_para">Thanky You, </p>
      <p className="generalMeetingNotice_para">Bal Monger (717-480-6730)</p>
      <p className="generalMeetingNotice_para">Gen. Secretary</p>
      <p className="generalMeetingNotice_para">or</p>
      <p className="generalMeetingNotice_para">
        Aavash Rikham Magar (704-713-0609)
      </p>
      <p className="generalMeetingNotice_para">Asst. Gen. Secretary</p>
      <p className="generalMeetingNotice_para">Harrisburg Magar Gumustha</p>
      <p className="generalMeetingNotice_para">Harrisburg, PA</p>
      {/* <ShareBtn url={url} text={text} className="ib" displayText="Share this Announcement" /> */}
      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />
      {/* <FinancialDisclaimer /> */}
    </div>
  )
}
