import React, { Component } from 'react'
import PunctuationApp from './Punctuation'

export default class Cover_Punctuation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            miscellaneousID: "amzn-assoc-ad-125e3e16-4811-423c-85ef-82c7d188fe93",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=125e3e16-4811-423c-85ef-82c7d188fe93";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <PunctuationApp/>
                {/* <div id={this.state.miscellaneousID}/>  */}
            </div>
        )
    }
}
