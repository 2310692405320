import React, { Component } from 'react'
import LamaFrontApp from './lamaAppFront'

export default class CoverLamaAppFront extends Component {
    constructor(props) {
        super(props);
        this.state = {
          ElectronicsAndHomeAudio: "amzn-assoc-ad-c38cac07-131b-4639-9297-05d2c5959cee"
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
       script.src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=c38cac07-131b-4639-9297-05d2c5959cee";
       script.async = true;
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                {/* <div id={this.state.ElectronicsAndHomeAudio}></div>  */}
                <LamaFrontApp/>
            </div>
        )
    }
}
