import React from 'react'
import "./HisAndTimeline.css"

export default function Vid_Lib() {
    return (
        <div className="vid_container">
            <div className= "text_container">
            <h2 className="text_temp">This Page is under construction.</h2>
            <h3 className="text_temp">Please bear with us.</h3>
            <h3 className="text_temp">Thank you.</h3>
            </div>
            <span style={{ padding: 15, fontFamily: "monospace" }}>
                Photo by{" "}
                <a style={{ textDecoration: null }} href="https://unsplash.com/@hojipago?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noopener noreferrer">
                Shivendu Shukla
                </a>{" "}
                on <a href="https://unsplash.com/photos/3yoTPuYR9ZY" target="_blank" rel="noopener noreferrer">Unsplash</a>
              </span>
        </div>
    )
}