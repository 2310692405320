import React from "react";
import "./ConsonantStyles.css";
function GridRowPhonemes({
  id,
  pronounce,
  pronounce_Kantipur,
  phonemeLetters,
}) {
  return (
    <tr className="GridRowPhonemesContainer">
      <td className="phoneme_sound">{pronounce}<h3>{pronounce_Kantipur}</h3></td>
      <td className="phoneme_letters">{phonemeLetters}</td>
    </tr>
  );
}

export default GridRowPhonemes;
