// import "./styles.css";
import LocalInvitation1 from '../../assets/Documents/local_Invitation_1.pdf'
export default function InvitationDownLoadApp() {
  return (
    <div className="App">
      <button style={{ padding: '10px', border: 'none', borderRadius: '20px' }}>
        <a
          href={LocalInvitation1}
          target="_blank"
          rel="noopener noreferrer"
          // download
          style={{
            textDecoration: 'none',
            color: 'gray',
            fontWeight: '600',
            zIndex: '1500',
          }}
        >
          Local Invitation 1
        </a>
      </button>
      {/* <a style={{ textDecoration: "none", color: 'white', zIndex: '1000' }} href={Invitation} target="_blank" rel="noopener noreferrer">
                  Download this in PDF
                </a> */}
    </div>
  )
}
