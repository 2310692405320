import React from "react";
import "./Aekaar.css";

function GridRowVowelAekaar({
  akkha_VowelAekar,
  kantipur_VowelAekar,
  Nep_Meaning,
  Eng_Meaning
}) {

  return (
    <tr className="vowelAekar_tableRow">
      <td className="akkha_vowelAekar">{akkha_VowelAekar}</td>
      <td className="kantipur_vowelAekar">{kantipur_VowelAekar}</td>
      <td className="nep_meaning_vowelAekar">{Nep_Meaning}</td>
      <td className="eng_meaning_vowelAekar">{Eng_Meaning}</td>
    </tr>
  );
}

export default GridRowVowelAekaar;
