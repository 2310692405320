import React, { Component } from 'react'
import HalfFormsWaApp from './Half_forms_Wa'

export default class CoverHalfForms_Wa extends Component {
    constructor(props) {
        super(props);
        this.state = {
            healthAndHouseholdID: "amzn-assoc-ad-fc411906-d3b7-46ea-8d92-0caf2c8840ee",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=fc411906-d3b7-46ea-8d92-0caf2c8840ee";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <HalfFormsWaApp/>
                {/* <div id={this.state.healthAndHouseholdID}/>  */}
            </div>
        )
    }
}
