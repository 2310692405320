import React, { Component } from 'react'
import Exercise5App from './Exercise5'

export default class Cover_Exercise5 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            homeImprovementID: "amzn-assoc-ad-51837cba-71ae-4088-b9c0-a3273b938d4b",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=51837cba-71ae-4088-b9c0-a3273b938d4b";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <Exercise5App/>
                {/* <div id={this.state.homeImprovementID}/>  */}
            </div>
        )
    }
}
