
// image should be of 0:2.7 ratio with dimension of 950x350 pixel

import feeds from "./feed.jpg";
import runningAthlet from "./runningAthlet.jpg";
import door from "./door.jpg";
import fishCatch from "./catch.jpg";
import fair from "./fair.jpg";
import yell from "./yell.jpg";
import pickle from "./pickle.jpg";
import win from "./win.jpg";
import soil from "./soil.jpg";
import standing from "./standing.jpg";
  
  
const feedLink1 = "https://unsplash.com/@daiga_ellaby?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const Link2 = "https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const feedLink_Photographer = "Daiga Ellaby"

const runningAthletLink1 = "https://unsplash.com/@jon_chng?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const runningAthletLink_Photographer = "Jonathan Chng"
  

const doorLink1 = "https://unsplash.com/@chiara_art?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const doorLink_Photographer = "Chiara Polo"
  

const fishCatchLink1 = "https://unsplash.com/@jeremybezanger?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const fishCatchLink_Photographer = "Jeremy Bezanger"
  

const fairLink1 = "https://unsplash.com/@gabrielvdz?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const fairLink_Photographer = "Gabriel Valdez"

const yellLink1 = "https://unsplash.com/@finjapetersen?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const yellLink_Photographer = "Finja Petersen"
  
const pickleLink1 = "https://unsplash.com/@bite_size01?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const pickleLink_Photographer = "Ryan Concepcion"

  
const winLink1 = "https://unsplash.com/@izgubljenausvemiru?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const winLink_Photographer = "Tijana Drndarski"

const soilLink1 = "https://unsplash.com/@gabrielj_photography?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const soilLink_Photographer = "Gabriel Jimenez"

const standingLink1 = "https://unsplash.com/@joshuaearle?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
const standingLink_Photographer = "Joshua Earle"

  

const feedText = "kAsSc";
const feeds_Neptrans = "(कास्च)";
const feeds_Meaning = "Feeding (खुवाएको)"

const runningAthletText = "KQrAkSkE";
const runningAthlet_Neptrans = "(खेराक्की)";
const runningAthlet_Meaning = "Run (दौडाउनु)"

const doorText= "gAlAm";
const door_Neptrans= "(गालाम)";
const door_Meaning = "Door (ढोका)"

const fishCatchText="GOc";
const fishCatch_Neptrans="(घोच)";
const fishCatch_Meaning = "Caught (समाएको)"

const fairText="zAr";
const fair_Neptrans="(ङार)"
const fair_Meaning = "Fair (मेला)"

const yellText = "cSyAkSkE";
const yells_Neptrans = "(च्याक्की)";
const yells_Meaning = "Yell (कराउनु)"

const pickleText = "COp";
const pickle_Neptrans = "(छोप)";
const pickle_Meaning = "Pickle (अचार)"

const winText= "jYkE";
const win_Neptrans= "(जुकी)";
const win_Meaning = "Win (जित्नु)"

const soilText="JA";
const soil_Neptrans="(झा)";
const soil_Meaning = "Soil (माटो)"

const standingText="tOzSc";
const standing_Neptrans="(टोङ्च)"
const standing_Meaning = "Standing (उभेको)"

export const media = [feeds, runningAthlet, door, fishCatch, fair, yell, pickle, win, soil, standing];
export const mediaByIndex = index => media[index % media.length];

export const link1 = [feedLink1, runningAthletLink1, doorLink1, fishCatchLink1, fairLink1, yellLink1, pickleLink1, winLink1, soilLink1, standingLink1];
export const linkByIndex1 = index => link1[index % link1.length];

export const link2 = [Link2];
export const linkByIndex2 = index => link2[index % link2.length];

export const linkPhotographer = [feedLink_Photographer, runningAthletLink_Photographer, doorLink_Photographer, fishCatchLink_Photographer, fairLink_Photographer, yellLink_Photographer, pickleLink_Photographer, winLink_Photographer, soilLink_Photographer, standingLink_Photographer];
export const linkPhotographerByIndex = index => linkPhotographer[index % linkPhotographer.length];

export const text = [feedText, runningAthletText, doorText, fishCatchText, fairText, yellText, pickleText, winText, soilText, standingText];
export const TextkByIndex = index => text[index % text.length];

export const nep_trans = [feeds_Neptrans, runningAthlet_Neptrans, door_Neptrans, fishCatch_Neptrans, fair_Neptrans, yells_Neptrans, pickle_Neptrans, win_Neptrans, soil_Neptrans, standing_Neptrans ];
export const TextkByIndex1 = index => nep_trans[index % nep_trans.length];

export const meaning = [feeds_Meaning, runningAthlet_Meaning, door_Meaning, fishCatch_Meaning, fair_Meaning, yells_Meaning, pickle_Meaning, win_Meaning, soil_Meaning, standing_Meaning ];
export const MeaningByIndex = index => meaning[index % meaning.length];


// // image should be of 0:2.7 ratio with dimension of 950x350 pixel

// import snacks from "./snacks.jpg";
// import graze from "./graze.jpg";
// import garlic from "./garlic.jpg";
// import hide from "./Hide.jpg";
// import sale from "./sale.jpg";
  
  
// const snackLink1 = "https://unsplash.com/@gillyberlin?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
// const snackLink2 = "https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
// const snackLink_Photographer = "Gilly"

// const grazeLink1 = "https://unsplash.com/@calepinaud?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
// const grazeLink2 ="https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
// const grazeLink_Photographer = "Camilo Pinaud"

// const garlicLink1 = "https://unsplash.com/@izgubljenausvemiru?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
// const garlicLink2 = "https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
// const garlicLink_Photographer = "Tijana Drndarski"

// const hideLink1 = "https://unsplash.com/@bady?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
// const hideLink2 = "https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
// const hideLink_Photographer = "Bady abbas"

// const saleLink1 = "https://unsplash.com/@belart84?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
// const saleLink2 = "https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText";
// const saleLink_Photographer = "Artem Beliaikin"

// const snackText = "arnE";
// const snacks_Neptrans = "(अरनी)";
// const snacks_Meaning = "Snacks (खाजा/नास्ता)"

// const grazeText = "aArCAkQ";
// const graze_Neptrans = "(आर्छाके)";
// const graze_Meaning = "Graze (चराउनु)"

// const garlicText= "aAryAk";
// const garlic_Neptrans= "(आर्याक)";
// const garlic_Meaning = "Garlic (लसुन)"

// const hideText="aArcSyYkQ";
// const hide_Neptrans="(आर्च्युके)";
// const hide_Meaning = "Hide (लुक्नु)"

// const saleText="aArlAkQ";
// const sale_Neptrans="(आर्लाके)"
// const sale_Meaning = "Sale (विक्री गर्नु)"



