import React from "react";
import "./GyaraKharistyles.css";

function GridRowVowelsAndSymbols({
  id,
  akkha_GyaraKhariExampleFirstColumn,
  kantipur_GyaraKhariExampleFirstColumn,
  Akkha_GyaraKhariExampleSecondColumn,
  kantipur_GyaraKhariExampleSecondColumn
}) {
  
  return (
    <tr className="gyaraKhari_tableRow">
        <td className="gyarakhari_akkha">{akkha_GyaraKhariExampleFirstColumn}</td>
        <td className="gyarakhari_kantipur">{kantipur_GyaraKhariExampleFirstColumn}</td>
        <td className="gyarakhari_akkha">{Akkha_GyaraKhariExampleSecondColumn}</td>
        <td className="gyarakhari_kantipur">{kantipur_GyaraKhariExampleSecondColumn}</td>
    </tr>
  );
}

export default GridRowVowelsAndSymbols;
