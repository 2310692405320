import React, { Component } from "react";
import LamaApp from "./lamaAppBack";
import AffiliateDisclaimer from "../pages/AffiliateDisclaimer";

export default class CoverLamaAppBack extends Component {
  constructor(props) {
    super(props);
    this.state = {
      CellphonesAndAccessories:
        "amzn-assoc-ad-59c79e33-c468-41d5-b983-822ddc8877e4",
    };
  }

  componentDidMount() {
    const script = document.createElement("script");

    script.src =
      "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=59c79e33-c468-41d5-b983-822ddc8877e4";
    script.async = true;

    document.body.appendChild(script);
  }
  render() {
    return (
      <div>
        {/* <div id={this.state.ElectronicsAndHomeAudio}></div>  */}
        {/* <AffiliateDisclaimer/> */}
        <LamaApp />
        {/* <div id={this.state.CellphonesAndAccessories}></div> */}
      </div>
    );
  }
}
