import React, { Component } from 'react'
import Exercise2App from './Exercise2'

export default class Cover_Exercise2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SoftwareID: "amzn-assoc-ad-cba78971-d439-4e47-9e01-1cb200106f4c",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=cba78971-d439-4e47-9e01-1cb200106f4c";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <Exercise2App/>
                {/* <div id={this.state.SoftwareID}/>  */}
            </div>
        )
    }
}