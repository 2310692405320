import React from "react";
import "./GyaraKharistyles.css";

function GridRowVowelsAndSymbols({
  id,
  akkha_GyaraKhariWordFirstColumn,
  kantipur_GyaraKhariWordFirstColumn,
  Akkha_GyaraKhariWordSecondColumn,
  kantipur_GyaraKhariWordSecondColumn
}) {
  return (
    <tr className="gyaraKhari_tableRow">
        <td className="gyarakhari_akkha">{akkha_GyaraKhariWordFirstColumn}</td>
        <td className="gyarakhari_nep_Trans">{kantipur_GyaraKhariWordFirstColumn}</td>
        <td className="gyarakhari_nep_meaning">{Akkha_GyaraKhariWordSecondColumn}</td>
        <td className="gyarakhari_eng_meaning">{kantipur_GyaraKhariWordSecondColumn}</td>
    </tr>
  );
}

export default GridRowVowelsAndSymbols;
