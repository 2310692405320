import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { AppBar, Toolbar } from '@material-ui/core'
// import Typed from 'react-typed'
// import MenuIcon from '@material-ui/icons/Menu'

import logo from '../../../../assets/Images/bama_logo.png'
import './Topbar.css'

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props

  return (
    <AppBar {...rest} style={{ backgroundColor: '#2e8dab' }}>
      <Toolbar className="topbar_container_bama">
        <RouterLink to="/">
          <img alt="Logo" src={logo} className="logo_img_bama" />
        </RouterLink>
        <h1 className="head_text_bama">
          Bhutanese American Magar Association, USA
        </h1>
        <div className="flexGrow_bama" />
      </Toolbar>
    </AppBar>
  )
}

Topbar.propTypes = {
  className: PropTypes.string,
  // onSidebarOpen: PropTypes.func,
}

export default Topbar
