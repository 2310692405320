import React from "react";
import "./Okaar.css";

function GridRowVowelOkaar({
  id,
  akkha_VowelOkar,
  kantipur_VowelOkar,
  Nep_Meaning,
  Eng_Meaning
}) {
  return (
    <tr className="vowelOkar_tableRow">
      <td className="akkha_vowelOkar">{akkha_VowelOkar}</td>
      <td className="kantipur_vowelOkar">{kantipur_VowelOkar}</td>
      <td className="nep_meaning_vowelOkar">{Nep_Meaning}</td>
      <td className="eng_meaning_vowelOkar">{Eng_Meaning}</td>
    </tr>
  );
}
export default GridRowVowelOkaar;
