import React from 'react'
import PropTypes from 'prop-types'

import { Topbar, Footer } from './components'

const Main = (props) => {
  const { children } = props

  return (
    <div>
      <Topbar />
      <div style={{ margin: '150px auto 20px auto' }}>{children}</div>
      <Footer />
    </div>
  )
}

Main.propTypes = {
  children: PropTypes.node,
}

export default Main
