import React, { Component } from 'react'
import VowelOkaar from './VowelOkaar';

export default class CoverVowelOkaar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          computersTabletsAndComponentsID: "amzn-assoc-ad-d05bd54e-1912-468b-8ddb-e0a398f20a54",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=d05bd54e-1912-468b-8ddb-e0a398f20a54";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <VowelOkaar/>
                {/* <div id={this.state.computersTabletsAndComponentsID}></div>  */}
            </div>
        )
    }
}