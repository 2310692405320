import React from "react";
import "./Ukaar.css";

function GridRowVowelAakaar({
  id,
  akkha_VowelUkar,
  kantipur_VowelUkar,
  Nep_Meaning,
  Eng_Meaning
}) {
  return (
    <tr className="vowelUkar_tableRow">
      <td className="akkha_vowelUkar">{akkha_VowelUkar}</td>
      <td className="kantipur_vowelUkar">{kantipur_VowelUkar}</td>
      <td className="nep_meaning_vowelUkar">{Nep_Meaning}</td>
      <td className="eng_meaning_vowelUkar">{Eng_Meaning}</td>
    </tr>
  );
}

export default GridRowVowelAakaar;
