import React, { Component } from 'react'
import AllVowelWordsApp from './AllVowelWords';

export default class CoverAllVowelWords extends Component {constructor(props) {
    super(props);
    this.state = {
      electronicComponentsAndHomeAudioID: "amzn-assoc-ad-c38cac07-131b-4639-9297-05d2c5959cee",
    };
  }

componentDidMount() {
    const script = document.createElement("script");

   script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=c38cac07-131b-4639-9297-05d2c5959cee";
   script.async = true;

   document.body.appendChild(script);
  }
render() {
    return (
        <div>
            <AllVowelWordsApp/>
            {/* <div id={this.state.electronicComponentsAndHomeAudioID}></div>  */}
        </div>
    )
}
}