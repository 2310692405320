import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Typography } from "@material-ui/core";
import footerBackgroundImage from "../../../../assets/Images/washi-1920x1080.png";

// import "./Footer.css"
const facebookUrl = "https://www.facebook.com/bama.magar.7";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    maxWidth: "100%",
    // backgroundImage: `url(${footerBackgroundImage})`,
    backgroundColor: "#2e8dab",
    marginBottom: "0",
  },
  footerText: {
    color: "#FFEF78",
    textDecoration: "none",
    fontSize: "15px",
    textAlign: "center",
    // backgroundColor: 'darkcyan'
  },
}));

const Footer = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <Typography>
        <p
          style={{
            fontFamily: "sans-serif",
            color: "aliceblue",
            textAlign: "center",
          }}
        >
          408 Briar Ln., Chambersburg, PA 17202
        </p>
      </Typography>
      <p
        style={{
          fontFamily: "sans-serif",
          color: "aliceblue",
          textAlign: "center",
        }}
      >
        EMAIL: bhutanesemagar@gmail.com
      </p>
      <p
        style={{
          fontFamily: "sans-serif",
          color: "aliceblue",
          textAlign: "center",
          lineHeight: 1.5,
        }}
      >
        Find us on Facebook as{" "}
        <a
          href={facebookUrl}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            textDecoration: "underline",
            color: "gold",
            padding: "3.5px",
            fontFamily: "helvetica",
            fontWeight: "900",
          }}
        >
          BAMA MAGAR
        </a>
      </p>
    </div>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
};

export default Footer;
