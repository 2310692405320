import React, { Component } from 'react'
import HalfFormsReeApp from './Half_forms_Ree'

export default class CoverHalfForm_Ree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kindleStoreID: "amzn-assoc-ad-9a616693-95c3-44ae-90a8-a7ea0479c6fd",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=9a616693-95c3-44ae-90a8-a7ea0479c6fd";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <HalfFormsReeApp/>
                {/* <div id={this.state.kindleStoreID}/>  */}
            </div>
        )
    }
}
