import React from "react";
import Accordion from '../Accordion'

import "../ExerciseAccordion.css";

function tryAccordionapp() {
  return (
      <Accordion>
        <div className="accor">
          <div className="head noselect">Nepali Transliteration</div>
          <div className="body">जाटौकेनाङ: टिस्यमिङ, टेरीयाक, ट्याङ्छ, टाङ्मा, ठूनछ, ठूक्की, ठाक्की, ठोक्की, ठूरिड, ठान।  डाकी, डाेन्खी, डुङकी, डासकी, डापकी ड डुन्खी, ढानकी, ढेन्नाम, ढेस्च ड ढयाक।  टिस्यमिङ ह्या कोखी माझ्या डेच ढुट डासिङ।</div>
        </div>
      </Accordion>
  );
}

export default tryAccordionapp;
