import { Divider } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
// import logo from '../../assets/Images/magarsmajLogo.png'
// import FinancialDisclaimer from '../FinancialDisclaimer'
import DownLoadPdf from '../../components/DOCUMENT_DOWNLOADER/invitationDownload'
import LocalInvitation_1 from '../../components/DOCUMENT_DOWNLOADER/Localinvitation_1'
import EndorsementVideo from './endorsementVideo'
// import ShareBtn from "react-share-button";

// const url = "magar_society_mainpage/general_meeting_notice"
// const text="Click the link below to see Invitaion/Announcement for upcoming General Meeting."

export default function MaghaySakrantiNotice() {
  return (
    <div className="generalMeetingMain_container">
      <div className="invitation_buttonContainer">
        <Link
          to="/magar_society_mainpage"
          onClick={() => window.scrollTo(0, 0)}
        >
          <button className="invitationBackBtn">Back</button>
        </Link>
        <DownLoadPdf />
      </div>
      {/* <div className="generalMeetingNoticeHeader_container">
        <img src={logo} alt="" className="generalMeetingNotice_logoImage" />
        <div>
          <h2 className="generalMeetingNoticeHeaderText">भूटनिज मगर गुमस्ठ</h2>
          <h3 className="generalMeetingNoticeSubHeaderText">
            हेरिसबर्ग, पेन्सिलभेनिया
          </h3>
        </div>
      </div> */}

      <h2 className="generalMeetingNoticeHeading">
        *** हार्दिक निमन्त्रणा ***{' '}
      </h2>
      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />
      <EndorsementVideo />
      <p className="generalMeetingNoticeDate">March 25, 2022</p>
      <p className="generalMeetingNotice_para">
        सबैमा नमस्कार (पट्टकी मिझोरले)
      </p>
      <p className="generalMeetingNotice_para">
        ‘भूटानिस अमेरिकन मगर संघ’ (BAMA) को तेस्रो राष्ट्रिय सम्मेलन यही आउदो
        जुलाई महीनाको मितिः-७-१६ र १७-२०२२, शनिवार र आइतवार दुईदिने कार्यक्रम
        अमेरिकाको पेन्सिलभेनिय राज्य अन्तर्गत ह्यारिस्वर्ग (Harrisburg) शहरमा
        भव्यताको साथ सुसम्पन्न हुन गइरहेको हॅुदा यहॅा लगायत यहॅाका सम्पूर्ण
        परिवारहरुलाई विशेष निमन्त्रणा (BAMA) अन्तर ह्रदयबाट सनम्र अनुरोध गर्दछ ।
      </p>
      {/* <p className="generalMeetingNotice_para">
        सबैको स्वास्थ्यलाई पहिलो स्थानमा राख्दै, सबै शुभेच्छुकहरूको आवाज
        सूचीबद्ध गर्दै र पछिल्लो समय Covid Case हरू बढेका कारणले, म तपाईंलाई
        जानकारी गराउन चाहन्छु कि <strong>जनवरी 16, 2022</strong> मा तय गरिएको
        मगरहरुको महान चाड माघे संक्रान्तिको दिन हुने भनिएको संस्कृतिक कार्यक्रम{' '}
        <strong>रद्द (Cancel)</strong> गरिएको छ।तपाईको दयालु सहयोगको लागि सबैलाई
        धन्यवाद। यदि भविष्यमा स्थिति सामान्य भएको खण्डमा हामी आगामी कार्यक्रमहरू
        मनाउन तत्पर छौं।
      </p> */}
      <p className="generalMeetingNotice_para">
        यस सम्मेलन अति महत्वपूर्ण रहनेछ किन कि, अमेरिकाको पचासै राज्यहरुबाट र
        अमेरिका बाहिरबाट पनि सम्पूर्ण मगरहरूका सहभागिता रहने छन् ।
      </p>
      <p className="generalMeetingNotice_para">
        यस कार्यक्रमका कार्य झलकहरु निम्न प्रकारका छन्ः-
      </p>
      {/* <div className="ordered_list"> */}
      <ol className="ordered_list">
        <li>मगर वापामापाहरुबाट प्रवचन वा पाठपूजा गरिने ।</li>
        <li>( BAMA ) को ‘Bylaws’ (उपनियामहरु ) घोषणा गरिने ।</li>
        <li>मगर अग्रजहरुबाट छोटो मन्तव्य राखिने ।</li>
        <li>
          प्रत्येक राज्य वा शहरहरुबाट आएका प्रतिनिधिहरुबाट आ-आफ्नो छोटो
          विचारविमर्श राखिने ।
        </li>
        <li>बाह्र मगरहरुको पुस्तक विमोचन गरिने ।</li>
        <li>वापामापाहरुको निर्देशिका लोकार्पण गरिने ।</li>
        <li>
          मगर जातिहरुका पहिचान र सॅास्कृतिक आभास दिने खाल्का नाचगानहरु प्रस्तुत
          गरिने ।
        </li>
        <li>
          (BAMA) ‘भूटानिस अमेरिकन मगर संघ’ का नयॅा कार्यकर्ताहरुको छनोट गरिने ।
        </li>
        <li>
          शैक्षिक क्षेत्रबाट उत्तीर्णा गरेका बालबालिकाहरुलाई प्रमाण पत्र वितरण
          गरिने छ ।
        </li>
        <li>BAMA को बजेट रिपोर्ट  पेस गरिने छ ।</li>
      </ol>
      {/* </div> */}

      <p className="generalMeetingNotice_para">
        यस्तै अरु थुप्रै रोचक लाभदायक प्रकारका कार्यहरु पनि रहने छन् । 
      </p>
      <p className="generalMeetingNotice_para">
        यो हाम्रै आफ्नै ‘भूटानिस अमेरिकन मगर संघ’ हो, यस ऐतिहासिक सम्मेलनलाई
        सौहार्दपूर्ण सफल पार्नुमा हजुरहरुको ओजस्वी हात रहने छ ।
      </p>
      <div className="important_points">
        <p className="generalMeetingNotice_para">Important/महत्वपूर्ण:</p>
        <ol className="ordered_list">
          <li>
            आफ्नो राज्यबाट कसलाई जिताएर बोर्डमा पठाउनु चाहनु हुन्छ, उम्मेदवारको
            नाम ,ठेगाना र फोन न: June 1st 2022 to July 13th 2022 रात्री समय
            ११:५९ (11.59pm) सम्ममा यो email:bhutanesemagar@gmail.com मार्फत
            पठाइसक्नु पर्ने हुन्छ । (उमेत्द्वर को उमेर १८ बर्ष भन्दा माथि र सो
            देश को नगरिक्त हुनु जरुरी छ){' '}
          </li>
          <li>
            आफ्नो भाषा,भेष,धर्म र सॅास्कृतिको सम्मान र उजागर गराऊ भन्ने नारालाई
            टेवा पुर्याउन सबै जनहरुलाई आह्वान  अनि अनुरोध गर्न चाहन्छौं ।छोरा 
            मान्छेले घल्याङ ( सेतो पोसाक )मगर ड्रेस र छोरी माछेले लुङ्गी लगाइ
            मगर हुं भन्ने आफ्नो पहिचान आफै दिनु होला । 
          </li>
        </ol>
      </div>
      <p className="generalMeetingNotice_para">धन्यवाद।</p>
      <p className="generalMeetingNotice_para">
        कार्यक्रम स्थान:{' '}
        <strong>
          Scottish Rite Theatre & Ballroom, 2701 North 3rd Street, Harrisburg,
          PA-17110
        </strong>
      </p>

      <p className="generalMeetingNotice_para">
        कार्यक्रम समय: <strong>July 16th & 17th, 2022 from 8am to 4pm.</strong>
      </p>
      <p className="generalMeetingNotice_para">
        आयोजक:{' '}
        <strong>मगर समाज ह्यारिस्वर्ग पेन्सिलभेनिया (भूटनिज मगर गुमस्ठ)</strong>
      </p>
      <p className="generalMeetingNotice_para">
        सह-आयोजक:: <strong>भुट्निज अमेरिकन मगर एसोसिएसन (बामा) </strong>
      </p>

      <p className="generalMeetingNotice_para">अध्यक्ष: नर बलम्पाकी मगर</p>
      <p className="generalMeetingNotice_para">महासचिव: बल आले मगर </p>
      <p className="generalMeetingNotice_para">भूटनिज मगर गुमस्ठ</p>
      <p className="generalMeetingNotice_para">हेरिसबर्ग, पेन्सिलभेनिया</p>
      {/* <ShareBtn url={url} text={text} className="ib" displayText="Share this Announcement" /> */}

      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />
      <div className="contact_persons">
        <p className="generalMeetingNotice_para">सम्पर्क व्यक्तिहरुः-</p>
        <ol className="ordered_list">
          <li>नर बलाम्पाकीमगर ७१७-३५०-८००९ /717-350-8009 </li>
          <li>बल आलेमगर ७१७-४८०-६७३०/717-480-6730</li>
          <li>सर्मन समालमगर ६७८-८८७-०४७८/678-887-0478</li>
          <li>पुर्ण बालम्पकीमगर ३३०-९९९-१९००/330-999-1900</li>
          <li>टहलमान पिठकोटेमगर ५१०-६०६-३९७५/510-606-3975</li>
        </ol>
      </div>
      {/* <FinancialDisclaimer /> */}
      <LocalInvitation_1 />
      <Link
        to="/magar_society_mainpage/bama_ConventionInvitation_toAll"
        onClick={() => window.scrollTo(0, 0)}
      >
        <button className="invitationBackBtn">Bama Invitation</button>
      </Link>
    </div>
  )
}
