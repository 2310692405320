import { Divider } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/Images/magarsmajLogo.png";
import FinancialDisclaimer from "./FinancialDisclaimer";
// import ShareBtn from "react-share-button";

// const url = "magar_society_mainpage/general_meeting_notice"
// const text="Click the link below to see Invitaion/Announcement for upcoming General Meeting."

export default function GeneralMeetingNotice() {
  return (
    <div className="generalMeetingMain_container">
      <Link to="/magar_society_mainpage" onClick={() => window.scrollTo(0, 0)}>
        <button className="back_button">Back to Magar Society Page</button>
      </Link>
      <div className="generalMeetingNoticeHeader_container">
        <img src={logo} alt="" className="generalMeetingNotice_logoImage" />
        <div>
          <h1 className="generalMeetingNoticeHeaderText">
            ह्यारिसबर्ग मगर गुमस्ठ
          </h1>
        </div>
      </div>
      <h3 className="generalMeetingNoticeSubHeaderText">
        *** सदस्य शुल्क (Member Fee $50) सम्बन्धी सूचना ***
      </h3>
      <Divider
        style={{
          backgroundColor: "white",
          maxWidth: "1124px",
          margin: "20px auto",
        }}
      />
      <p className="generalMeetingNotice_para">आदरणीय गुमस्ठ सदस्यहरु,</p>
      <p className="generalMeetingNotice_para">
        ६ महिने (जुलाई २०२२-डिसेम्बर २०२२) सदस्य शुल्क तिर्न बाँकी हुनेले यो
        जुलाईको अन्त्यसम्ममा तिर्नुपर्नेछ भनी सबैलाई यो मैत्रीपूर्ण सूचना दिन
        चाहन्छु। यदि त्यस समय पछि भुक्तान गरियो वा बाँकी रह्यो भने, प्रत्येक
        महिना $5 थपिनेछ भन्ने जानकारी गराउन चाहन्छु। कृपया ढिलो नगरी,
        कोषाध्यक्षको ठेगानामा
        <strong style={{ color: "orange" }}>
          (5661 Fordham Ave. Harrisburg, PA-17111)
        </strong>{" "}
        आफ्नो शुल्क बुझाउनु हुनेछ भन्ने आशा गर्दछु। हामी छिट्टै शुल्क तिर्न
        सजिलो हुने तरिकामा पनि काम गरिरहेका छौं। कृपया यसमा धैर्य गर्नुहोस्।
      </p>
      <p className="generalMeetingNotice_para">
        यदि छ भने, कृपया आफ्नो सम्पर्कमा रहेका नयाँ मगर परिवारलाई सदस्यता
        प्राप्त गर्नको लागि भर्ना सुचारु भएको जानकारी गराउनुहोस्।
      </p>

      <p className="generalMeetingNotice_para">
        यदि तपाइँसँग कुनै प्रश्न वा जिज्ञासा भए, कृपया Bal Monger (717-480-6730)
        लाई सम्पर्क गर्न नहिचकिचाउनुहोस्।
      </p>

      <p className="generalMeetingNotice_para">धन्यवाद।</p>
      <p className="generalMeetingNotice_para">बल आले मगर</p>
      <p className="generalMeetingNotice_para">महासचिव</p>
      <p className="generalMeetingNotice_para">ह्यारिसबर्ग मगर गुमस्ठ</p>
      <p className="generalMeetingNotice_para">ह्यारिसबर्ग, पेन्सिलभेनिया</p>
      {/* <ShareBtn url={url} text={text} className="ib" displayText="Share this Announcement" /> */}
      <Divider
        style={{
          backgroundColor: "white",
          maxWidth: "1124px",
          margin: "20px auto",
        }}
      />
      {/* <FinancialDisclaimer /> */}
    </div>
  );
}
