import React, { Component } from 'react'
import VowelOukaar from './VowelOukaar';

export default class CoverVowelOukaar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          bluRayAndDvdID: "amzn-assoc-ad-a4327e66-7ddb-4d04-b20c-991065b6a1c9",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=a4327e66-7ddb-4d04-b20c-991065b6a1c9";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <VowelOukaar/>
                {/* <div id={this.state.bluRayAndDvdID}></div>  */}
            </div>
        )
    }
}
