import React, { Component } from 'react'
import HalfFormsGeneralApp from './Half_forms_General'

export default class CoverHalfForms_General extends Component {
    constructor(props) {
        super(props);
        this.state = {
            magazinesID: "amzn-assoc-ad-d90a5ba4-e772-4a7c-95a3-9fd34eefe2a9",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=d90a5ba4-e772-4a7c-95a3-9fd34eefe2a9";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <HalfFormsGeneralApp/>
                {/* <div id={this.state.magazinesID}/>  */}
            </div>
        )
    }
}
