// import "./styles.css";
import Invitation from "../../assets/Documents/BAMA_INVITATION.pdf";
export default function InvitationDownLoadApp() {
  return (
    <div className="App">
      <button style={{ padding: "10px", border: "none", borderRadius: "20px" }}>
        <a
          href={Invitation}
          target="_blank"
          rel="noopener noreferrer"
          // download
          style={{
            textDecoration: "none",
            color: "gray",
            fontWeight: "600",
            zIndex: "1500",
          }}
        >
          View in PDF
        </a>
      </button>
      {/* <a style={{ textDecoration: "none", color: 'white', zIndex: '1000' }} href={Invitation} target="_blank" rel="noopener noreferrer">
                  Download this in PDF
                </a> */}
    </div>
  );
}
