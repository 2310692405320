import { Divider } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/Images/magarsmajLogo.png'
import FinancialDisclaimer from './FinancialDisclaimer'
// import ShareBtn from "react-share-button";

// const url = "magar_society_mainpage/general_meeting_notice"
// const text="Click the link below to see Invitaion/Announcement for upcoming General Meeting."

export default function GeneralMeetingNotice() {
  return (
    <div className="generalMeetingMain_container">
      <Link to="/magar_society_mainpage" onClick={() => window.scrollTo(0, 0)}>
        <button className="back_button">Back to Magar Society Page</button>
      </Link>
      <div className="generalMeetingNoticeHeader_container">
        <img src={logo} alt="" className="generalMeetingNotice_logoImage" />
        <div>
          <h1 className="generalMeetingNoticeHeaderText">हेरिसबर्ग मगर समाज (गुमस्ठ)</h1>
        </div>
      </div>
      <h3 className="generalMeetingNoticeSubHeaderText">
        *** साधारण सभा (General Meeting) सूचना ***
      </h3>
      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />
      <p className="generalMeetingNotice_para">पट्टकी मिझोर,</p>
      <p className="generalMeetingNotice_para">
        सम्पूर्ण हेरिसबर्ग मगर समाज सदस्यहरुमा यो सूचित गरिन्छ कि, हामी{' '}
        <strong style={{ color: 'orange' }}>
          आउँदो September 24, 2022{' '}, शनिबारको दिन,
        </strong>{' '}
        मा साधारण सभा (general meeting) आयोजना गर्दैछौं। यही बैठकमा सबैको सहमति अनुसार महिला अध्यक्ष छनौट गर्ने, हाम्रो कोष (financial) व्यवस्थापनमा कसरी गर्ने, ह्यारिसबर्ग मगर समाजको लागि नयाँ अध्यक्ष र बोर्ड सदस्यहरू छनौट गर्ने लगायत अन्य महत्वपुर्ण बिषयमा
छलफल गरी सबैको सहमति घोषणा गर्नु पर्ने भएकाले कृपया सबै सदस्यहरू तोकिएको समयमा उपस्थित भइदिनुहोला। 
      </p>
      
      <p className="generalMeetingNotice_para">
        भेला ठेगाना:{' '}
        <strong style={{ color: 'orange' }}>
          5661 Fordham Ave. Harrisburg, PA-17111
        </strong>
      </p>
      <p className="generalMeetingNotice_para">
        भेला हुने समय: <strong style={{ color: 'orange' }}>10:30 AM.</strong>
      </p>
      <p className="generalMeetingNotice_para">
        भेला सकिने समय: <strong style={{ color: 'orange' }}>12:30 pm.</strong>
      </p>
      <p className="generalMeetingNotice_para">हामीले हाम्रो बोर्ड सदस्यहरूबाट प्रत्येक सदस्य समूहको लागि एक प्रतिनिधि नियुक्त गरेका छौं जसले तपाईंलाई कहिलेकाहीँ फोन कल रिमाइन्डरहरू दिनेछ। हामीले यो हाम्रो समाजको राम्रोको लागि गरिरहेका छौं। कृपया आफ्नो प्रतिनिधिलाई विनम्रतापूर्वक बोलेर सहयोग गर्नुहोस्।</p>
      
      <p className="generalMeetingNotice_para">
        यदि तपाइँसँग कुनै प्रश्न वा जिज्ञासा छ भने, कृपया Bal Monger
        (717-480-6730) लाई सम्पर्क गर्न नहिचकिचाउनुहोस्।
      </p>

      <p className="generalMeetingNotice_para">धन्यवाद।</p>
      <p className="generalMeetingNotice_para">बल आले मगर</p>
      <p className="generalMeetingNotice_para">महासचिव</p>
      <p className="generalMeetingNotice_para">हेरिसबर्ग मगर समाज (गुमस्ठ)</p>
      <p className="generalMeetingNotice_para">हेरिसबर्ग</p>
      {/* <ShareBtn url={url} text={text} className="ib" displayText="Share this Announcement" /> */}
      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />
      {/* <FinancialDisclaimer /> */}
    </div>
  )
}
