import React from "react";
import Exercise2AccordionApp from "./Exercise2Accordion";
import './styles.css'

export default function Exercise2App() {
  return (
    <div className="Exercise_container">
      <h3 className="Exercise_akkha_Para">
        jAtWkQnAz, tEsSyAmEz, tQrEyAk, tSyAzC, tAzmA, TUnc, TUkSkE, TAkSkE, TOkSkE, TUrEd TAn. dAkE, dOnKE, dYzkE, dAskE, dApkE d dYnKE, DAnkE, DQnSnAm, DQsc d DSyAk. tEsSyAmEz hSyA kOKE mAvSyA dQc DUt dAsEz.</h3>
      <Exercise2AccordionApp />
    </div>
  );
}
