import React, { Component } from 'react'
import SevenDaysApp from './SevenDays'

export default class Cover_SevenDays extends Component {
    constructor(props) {
        super(props);
        this.state = {
            officeAndSchoolSuppliesID: "amzn-assoc-ad-aed23905-249f-4be3-9b8a-8be09c073676",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=aed23905-249f-4be3-9b8a-8be09c073676";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <SevenDaysApp/>
                {/* <div id={this.state.officeAndSchoolSuppliesID}/>  */}
            </div>
        )
    }
}
