import React from "react";
import "./ConsonantStyles.css";
function GridRow({
  akkha_letter,
  kantipur_transliteration,
  eng_transliteration,
}) {
  return (
    <tr>
      <td className="consonant_akkha">{akkha_letter}</td>
      <td className="consonant_kantipur">{kantipur_transliteration}</td>
      <td className="consonant_eng_literation">{eng_transliteration}</td>
    </tr>
  );
}

export default GridRow;
