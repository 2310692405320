import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Link as ScrollLink } from "react-scroll";
import { makeStyles } from "@material-ui/styles";
import {Link} from 'react-router-dom';
import CoverNumberPage from "../../pages/Numbers/CoverNumber";
import CoverCalcApp from "../../pages/Numbers/CalcComponents/CoverCalcApp";
import AffiliateDisclaimer from "../../pages/AffiliateDisclaimer";
// import AudibleAdsApp from '../../AdsContainer/AudibleAdsApp'
// import BeautyAdsApp from '../../AdsContainer/BeautyAdsApp'
// import GourmetAdsApp from '../../AdsContainer/GourmetAdsApp'
// import HealthAndPersonalCareAdsApp from '../../AdsContainer/HealthAndPersonalCareAdsApp'
// import HomeAndGardenAdsApp from '../../AdsContainer/HomeAndGardenAdsApp'
// import KindleAdsApp from '../../AdsContainer/KindleAdsApp'
// import KitchenAndHouseWareAdsApp from '../../AdsContainer/KitchenAndHouseWareAdsApp'
// import OutletAdsApp from '../../AdsContainer/OutletAdsApp'
// import PrimeSubsAds from '../../AdsContainer/PrimeSubsAds'
// import PrimeVideoAdsApp from '../../AdsContainer/PrimeVideoAdsApp'
// import SoftwareAdsApp from '../../AdsContainer/SoftwareAdsApp'
// import MusicalAdsApp from '../../AdsContainer/GourmetAdsApp'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0.5),
  },
  linkContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 5,
  },
  nav_buttons: {
    padding: 5,
    borderRadius: 5,
    fontSize: 15,
    color: "gray",
    fontWeight: "bold",
    cursor: "pointer",
  },
}));

const Numbers = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div id="topofpage" {...rest} className={clsx(classes.root, className)}>
      {/* <AffiliateDisclaimer /> */}
      <div className={classes.linkContainer}>
        <Link to="/gyarakhari" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Prev: Gyarakhari</button>
        </Link>
        <Link to="/halfForms" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Next: Half Forms</button>
        </Link>
      </div>
      {/* <MusicalAdsApp/> */}
      <CoverCalcApp />
      {/* <PrimeVideoAdsApp/> */}
      <CoverNumberPage />
      {/* <SoftwareAdsApp/> */}
      <div className={classes.linkContainer}>
        <Link to="/gyarakhari" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Prev: Gyarakhari</button>
        </Link>
        <Link to="/halfForms" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Next: Half Forms</button>
        </Link>
      </div>
      <ScrollLink to="topofpage" smooth={true} className="to-top-btn">
        <button className="to-top-button">Back to top ↑</button>
      </ScrollLink>
    </div>
  );
};

Numbers.propTypes = {
  className: PropTypes.string,
};

export default Numbers;
