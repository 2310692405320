import React from "react";
import "./TimeAdjective.css";

function GridRowTimeAdjApp({
  id,
  akkha_Words,
  Nep_Words,
  Nep_Meaning,
  Eng_Meaning
}) {
  return (
    <tr className="TimeAdj_Words_tableRow">
        <td className="TimeAdj_akkha_Words">{akkha_Words}</td>
        <td className="TimeAdj_Nep_Words">{Nep_Words}</td>
      <td className="TimeAdj_Nep_Meaning">{Nep_Meaning}</td>
      <td className="TimeAdj_Eng_Meaning">{Eng_Meaning}</td>
    </tr>
  );
}

export default GridRowTimeAdjApp;
