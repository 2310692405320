import { Divider } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/Images/magarsmajLogo.png'
import dhanMayaLungeli from '../assets/Images/dhanMayaLungeli.jpg'
import FinancialDisclaimer from './FinancialDisclaimer'
// import ShareBtn from "react-share-button";

// const url = "magar_society_mainpage/general_meeting_notice"
// const text="Click the link below to see Invitaion/Announcement for upcoming General Meeting."

export default function GeneralMeetingNotice() {
  return (
    <div>
      <Link to="/magar_society_mainpage" onClick={() => window.scrollTo(0, 0)}>
        <button className="back_button">Back to Magar Society Page</button>
      </Link>
      <div className="generalMeetingNoticeHeader_container">
        <img src={logo} alt="" className="generalMeetingNotice_logoImage" />
        <div>
          <h2 className="generalMeetingNoticeHeaderText">भूटनिज मगर गुमस्ठ</h2>
          <h3 className="generalMeetingNoticeSubHeaderText">
            हेरिसबर्ग, पेन्सिलभेनिया
          </h3>
        </div>
      </div>

      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />
      <div className="obituaryMain_container">
        <div className="obituaryHeader">
          <img
            className="obituaryImage"
            src={dhanMayaLungeli}
            alt="dhanMayaLungeli"
          />
          <h3 style={{ fontSize: '25px' }}>*** हार्दिक संवेदना ***</h3>
        </div>
        <p className="generalMeetingNotice_para">पट्टकी मिझोर,</p>
        <p className="generalMeetingNotice_para">
          {' '}
          6450 Finch Lane हेरिसबर्ग, पेन्सिलभेनिया निवासी धन माया लुङ्गेलि (Dhan
          Maya Lungeli), हिजो 01/15/2022 रातको 9:45 बजे ९३ (93) वर्षको उमेरमा
          निधन भएको सबैलाई जानकारी गराउन चाहन्छौ। उहाँको दिवंगत आत्माको चीर
          शान्तिको कामनाका साथै भावपूर्ण श्रद्धाञ्जली व्यक्त गर्दछौं । यस दु:खद
          घडीमा शोक सन्तप्त लुङ्गेली परिवार मित्र र आफन्तजनमा धैर्य धारण गर्ने
          शक्ति प्राप्त होस् भनि हार्दिक संवेदना व्यक्त गर्दछौं । हामी उहाँको
          आत्माले स्वर्गमा शान्ति पाओस् भनी प्रार्थना गर्दैछौं। यो सबैलाई हुने
          प्राकृतिक घटना हो। त्यसैले हामी सबैलाई एक अर्काको सहयोगको खाँचो पर्दछ।
        </p>
        {/* <h3 style={{ textAlign: 'center', fontSize: '25px' }}>
          मृतकको संक्षिप्त परिचय
        </h3> */}
        <p className="generalMeetingNotice_para">
          सदाझैं यस्ता दुखद घडीमा लुङ्गेली परिवारको निम्ति आर्थिक सहयोग प्रत्येक
          सदस्य परिवार बाट $50 संकलन गर्दछौं । तसर्थ यो सूचना प्राप्त हुने
          बित्तिकै कोषाध्यक्षको ठेगानामा (5661 Fordham Ave. Harrisburg,
          PA-17111) आफ्नो सहयोग बुझाउनु हुनेछ भन्ने आशा गर्दछु।
        </p>
        <p className="generalMeetingNotice_para">
          मृतकका छोरा भक्तबहादुरका अनुसार जारी कोभिड–१९ प्रतिबन्धका कारण funeral
          service मा लामा, परिवारका सदस्य र सीमित संख्यामा मात्रै उपस्थित हुन
          अनुरोध गरिएको छ ।
        </p>
        <p className="generalMeetingNotice_para">
          Funeral Address:{' '}
          <strong style={{ color: 'orange' }}>
            <i>2100 Linglestown Rd, Harrisburg, PA 17110</i>
          </strong>
        </p>
        <p className="generalMeetingNotice_para">
          Funeral date & Time:{' '}
          <strong style={{ color: 'orange' }}>
            <i>Tuesday, January 18, 2022 @ 1.30 pm</i>
          </strong>
        </p>
        {/* <p className="generalMeetingNotice_para">
          यसै सूचना मार्फत, आउदो वर्ष २०२२ को लागि $ 50 मगर समाज सदस्यता शुल्क
          (member fee) संकलन(collection) गर्ने अवधि समय January महिना को अन्तिम
          सम्ममा बुझाईसक्नु पर्ने जानकारी गराउन चाहनछौं।{' '}
        </p> */}
        <p className="generalMeetingNotice_para">
          यसैगरी उहाँको अन्तिम सुधाई आफ्नै निवासमा 1/24/2022 सोमबारका दिन हुने
          भएको छ । यस सूचना बारे केहि जिज्ञासा भए 717-480-6730 मा सम्पर्क गर्न
          अनुरोध गर्दछौं।
        </p>
        <p className="generalMeetingNotice_salutation">धन्यवाद।</p>
        <p className="generalMeetingNotice_salutation">बल आले मगर</p>
        <p className="generalMeetingNotice_salutation">महासचिव</p>
        <p className="generalMeetingNotice_salutation">भूटनिज मगर गुमस्ठ</p>
        <p className="generalMeetingNotice_salutation">
          हेरिसबर्ग, पेन्सिलभेनिया
        </p>
        {/* <ShareBtn url={url} text={text} className="ib" displayText="Share this Announcement" /> */}
      </div>
      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />
      <FinancialDisclaimer />
    </div>
  )
}
