import React, { Component } from "react";
import HomePage from "./Home";
import WelcomePageCarousel from "../../ImageCarousel/js/HomePageCarousel";
import AffiliateDisclaimer from "../AffiliateDisclaimer";
import { Link } from "react-router-dom";

// Carousel start
const SLIDE_COUNT = 7;
const slides = Array.from(Array(SLIDE_COUNT).keys());
// Carousel end

export default class CoverHomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneAccessoriesID: "amzn-assoc-ad-0429f5d3-19b1-4b6b-b4a4-6639d9fbc0b6",
    };
  }
  componentDidMount() {
    const script = document.createElement("script");

    script.src =
      "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=0429f5d3-19b1-4b6b-b4a4-6639d9fbc0b6";
    script.async = true;

    document.body.appendChild(script);
  }
  render() {
    return (
      <div>
        <WelcomePageCarousel slides={slides} />
        {/* <AffiliateDisclaimer /> */}
        <div
          style={{
            display: "flex",
            justifyContent: 'flex-end',
            marginTop: 5,
            
          }}
        >
           <Link to="/consonant" onClick={() => window.scrollTo(0, 0)}>
            <button
              style={{
                padding: 5,
                borderRadius: 5,
                fontSize: 15,
                color: "gray",
                fontWeight: "bold",
                cursor: "pointer"
              }}
            >
              Next: Consonant
            </button>
          </Link>
        </div>
        <HomePage />
        {/* <div id={this.state.phoneAccessoriesID}></div> */}
      </div>
    );
  }
}
