import React from "react";
import "./Numbers.css";

function GridRowVowelsAndSymbols({
  id,
  akkha_Number,
  Eng_Number,
  Akkha_NumberPronounce,
  kantipur_NumPronounce,
  Eng_NumPronounce
}) {
  
  return (
    <tr className="Number_tableRow">
      <td className="akkha_num">{akkha_Number}</td>
      <td className="Eng_Number">{Eng_Number}</td>
      <td className="Akkha_NumberPronounce">{Akkha_NumberPronounce}</td>
      <td className="Nep_transliteration">{kantipur_NumPronounce}</td>
      <td className="Eng_transliteration">{Eng_NumPronounce}</td>
    </tr>
  );
}

export default GridRowVowelsAndSymbols;
