import React from 'react'
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    disclaimer_container: {
        backgroundColor: "dodgerblue",
        borderRadius: "0 0 10px 10px",
        padding: 8,
        color: "white",
textAlign: 'right'
      },
      disclaimer_headingText: {
        color: "gold",
        fontFamily: "Tahoma",
        fontSize: 12,
      },
      disclaimer_para: {
        color: "aliceblue",
        fontFamily: "Tahoma",
        fontSize: 13,
        // textAlign: "justify",
      },
}))

export default function AffiliateDisclaimer() {
    const classes = useStyles();
    return (
        <div className={classes.disclaimer_container}>
            <h3 className={classes.disclaimer_headingText}>
            AFFILIATES DISCLOSURE:
          </h3>
          <p className={classes.disclaimer_para}>
            This Site contains affilliate links, of which I receive a small
            commission from sale of certain items.
          </p>
        </div>
    )
}
