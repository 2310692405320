import React from "react";
import "../Halfforms.css";
import Half_Form_WaData from "./Half_forms_WaData.json";
import Half_Form_WaWordsData from "./Half_forms_WaWordsData.json";
import GridRowHalfFormsWa from "./GridRowHalf_forms_Wa";
import GridRowHalfFormsWaWords from "./GridRowHalf_forms_WaWords";

export default function Half_Forms_WaApp() {
  const Half_forms_WaContent = Half_Form_WaData.map(
    ({ id, akkha, nepali, akkha1, nepali1, akkha2, nepali2 }) => (
      <GridRowHalfFormsWa
        id={id}
        akkha={akkha}
        nepali={nepali}
        akkha1={akkha1}
        nepali1={nepali1}
        akkha2={akkha2}
        nepali2={nepali2}
      />
    )
  );

  const Half_forms_WaWordsContent = Half_Form_WaWordsData.map(
    ({ id, akkha_Words, Nep_Words, Nep_Meaning, Eng_Meaning }) => (
      <GridRowHalfFormsWaWords
        id={id}
        akkha_Words={akkha_Words}
        Nep_Words={Nep_Words}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );

  return (
    <div className="HalfFormsWa_bcg">
      <h2 className="halfForms_Heading_text">अर्ध अक्षर "व्"</h2>
      <div className="halfForms_top_part">
        <table align="center">
          <thead className="halfForms_tableHeader">
            <tr>
              <th className="halfForms_heading_table">Akkha</th>
              <th className="halfForms_heading_table">Nepali Trans</th>
              <th className="halfForms_heading_table">Akkha</th>
              <th className="halfForms_heading_table">Nepali Trans</th>
              <th className="halfForms_heading_table">Akkha</th>
              <th className="halfForms_heading_table">Nepali Trans</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_first_eachCells">
            {Half_forms_WaContent}
          </tbody>
        </table>
      </div>
      <h2 className="halfForms_Heading_text">अर्ध अक्षर "व्" द्वारा बनिने शब्दहरु:</h2>
      <div className="halfForms_bottom_part">
        <table align="center">
          <thead className="halfForms_tableHeader">
            <tr>
              <th className="halfForms_heading_table1">Akkha Word</th>
              <th className="halfForms_heading_table1">Nepali Trans</th>
              <th className="halfForms_heading_table1">Nepali Meaning</th>
              <th className="halfForms_heading_table1">English Meaning</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_second_eachCells">
            {Half_forms_WaWordsContent}
          </tbody>
        </table>
      </div>
    </div>
  );
}
