import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Link as ScrollLink } from "react-scroll";

import CoverConsonant from '../../pages/Consonants/CoverConsonant';
import CoverPhoneme from '../../pages/Consonants/CoverPhoneme';
import ConsonantCarousel from '../../ImageCarousel/js/ConsonantCarousel'
import AffiliateDisclaimer from '../../pages/AffiliateDisclaimer';
import {Link} from 'react-router-dom';


// Carousel start
const SLIDE_COUNT = 26;
const slides = Array.from(Array(SLIDE_COUNT).keys());
// Carousel end

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(0),
  },

  linkContainer: {
    display: "flex",
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 5
  },
  nav_buttons : {
    padding: 5,
    borderRadius: 5,
    fontSize: 15,
    color: 'gray',
    fontWeight: "bold",
    cursor: "pointer",
  }
}));

const ConsonantPage = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      id="topofpage"
    >
      <ConsonantCarousel slides={slides}/>
      {/* <AffiliateDisclaimer/> */}
      <div className={classes.linkContainer}>
      <Link to="/home" onClick={() => window.scrollTo(0, 0)}><button className={classes.nav_buttons}>Prev: Dashboard</button></Link>
      <Link to="/Vowels" onClick={() => window.scrollTo(0, 0)}><button className={classes.nav_buttons}>Next: Vowels</button></Link>
      </div>
      {/* <MusicalAdsApp/> */}
     <CoverConsonant/>
     {/* <BeautyAdsApp/> */}
     <CoverPhoneme/>
     {/* <KindleAdsApp/> */}
     <ScrollLink to="topofpage" smooth={true} className="to-top-btn">
        <button className="to-top-button">
          ↑ Back to top
        </button>
      </ScrollLink>
    </div>
  );
};

ConsonantPage.propTypes = {
  className: PropTypes.string
};

export default ConsonantPage;
