// import React, { useEffect, useRef } from "react";

// const handleClick = () => {
//   const acc = rfVal.children;
//   for (let i = 0; i < acc.length; i++) {
//     let a = acc[i];
//     a.children[0].onclick = () => a.classList.toggle("active");
//   }
// };

// useEffect(() => {
//   handleClick();
// }, []);

// function Accordion({ children }) {
//   let rfVal = useRef(null);
//   return <div ref={(a) => (rfVal = a)}>{children}</div>;
// }

// export default Accordion;

import React, { useEffect, useRef } from 'react';

function Accordion({ children }) {
    let rfVal = useRef(null);

  useEffect(() => {
    handleClick();
  }, []);

  const handleClick = () => {
    const acc = rfVal.children;
    for (let i = 0; i < acc.length; i++) {
      let a = acc[i];
      a.children[0].onclick = () => a.classList.toggle("active");
    }
  };
    return <div ref={a => (rfVal = a)}>{children}</div>;
}

export default Accordion;