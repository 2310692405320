import React from "react";
import './Eekaar.css'
import GridRowVowelEekaar from './GridRowVowelEekaar';
import VowelEekaarData from './VowelEekaarData.json';

export default function VowelAekaar() {
  const VowelAakarContent = VowelEekaarData.map(
    ({ id, akkha_VowelEekar, kantipur_VowelEekar, Nep_Meaning, Eng_Meaning }) => (
      <GridRowVowelEekaar
        id={id}
        akkha_VowelEekar={akkha_VowelEekar}
        kantipur_VowelEekar={kantipur_VowelEekar}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );
  
  return (
    <div className="Vowel_eekar_Container">
      <h3 className="Vowel_Eekar_Heading">
        {/* <p className="matra">q</p> */}
        "ि,  ी" इ/ईकारलाई प्रयोग गर्दा बनिने शब्दहरु:</h3>
      <div className="eekar_table_div">
        <table align="center">
        <thead className="eekar_tableHeader">
            <tr>
              <th className="eekar_heading_table">Akkha</th>
              <th className="eekar_heading_table">Nepali Trans</th>
              <th className="eekar_heading_table">Nepali Meaning</th>
              <th className="eekar_heading_table">English Meaning</th>
            </tr>
          </thead>
          <tbody className="eachCells">{VowelAakarContent}</tbody>
        </table>
      </div>
    </div>
  );
}
