import React from "react";
import "./Aikaar.css";

function GridRowVowelAikaar({
  id,
  akkha_VowelAikar,
  kantipur_VowelAikar,
  Nep_Meaning,
  Eng_Meaning
}) {

  return (
    <tr className="vowelAikar_tableRow">
      <td className="akkha_vowelAikar">{akkha_VowelAikar}</td>
      <td className="kantipur_vowelAikar">{kantipur_VowelAikar}</td>
      <td className="nep_meaning_vowelAikar">{Nep_Meaning}</td>
      <td className="eng_meaning_vowelAikar">{Eng_Meaning}</td>
    </tr>
  );
}

export default GridRowVowelAikaar;
