const dataList = [
  {
    "id": 1,
    "magarName": "pSrQm aAlQ mgr",
    "name": "Prem Ale Magar",
    "title": "some title",
    "year": "15+ yrs",
    "color": "#98B2D1",
    "phoneNumber": "7138557524",
    "category": "All",
    "city": "Columbus",
    "state": "Ohio",
    'zipcode': "43004",
    "priestCode": "Prem Ale Magar",
    "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
    "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Prem Ale Magar&body=I want to contact Prem Ale Magar for..",
    "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/premAle.jpeg?raw=true"
  },
  {
    "id": 2,
    "magarName": "mn pYrbCAnQ mgr",
    "name": "Mon Purbachhane Magar",
    "title": "some title",
    "year": "15+ yrs",
    "color": "#45B5AA",
    "phoneNumber": "3195366521",
    "category": "All",
    "city": "Cedar Rapids",
    "state": "Iowa",
    'zipcode': "52233",
    "priestCode": "Mon Purbachhaney Magar",
    "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
    "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Mon Purbachhaney Magar&body=I want to contact Mon Purbachhaney Magar for..",
    "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/monPurbachhanProf.JPG?raw=true"
  },
  {
    "id": 3,
    "magarName": "KdSg mAn aAlQ mgr",
    "name": "Khadga Man Aley Magar",
    "title": "some title",
    "year": "15+ yrs",
    "color": "#45B5AA",
    "phoneNumber": "9788769438",
    "category": "All",
    "city": "Akron",
    "state": "Ohio",
    'zipcode': "44223",
    "priestCode": "Khadga Man Aley Magar",
    "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
    "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Khadga Man Aley Magar&body=I want to contact Khadga Man Aley Magar for..",
    "img": "https://github.com/rob2rhyme/Magar_Lamaphotos/blob/main/khadgaAlay.jpeg?raw=true"
  },
  // {
  //   "id": 4,
  //   "magarName": "aAetA sEz sOrAlE mgr",
  //   "name": "Aita Singh Sorali Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "5857752469",
  //   "category": "All",
  //   "city": "Rochester",
  //   "state": "New York",
  //   'zipcode': "14602",
  //   "priestCode": "Aita Singh Sorali Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Aita Singh Sorali Magar&body=I want to contact Aita Singh Sorali Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/AitaSinghSorali.jpeg?raw=true"
  // },
  // {
  //   "id": 5,
  //   "magarName": "dmSbr sEjAlE mgr",
  //   "name": "Dambar Sijali Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "4043800309",
  //   "category": "All",
  //   "city": "Stone Mountain",
  //   "state": "Georgia",
  //   'zipcode': "30083",
  //   "priestCode": "Dambar Sijali Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Dambar Sijali Magar&body=I want to contact Dambar Sijali Magarfor..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/DamberSinjali.jpeg?raw=true"
  // },
  // {
  //   "id": 6,
  //   "magarName": "KdSkA sEz fQwAlE mgr",
  //   "name": "Khadka Singh Phewali Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "7017817019",
  //   "category": "All",
  //   "city": "Harrisburg",
  //   "state": "Pennsylvania",
  //   'zipcode': "17111",
  //   "priestCode": "Khadka Singh Phewali Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Khadka Singh Phewali Magar&body=I want to contact Khadka Singh Phewali Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/khadkaSinghPhewaliMagar.JPG?raw=true"
  // },

  // {
  //   "id": 7,
  //   "magarName": "pSrtAp sEz mgr",
  //   "name": "Pratap Singh Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "2146803347",
  //   "category": "All",
  //   "city": "Manheim",
  //   "state": "Pennsylvania",
  //   'zipcode': "17545",
  //   "priestCode": "Pratap Singh Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Pratap Singh Magar&body=I want to contact Pratap Singh Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/monkprofile1.png?raw=true"
  // },
  // {
  //   "id": 8,
  //   "magarName": "dQvE crn mApCAn mgr",
  //   "name": "Devi Charan Mapchhan Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "4049162888",
  //   "category": "All",
  //   "city": "Atlanta",
  //   "state": "Georgia",
  //   'zipcode': "30047",
  //   "priestCode": "Devi Charan Mapchhan Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Devi Charan Mapchhan Magar&body=I want to contact Devi Charan Mapchhan Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/monkprofile1.png?raw=true"
  // },   
  // {
  //   "id": 9,
  //   "magarName": "gzSgA aAlQ mgr",
  //   "name": "Ganga Ale Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "3313154865",
  //   "category": "All",
  //   "city": "Munroe Falls",
  //   "state": "Ohio",
  //   'zipcode': "44262",
  //   "priestCode": "Ganga Ale Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Ganga Ale Magar&body=I want to contact Ganga Ale Magar for..",
  //   "img": "https://github.com/rob2rhyme/Magar_Lamaphotos/blob/main/GangaAlay.jpeg?raw=true"
  // },  
  // {
  //   "id": 10,
  //   "magarName": "tYlA rAm aAlQ mgr",
  //   "name": "Tula Ram Ale Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "4044923130",
  //   "category": "All",
  //   "city": "Atlanta",
  //   "state": "Georgia",
  //   'zipcode': "30047",
  //   "priestCode": "Tula Ram Ale Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Tula Ram Ale Magar&body=I want to contact Tula Ram Ale Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/tulaRam.jpg?raw=true"
  // },
  // {
  //   "id": 11,
  //   "magarName": "bEml gSyAzmE mgr",
  //   "name": "Bemal Gyangmi Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "3142037085",
  //   "category": "All",
  //   "city": "St. Louis",
  //   "state": "Missouri",
  //   'zipcode': "63116",
  //   "priestCode": "Bemal Gyangmi Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Bemal Gyangmi Magar&body=I want to contact Bemal Gyangmi Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/bemal.JPG?raw=true"
  // },      
  // {
  //   "id": 12,
  //   "magarName": "rAm bAhAdYr pYlAmE mgr",
  //   "name": "Ram Bahadur Pulami Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "8323050723",
  //   "category": "All",
  //   "city": "Houston",
  //   "state": "Texas",
  //   'zipcode': "77038",
  //   "priestCode": "Ram Bahadur Pulami Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Ram Bahadur Pulami Magar&body=I want to contact Ram Bahadur Pulami Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/ramPulami.jpg?raw=true"
  // },
  // {
  //   "id": 13,
  //   "magarName": "mn drlAmE mgr",
  //   "name": "Mon Darlami Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "3302431904",
  //   "category": "All",
  //   "city": "Akron",
  //   "state": "Ohio",
  //   'zipcode': "44310",
  //   "priestCode": "Mon Darlami Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Mon Darlami Magar&body=I want to contact Mon Darlami Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/monDarlami.jpg?raw=true"
  // },  
       
  // {
  //   "id": 14,
  //   "magarName": "tQk bAhAdYr KpAzSgE mgr",
  //   "name": "Tek Bdr. Khapangi Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "4132597048",
  //   "category": "All",
  //   "city": "Westfield",
  //   "state": "Massachusetts",
  //   'zipcode': "01085",
  //   "priestCode": "Tek Bdr. Khapangi Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Tek Bdr. Khapangi Magar&body=I want to contact Tek Bdr. Khapangi Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/monkprofile1.png?raw=true"
  // },  
  // {
  //   "id": 15,
  //   "magarName": "snSt rAnA mgr",
  //   "name": "Santa Rana Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "7815134574",
  //   "category": "All",
  //   "city": "Harrisburg",
  //   "state": "Pennsylvania",
  //   'zipcode': "17111",
  //   "priestCode": "Santa Rana Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Santa Rana Magar&body=I want to contact Santa Rana Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/santaRana.JPG?raw=true"
  // },  
  // {
  //   "id": 16,
  //   "magarName": "sn mAn sArY mgr",
  //   "name": "San Man Saru Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "4696858751",
  //   "category": "All",
  //   "city": "Dallas",
  //   "state": "Texas",
  //   'zipcode': "75231",
  //   "priestCode": "San Man Saru Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: San Man Saru Magar&body=I want to contact San Man Saru Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/monkprofile1.png?raw=true"
  // },    
  // {
  //   "id": 17,
  //   "magarName": "pSrtAp smAl mgr",
  //   "name": "Pratap Samal Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "7017305057",
  //   "category": "All",
  //   "city": "South Park",
  //   "state": "Pennsylvania",
  //   'zipcode': "15129",
  //   "priestCode": "Pratap Samal Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Pratap Samal Magar&body=I want to contact Pratap Samal Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/pratapSamal.jpg?raw=true"
  // },    
  // {
  //   "id": 18,
  //   "magarName": "sQr sEjAlE mgr",
  //   "name": "Sher Sijali Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "3196546175",
  //   "category": "All",
  //   "city": "Hiawatha",
  //   "state": "Iowa",
  //   'zipcode': "",
  //   "priestCode": "Sher Sijali Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Sher Sijali Magar&body=I want to contact Sher Sijali Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/sherSijali.jpg?raw=true"
  // },
  // {
  //   // never reached
  //   "id": 19,
  //   "magarName": "bl bAhAdYr GlQ mgr",
  //   "name": "Bal Bdr. Ghale Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "5856294423",
  //   "category": "All",
  //   "city": "***Waiting for details***",
  //   "state": "",
  //   'zipcode': "",
  //   "priestCode": "Bal Bdr. Ghale Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Bal Bdr. Ghale Magar&body=I want to contact Bal Bdr. Ghale Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/monkprofile1.png?raw=true"
  // },     
  // {
  //   // never reached
  //   "id": 20,
  //   "magarName": "js bAhAdYr lShAyO mgr",
  //   "name": "Jas Bahadur Lhayo Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "8023637088",
  //   "category": "All",
  //   "city": "",
  //   "state": "Vermont",
  //   'zipcode': "",
  //   "priestCode": "Jas Bahadur Lhayo Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Jas Bahadur Lhayo Magar&body=I want to contact Jas Bahadur Lhayo Magar for..",
  //   "img": "https://github.com/rob2rhyme/Magar_Lamaphotos/blob/main/JasLhayo.jpeg?raw=true"
  // },  
  // {
  //   // not reachable
  //   "id": 21,
  //   "magarName": "dEl bAhAdYr drlAmE mgr",
  //   "name": "Dil Bahadur Darlami Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "5136932563",
  //   "category": "All",
  //   "city": "***Waiting for details***",
  //   "state": "",
  //   'zipcode': "",
  //   "priestCode": "Dil Bahadur Darlami Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Dil Bahadur Darlami Magar&body=I want to contact Dil Bahadur Darlami Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/monkprofile1.png?raw=true"
  // },   
  // {
  //   "id": 22,
  //   "magarName": "krSn bAhAdYr grbcn mgr",
  //   "name": "Karna Bahadur Gurbachhan Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "4043179612",
  //   "category": "All",
  //   "city": "Snellville",
  //   "state": "Georgia",
  //   'zipcode': "30078",
  //   "priestCode": "Karna Bahadur Gurbachhan Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Karna Bahadur Gurbachhan Magar&body=I want to contact Karna Bahadur Gurbachhan Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/monkprofile1.png?raw=true"
  // },
  // {
  //   "id": 23,
  //   "magarName": "dmSbr rAnA mgr",
  //   "name": "Dambar Rana Magar",
  //   "title": "some title",
  //   "year": "15+ yrs",
  //   "color": "#45B5AA",
  //   "phoneNumber": "7012128222",
  //   "category": "All",
  //   "city": "Fargo",
  //   "state": "North Dakota",
  //   'zipcode': "58103",
  //   "priestCode": "Dambar Rana Magar",
  //   "messageBody": "Please connect me with this wapa for (your reason of contact this priest...",
  //   "mailUrl": "mailto:magarbhasalipi@gmail.com?subject=Re: Dambar Rana Magar&body=I want to contact Dambar Rana Magar for..",
  //   "img": "https://github.com/rob2rhyme/Lamaphotos/blob/main/monkprofile1.png?raw=true"
  // }, 
];

export default dataList;