import { Divider } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/Images/magarsmajLogo.png'
import FinancialDisclaimer from './FinancialDisclaimer'
import DownLoadPdf from '../components/DOCUMENT_DOWNLOADER/invitationDownload'
// import ShareBtn from "react-share-button";

// const url = "magar_society_mainpage/general_meeting_notice"
// const text="Click the link below to see Invitaion/Announcement for upcoming General Meeting."

export default function MaghaySakrantiNotice() {
  return (
    <div className="generalMeetingMain_container">
      <div className="invitation_buttonContainer">
        <Link
          to="/magar_society_mainpage"
          onClick={() => window.scrollTo(0, 0)}
        >
          <button className="invitationBackBtn">Back</button>
        </Link>
        <DownLoadPdf />
      </div>
      <div className="generalMeetingNoticeHeader_container">
        <img src={logo} alt="" className="generalMeetingNotice_logoImage" />
        <div>
          <h2 className="generalMeetingNoticeHeaderText">भूटनिज मगर गुमस्ठ</h2>
          <h3 className="generalMeetingNoticeSubHeaderText">
            हेरिसबर्ग, पेन्सिलभेनिया
          </h3>
        </div>
      </div>

      <h2 className="generalMeetingNoticeHeading">
        *** कार्यक्रम रद्द भएको सूचना ***{' '}
      </h2>
      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />
      <p className="generalMeetingNoticeDate">January 8, 2022</p>
      <p className="generalMeetingNotice_para">सम्पूर्णलाई मिझोर,</p>
      <p className="generalMeetingNotice_para">
        सर्वप्रथम यहाँहरुको सम्पूर्ण परिवारमा मगर माघे-संक्रान्ति र नव वर्ष २०२२
        सालको पवन अवसरमा हार्दिक मंगलमय शुभकामना व्यक्त गर्न चाहन्छु।
      </p>
      <p className="generalMeetingNotice_para">
        सबैको स्वास्थ्यलाई पहिलो स्थानमा राख्दै, सबै शुभेच्छुकहरूको आवाज
        सूचीबद्ध गर्दै र पछिल्लो समय Covid Case हरू बढेका कारणले, म तपाईंलाई
        जानकारी गराउन चाहन्छु कि <strong>जनवरी 16, 2022</strong> मा तय गरिएको
        मगरहरुको महान चाड माघे संक्रान्तिको दिन हुने भनिएको संस्कृतिक कार्यक्रम{' '}
        <strong>रद्द (Cancel)</strong> गरिएको छ।तपाईको दयालु सहयोगको लागि सबैलाई
        धन्यवाद। यदि भविष्यमा स्थिति सामान्य भएको खण्डमा हामी आगामी कार्यक्रमहरू
        मनाउन तत्पर छौं।
      </p>
      {/* <p className="generalMeetingNotice_para">
      कार्यक्रम ठेगाना: <strong>2701 North Third Street, Harrisburg, PA-17110</strong>
      </p> */}
      {/* <p className="generalMeetingNotice_para">कार्यक्रम मिति: <strong>10 am.</strong></p> */}
      {/* <p className="generalMeetingNotice_para">कार्यक्रम समय: <strong>January 16, 2022 @ 10 am.</strong></p> */}
      <p className="generalMeetingNotice_para">धन्यवाद।</p>
      <p className="generalMeetingNotice_para">नर बलम्पाकी मगर</p>
      <p className="generalMeetingNotice_para">अध्यक्ष</p>
      <p className="generalMeetingNotice_para">भूटनिज मगर गुमस्ठ</p>
      <p className="generalMeetingNotice_para">हेरिसबर्ग, पेन्सिलभेनिया</p>
      {/* <ShareBtn url={url} text={text} className="ib" displayText="Share this Announcement" /> */}

      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />

      <FinancialDisclaimer />
    </div>
  )
}
