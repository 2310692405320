import { Divider } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/Images/magarsmajLogo.png";
import Lamichhane_BabyGirl from "../assets/Images/lamichhane-baby-girl.jpg";
import FinancialDisclaimer from "./FinancialDisclaimer";
// import ShareBtn from "react-share-button";

// const url = "magar_society_mainpage/general_meeting_notice"
// const text="Click the link below to see Invitaion/Announcement for upcoming General Meeting."

export default function GeneralMeetingNotice() {
  return (
    <div>
      <Link to="/magar_society_mainpage" onClick={() => window.scrollTo(0, 0)}>
        <button className="back_button">Back to Magar Society Page</button>
      </Link>
      <div className="generalMeetingNoticeHeader_container">
        <img src={logo} alt="" className="generalMeetingNotice_logoImage" />
        <div>
          <h2 className="generalMeetingNoticeHeaderText">
            हेरिसबर्ग मगर गुमस्ठ
          </h2>
          <h3 className="generalMeetingNoticeSubHeaderText">
            हेरिसबर्ग, पेन्सिलभेनिया
          </h3>
        </div>
      </div>

      <Divider
        style={{
          backgroundColor: "white",
          maxWidth: "1124px",
          margin: "20px auto",
        }}
      />
      <div className="obituaryMain_container">
        <div className="obituaryHeader">
          <img className="obituaryImage" src={Lamichhane_BabyGirl} alt="kul_darlami" />
          <h3 style={{ fontSize: "25px" }}>*** हार्दिक संवेदना ***</h3>
        </div>
        <p className="generalMeetingNotice_para">अत्यन्त जरुरी सूचना!</p>
        <p className="generalMeetingNotice_para">
          {" "}
          हाम्रो मगर समाजको सदस्य 7 wild Rose Lane, Mechanicsburg, PA 17050 मा बसोबास गर्ने सुक लामीछाने मगरको नातिनी अर्थात पर्बल लामीछाने मगर र अस्मिता लामीछाने मगरको सुपुत्री जन्मने बित्तिकै मृत्यु भएको हुदा समाजले सम्पूर्ण ह्यार्रिसबर्ग मगर सदस्यहरुलाई $ २०.००/ $ 20. 00 दरले उठाउने निर्णय गरेकोले कोष-अध्यक्षज्युलाई दिन हुन र मृत पार्थिव शरीरको अन्तेस्ठी सोमबार जुन 13, 2022 ठिक 9:00 बजी गर्ने भएको हुदा यस ठेगानामा आईदिन हुन अनुरोध गर्दछौं ।
        </p>
        <p className="generalMeetingNotice_para">
          तसर्थ यो सूचना प्राप्त हुने बित्तिकै कोषाध्यक्षको ठेगानामा
          (5661 Fordham Ave. Harrisburg, PA-17111) आफ्नो सहयोग बुझाउनु हुनेछ
          भन्ने आशा गर्दछु।
        </p>

        <p className="generalMeetingNotice_para">
          Funeral Address:{" "}
          <strong style={{ color: "orange" }}>
            <i>2100 Linglestown Rd, Harrisburg, PA 17110</i>
          </strong>
        </p>
        <p className="generalMeetingNotice_para">
          Funeral date & Time:{" "}
          <strong style={{ color: "orange" }}>
            <i>Monday, June 13, 2022 @ 9.00 A.M.</i>
          </strong>
        </p>
        {/* <p className="generalMeetingNotice_para">
          यसैगरी उहाँको अन्तिम सुधाई 05/26/2022 बिहीबारका दिन हुने भएको छ । यस
          सूचना बारे केहि जिज्ञासा भए 717-480-6730 मा सम्पर्क गर्न अनुरोध
          गर्दछौं।
        </p> */}
        <p className="generalMeetingNotice_salutation">धन्यवाद।</p>
        {/* <p className="generalMeetingNotice_salutation">बल आले मगर</p>
        <p className="generalMeetingNotice_salutation">महासचिव</p> */}
        <p className="generalMeetingNotice_salutation">हेरिसबर्ग मगर गुमस्ठ</p>
        <p className="generalMeetingNotice_salutation">
          हेरिसबर्ग, पेन्सिलभेनिया
        </p>
        {/* <ShareBtn url={url} text={text} className="ib" displayText="Share this Announcement" /> */}
      </div>
      <Divider
        style={{
          backgroundColor: "white",
          maxWidth: "1124px",
          margin: "20px auto",
        }}
      />
      <FinancialDisclaimer />
    </div>
  );
}
