import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Toolbar, Hidden, IconButton } from "@material-ui/core";
import Typed from "react-typed";
import MenuIcon from "@material-ui/icons/Menu";

import logo from "../../../../assets/Images/logo.png";
import "./Topbar.css";

const webNameTextLines = [`akSKA rEkA lEpE sEpl`];

const Topbar = (props) => {
  const { className, onSidebarOpen, ...rest } = props;

  return (
    <AppBar {...rest} className="root">
      <Toolbar className="topbar_container">
        
        <RouterLink to="/">
          <img alt="Logo" src={logo} className="logo_img" />
        </RouterLink>
        <h1 className="head_text">
          <Typed
            strings={webNameTextLines}
            typeSpeed={150}
            loop={true}
            showCursor={false}
          />
        </h1>
        <div className="flexGrow" />

        <Hidden lgUp>
          <IconButton color="inherit" onClick={onSidebarOpen}>
            <MenuIcon fontSize= "large"/>
          </IconButton>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func,
};

export default Topbar;
