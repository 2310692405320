import React from "react";
import "../Halfforms.css";

function GridRowHalf_forms_GeneralWordsApp({
  id,
  akkha_Words,
  Nep_Num,
  Nep_Meaning,
  Nep_Transliteration
}) {
  return (
    <tr className="HalfForms_GeneralWords_tableRow">
      <td className="Nep_Num">{Nep_Num}</td>
        <td className="akkha_GeneralWords">{akkha_Words}</td>
        <td className="Nep_Transliteration">{Nep_Transliteration}</td>
      <td className="Nep_Meaning_General">{Nep_Meaning}</td>
    </tr>
  );
}

export default GridRowHalf_forms_GeneralWordsApp;
