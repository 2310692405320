import React, { Component } from 'react'
import CalcApp from './CalcApp'

export default class CoverCalcApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
          groceryAndGourmetFoodID: "amzn-assoc-ad-9faf5ca4-4a86-4b43-bf51-fa519e844dff",
        };
      }
    
      componentDidMount() {
        document.addEventListener("keydown", this.handleNumpad);
        const groceryAndGourmetFood_script = document.createElement("script");
    
       groceryAndGourmetFood_script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=9faf5ca4-4a86-4b43-bf51-fa519e844dff";
       groceryAndGourmetFood_script.async = true;
    
       document.body.appendChild(groceryAndGourmetFood_script);
      }
    render() {
        return (
            <div>
                <CalcApp/>
                {/* <div id={this.state.groceryAndGourmetFoodID}></div>  */}
            </div>
        )
    }
}