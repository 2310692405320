import React from "react";
import { Link } from "react-router-dom";
import { Divider } from "@mui/material";
import EventCards from "./EventCards";
import OtherLinks from "./OtherLinks";
// import DownloadContact from "../components/DOCUMENT_DOWNLOADER/contactDownload";
// import VerticalTimeLine from './VerticalTimeline'

export default function MsMainPage() {
  return (
    <>
      <Link to="/" onClick={() => window.scrollTo(0, 0)}>
        <button className="back_button">Back to Home Page</button>
      </Link>
      <div className="magarSocietyHarrisburgMainPage">
        <h3>Welcome to</h3>
        <h1>"ग्रेटर ह्यारिसबर्ग मगर संगठन"</h1>
        <h1>Greater Harrisburg Magar Organization (GHMO)</h1>
        <h2>Harrisburg Information Page</h2>
        {/* <DownloadContact /> */}
        <p
          style={{
            fontFamily: "Arial, Helvetica, sans-serif",
            margin: "10px auto",
            backgroundColor: "dodgerblue",
            padding: "10px",
            textAlign: "justify",
          }}
        >
          Note: This page is intended only for GHMO  to pass
          along inportant message about upcoming events and Announcements.
        </p>
        <EventCards />
        {/* <VerticalTimeLine/> */}
        <Divider
          style={{
            backgroundColor: "white",
            maxWidth: "1124px",
            margin: "20px auto",
          }}
        />
        <OtherLinks />
      </div>
    </>
  );
}
