import React from 'react'
import MessageApp from '../../Message-Container/MessageApp'
import Typed from 'react-typed'
import '../../App.css'
import Divider from '@material-ui/core/Divider'
// import AnnouncementIcon from '@mui/icons-material/Announcement';
// import AnnApp from '../../ANNOUNCEMENTS/CURRENT_ANNOUNCEMENT/Cur_AnnApp'
import LamaApp from '../../LAMA/CoverLamaAppFront'
import { Link } from 'react-router-dom'
// import HorizontalTimeLinePage from "../../HorizontalTimeLine/HorizontalTimeLine";
// import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const textLines_1 = [`ptStkE  mEJOr 🙏`]
const textLines_2 = [`akSKA rEkA lEpE sEplAz sSwAgt lQ.`]

export default function Home() {
  return (
    <div className="home_page">
      <div className="akkha_lipi">
        <Typed
          strings={textLines_1}
          typeSpeed={300}
          loop={false}
          showCursor={false}
        />
      </div>
      <div className="akkha_lipi1">
        <Typed
          strings={textLines_2}
          typeSpeed={150}
          loop={false}
          showCursor={false}
        />
      </div>
      <div className="affiliate_container"></div>
      {/* <Divider/> */}
      {/* <div className="announcement_container">
      <h1 className="dashboard_headings">Current Announcement<AnnouncementIcon fontSize="large"/></h1>
      <AnnApp />
      </div> */}
      {/* <Divider/> */}
      {/* <div className="MagarSocietyOfHarrisburgButtonLink"> */}

      {/* </div> */}
      {/* <HorizontalTimeLinePage/> */}
      <LamaApp />
      <Divider />
      {/* <p className="MagarSocietyOfHarrisburgButtonLink">
        Click{' '}
        <Link to="/magar_society_mainpage">
          <button
            style={{
              margin: '5px 10px',
              color: 'black',
              padding: '0 10px',
              border: '0 solid orange',
              borderRadius: '15px',
            }}
            onClick={() => window.scrollTo(0, 0)}
          >
            here
          </button>{' '}
        </Link>
        to visit{' '}
        <Link
          to="/magar_society_mainpage"
          onClick={() => window.scrollTo(0, 0)}
          style={{ margin: '0 10px', color: 'gold' }}
        >
          Magar Society of Harrisburg Announcement Page.
        </Link>
      </p> */}
      <h1 className="dashboard_headings">Inspiring Words</h1>
      <MessageApp />
      {/* <OutletAdsApp/> */}
    </div>
  )
}
