import React from "react";
import "./VowelsAndSymbols.css";
function GridRowVowels({
  id,
  akkha_Vowel,
  kantipur_Vowel,
  eng_transliteration,
}) {
  return (
    <tr className="vowel_tableRow">
      <td className="akkha_vowel">{akkha_Vowel}</td>
      <td className="kantipur_vowel">{kantipur_Vowel}</td>
      {/* <td className="eng_literation">{eng_transliteration}</td> */}
    </tr>
  );
}

export default GridRowVowels;
