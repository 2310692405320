import React from "react";
import "../Halfforms.css";

function GridRowHalf_forms_WaWordsApp({
  akkha_Words,
  Nep_Words,
  Nep_Meaning,
  Eng_Meaning
}) {
  return (
    <tr className="HalfForms_Words_tableRow">
      <td className="akkha_Words">{akkha_Words}</td>
      <td className="Nep_Words">{Nep_Words}</td>
      <td className="Nep_Meaning">{Nep_Meaning}</td>
      <td className="Eng_Meaning">{Eng_Meaning}</td>
    </tr>
  );
}

export default GridRowHalf_forms_WaWordsApp;
