import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Link as ScrollLink } from "react-scroll";
import {Link} from 'react-router-dom';
import CoverExercise1App from '../../pages/Reading_Exercises/Exercise1/Cover_Exercise1'
import CoverExercise2App from '../../pages/Reading_Exercises/Exercise2/Cover_Exercise2'
import CoverExercise3App from '../../pages/Reading_Exercises/Exercise3/Cover_Exercise3'
import CoverExercise4App from '../../pages/Reading_Exercises/Exercise4/Cover_Exercise4'
import CoverExercise5App from '../../pages/Reading_Exercises/Exercise5/Cover_Exercise5'
import '../../pages/Reading_Exercises/ExerciseAccordion.css'
import AffiliateDisclaimer from "../../pages/AffiliateDisclaimer";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(1),
  },
  linkContainer: {
    display: "flex",
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 5
  },
  nav_buttons : {
    padding: 5,
    borderRadius: 5,
    fontSize: 15,
    color: 'gray',
    fontWeight: "bold",
    cursor: "pointer",
  }
}));

const ReadingPracticeApp = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)} id="topofpage">
      {/* <AffiliateDisclaimer/> */}
      <div className={classes.linkContainer}>
        <Link to="/basicTerms" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Prev: Common Words</button>
        </Link>
        <Link to="/typingPractice" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Next: Practice Typing</button>
        </Link>
      </div>
      {/* <MusicalAdsApp/> */}
      <h1 className="Heading_text">Reading Exercise</h1>
      <h3 className="reading_prac">Reading Practice 1</h3>
      <CoverExercise1App />
      {/* <HealthAndPersonalCareAdsApp/> */}
      <h3 className="reading_prac">Reading Practice 2</h3>
      <CoverExercise2App />
      {/* <OutletAdsApp/> */}
      <h3 className="reading_prac">Reading Practice 3</h3>
      <CoverExercise3App />
      {/* <PrimeSubsAds/> */}
      <h3 className="reading_prac">Reading Practice 4</h3>
      <CoverExercise4App />
      {/* <PrimeVideoAdsApp/> */}
      <h3 className="reading_prac">Reading Practice 5</h3>
      <CoverExercise5App />
      {/* <SoftwareAdsApp/> */}
      <div className={classes.linkContainer}>
        <Link to="/basicTerms" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Prev: Common Words</button>
        </Link>
        <Link to="/typingPractice" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Next: Practice Typing</button>
        </Link>
      </div>
      <ScrollLink to="topofpage" smooth={true} className="to-top-btn">
        <button className="to-top-button">↑ Back to top</button>
      </ScrollLink>
    </div>
  );
};

ReadingPracticeApp.propTypes = {
  className: PropTypes.string,
};

export default ReadingPracticeApp;
