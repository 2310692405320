import React from "react";
import "./Months.css";

function GridRowMonthsApp({
  akkha_Words,
  Nep_Words,
  Nep_Meaning,
  Eng_Meaning
}) {
  return (
    <tr className="months_Words_tableRow">
        <td className="months_akkha_Words">{akkha_Words}</td>
        <td className="months_Nep_Words">{Nep_Words}</td>
      <td className="months_Nep_Meaning">{Nep_Meaning}</td>
      <td className="months_Eng_Meaning">{Eng_Meaning}</td>
    </tr>
  );
}

export default GridRowMonthsApp;
