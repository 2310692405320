import React, { Component } from 'react'
import VowelUkaar from './VowelUkaar';

export default class CoverVowelUkaar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          beautyAndGroomingID: "amzn-assoc-ad-6819b591-bbd2-46e7-a1ef-5ebae00ecc3b",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=6819b591-bbd2-46e7-a1ef-5ebae00ecc3b";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <VowelUkaar/>
                {/* <div id={this.state.beautyAndGroomingID}></div>  */}
            </div>
        )
    }
}
