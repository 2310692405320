import React, { useState } from "react";
import "./LamaStyles.css";
import dataList from "./datalist";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Link } from "react-router-dom";
// import AffiliateDisclaimer from "../pages/AffiliateDisclaimer";
// import GoogleAd from "../GoogleAd";
// import ContactMailIcon from "@material-ui/icons/ContactMail";
// import ContactMailIcon from '@material-ui/icons-material/ContactMail

const LamaApp = () => {
  const [searchText, setSearchText] = useState("");
  const [data, setData] = useState(dataList);
  // eslint-disable-next-line
  const [category, setCategory] = useState(null);

  // exclude column list from filter
  const excludeColumns = ["id", "color"];

  // handle change event of search input
  const handleChange = (value) => {
    setSearchText(value);
    filterData(value);
    setCategory(value);
  };
  const filterData = (value) => {
    const lowercasedValue = value.toLowerCase().trim();
    if (lowercasedValue === "") setData(dataList);
    else {
      const filteredData = dataList.filter((item) => {
        return Object.keys(item).some((key) =>
          excludeColumns.includes(key)
            ? false
            : item[key].toString().toLowerCase().includes(lowercasedValue)
        );
      });
      setData(filteredData);
    }
  };

  return (
    <div className="main_container_back">
      {/* <AffiliateDisclaimer/> */}
      <Link to="/home" style={{textDecoration: "none", color: '#fff', fontFamily: "sans-serif", fontSize: '20px', lineHeight: '15px'}} onClick={() => window.scrollTo(0, 0)}><ArrowBackIcon fontSize="Large"/></Link>
      {/* <GoogleAd slot="7693932895"/> */}
      <h2 className="lamaHeading">Search for Magar Lama near you</h2>
      <div className="search_container">
        {/* Search for wapa:{" "} */}
        <input
          // style={{ marginLeft: 5 }}
          className="input_container"
          type="text"
          placeholder="Type to search..."
          value={searchText}
          onChange={(e) => handleChange(e.target.value)}
        />
        <select
          onChange={(e) => handleChange(e.target.value)}
          className="select_container"
        >
          <option value="">Select State</option>
          <option value="Alabama">Alabama</option>
          <option value="Alaska">Alaska</option>
          <option value="Arizona">Arizona</option>
          <option value="California">California</option>
          <option value="Colorado">Colorado</option>
          <option value="Connecticut">Connecticut</option>
          <option value="Delaware">Delaware</option>
          <option value="Florida">Florida</option>
          <option value="Georgia">Georgia</option>
          <option value="Hawaii">Hawaii</option>
          <option value="Idaho">Idaho</option>
          <option value="Illinois">Illinois</option>
          <option value="Indiana">Indiana</option>
          <option value="Iowa">Iowa</option>
          <option value="Kansas">Kansas</option>
          <option value="Kentucky">Kentucky</option>
          <option value="Louisiana">Louisiana</option>
          <option value="Maine">Maine</option>
          <option value="Maryland">Maryland</option>
          <option value="Massachusetts">Massachusetts</option>
          <option value="Michigan">Michigan</option>
          <option value="Minnesota">Minnesota</option>
          <option value="Mississippi">Mississippi</option>
          <option value="Missouri">Missouri</option>
          <option value="Montana">Montana</option>
          <option value="Nebraska">Nebraska</option>
          <option value="Nevada">Nevada</option>
          <option value="New Hampshire">New Hampshire</option>
          <option value="New Jersey">New Jersey</option>
          <option value="New Mexico">New Mexico</option>
          <option value="New York">New York</option>
          <option value="North Carolina">North Carolina</option>
          <option value="North Dakota">North Dakota</option>
          <option value="Ohio">Ohio</option>
          <option value="Oklahoma">Oklahoma</option>
          <option value="Oregon">Oregon</option>
          <option value="Pennsylvania">Pennsylvania</option>
          <option value="Rhode Island">Rhode Island</option>
          <option value="South Carolina">South Carolina</option>
          <option value="South Dakota">South Dakota</option>
          <option value="Tennessee">Tennessee</option>
          <option value="Texas">Texas</option>
          <option value="Utah">Utah</option>
          <option value="Vermont">Vermont</option>
          <option value="Virginia">Virginia</option>
          <option value="Washington">Washington</option>
          <option value="West Virginia">West Virginia</option>
          <option value="Wisconsin">Wisconsin</option>
          <option value="Wyoming">Wyoming</option>{" "}
        </select>
      </div>
      <div className="notice_container">
        <p>
          यहाँ आफ्नो नाम देख्नु भएन? चिन्ता नगर्नुहोस् ! यदि तपाईं मगर भिक्षु, लामा, वापा
          हुनुहुन्छ र यहाँ आफ्नो नाम समावेश गर्न चाहनुहुन्छ भने, कृपया हामीलाई
          इमेल गर्नुहोस् ताकि हामी तपाईंलाई सम्पर्क गर्न सकौं। इमेल गर्न यहाँ <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              window.location.href =
                "mailto:magarbhasalipi@gmail.com?subject=Requesting To Be In Monk list&body=please mention your name, phone number and location. we will contact you soon for verification.";
            }}
            style={{
              padding: "0 5px",
              cursor: "pointer",
              background: "orange",
              border: "none",
            }}
          >
            📧{" "}
          </button> {" "}
          क्लिक गर्नुहोस् । 
        </p>
      </div>
      <div className="lamaCards">
        {data.map((d, i) => {
          return (
            <div
              key={i}
              className="lamaCard"
              style={{ backgroundColor: d.color }}
            >
              <img src={d.img} alt="" className="lamaCard_image" />
              <div className="card_text_container">
              <b className="magarName">{d.magarName}</b>
                <br />
                <b>Name: </b>
                {d.name}
                <br />
                {/* <b>Title: </b>
                {d.title}
                <br /> */}
                <b>Practicing since: </b>
                {d.year}
                <br />
                {/* <b>Color: </b>{d.color}<br /> */}
                <b>Specialize in: </b>
                {d.category}
                <br />
                {/* <b>Ranks: </b>
              {d.ranks}
              <br /> */}
                <b>Location: </b> {d.city}, {d.state}
                <br />
                {/* <div className="communicationContainer">
                <h4>
                  Contact this priest:{" "} */}
                  <a
                    href={d.mailUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                    alt="contact this priest"
                  >
          
                    <button className="mediaIcon">📨 Contact me</button>
                  </a>
                  
                {/* <ContactMailIcon fontSize="medium" color="success" />  */}
                {/* </h4>
              </div> */}
              </div>
            </div>
          );
        })}
        <div className="clearboth"></div>
        {data.length === 0 && <span className="no_records_found_container">⚠️ No records found to display!</span>}
      </div>
      {/* <GoogleAd slot="6987355888"/> */}
      <h3 style={{ textAlign: "center", fontFamily: "monospace", fontSize: '20px' }}>Source credit: Prem Ale Magar</h3>
    </div>
  );
};

export default LamaApp;
