import React, { Component } from 'react'
import FourDirApp from './FourDirection'

export default class Cover_FourDirection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            CDsAndVinylID: "amzn-assoc-ad-a257e359-29db-4652-8e67-1e21d34a4fd6",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=a257e359-29db-4652-8e67-1e21d34a4fd6";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <FourDirApp/>
                {/* <div id={this.state.CDsAndVinylID}/>  */}
            </div>
        )
    }
}
