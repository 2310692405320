import { Divider } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/Images/magarsmajLogo.png'
import FinancialDisclaimer from './FinancialDisclaimer'
// import ShareBtn from "react-share-button";

// const url = "magar_society_mainpage/general_meeting_notice"
// const text="Click the link below to see Invitaion/Announcement for upcoming General Meeting."

export default function GeneralMeetingNotice() {
  return (
    <div className="generalMeetingMain_container">
      <Link to="/magar_society_mainpage" onClick={() => window.scrollTo(0, 0)}>
        <button className="back_button">Back to Magar Society Page</button>
      </Link>
      <div className="generalMeetingNoticeHeader_container">
        <img src={logo} alt="" className="generalMeetingNotice_logoImage" />
        <div>
          <h1 className="generalMeetingNoticeHeaderText">भूटनिज मगर गुमस्ठ</h1>
        </div>
      </div>
      <h3 className="generalMeetingNoticeSubHeaderText">
        *** साधारण सभा (General Meeting) सूचना ***
      </h3>
      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />
      <p className="generalMeetingNotice_para">पट्टकी मिझोर,</p>
      <p className="generalMeetingNotice_para">
        सर्वप्रथम म आशा गर्दछु कि तपाईंहरु कोभिड-१९(Covid-19) बाट सुरक्षित तथा
        सकुशल हुनुहुन्छ।
      </p>
      <p className="generalMeetingNotice_para">
        सम्पूर्ण भूटनिज मगर गुमस्ठ सदस्यहरुमा यो सूचित गरिन्छ कि, हामी January
        2, 2022 मा साधारण सभा(general meeting) आयोजना गर्दैछौं जसमा हामीले धेरै
        कुराहरु समावेश गर्ने भएकाले सबै सदस्यहरूलाई समयमै उपस्थित भइदिनुहुन
        अनुरोध गर्दछु। महामारीका कारण हामीले गत वर्षको साधारण सभा गुमाएका छौं।
      </p>
      <p className="generalMeetingNotice_para">
        भेला ठेगाना: <strong>5661 Fordham Ave. Harrisburg, PA-17111</strong>
      </p>
      <p className="generalMeetingNotice_para">
        भेला हुने समय: <strong>10 am.</strong>
      </p>
      <p className="generalMeetingNotice_para">
        भेला सकिने समय: <strong>2 pm.</strong>
      </p>
      <p className="generalMeetingNotice_para">
        यसै सूचना मार्फत, आउदो वर्ष २०२२ को लागि $ 50 मगर समाज सदस्यता शुल्क
        (member fee) संकलन(collection) गर्ने अवधि समय January महिना को अन्तिम
        सम्ममा बुझाईसक्नु पर्ने जानकारी गराउन चाहनछौं।{' '}
      </p>
      <p className="generalMeetingNotice_para">
        यदि तपाइँ नगद द्वारा शुल्क बुझाउन चाहनुहुन्छ भने, तपाइँ यसलाई साधारण
        सभाको दिनमा ल्याउन सक्नुहुन्छ। यदि तपाइँ चेक मार्फत शुल्क बुझाउन
        हुनुहुन्छ भने, कृपया B.B.O को नाममा भुक्तानयोग्य (check payable to
        B.B.O) चेक बनाउनुहोस् र तपाइँको सुविधा अनुसार माथि उल्लेख गरिएको
        ठेगानामा छोड्नुहोस्। यदि तपाइँसँग कुनै प्रश्न वा जिज्ञासा छ भने, कृपया
        Bal Monger (717-480-6730) लाई सम्पर्क गर्न नहिचकिचाउनुहोस्।
      </p>
      <p className="generalMeetingNotice_para">
        नोट: कृपया नगद Mail नगर्नुहोस्।
      </p>
      <p className="generalMeetingNotice_para">धन्यवाद।</p>
      <p className="generalMeetingNotice_para">भूटनिज मगर गुमस्ठ</p>
      <p className="generalMeetingNotice_para">हेरिसबर्ग & ल्यानकास्टर</p>
      {/* <ShareBtn url={url} text={text} className="ib" displayText="Share this Announcement" /> */}
      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />
      <FinancialDisclaimer />
    </div>
  )
}
