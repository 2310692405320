import React from "react";
import "./Aakaar.css";

function GridRowVowelAakaar({
  id,
  akkha_VowelAakar,
  kantipur_VowelAakar,
  Nep_Meaning,
  Eng_Meaning
}) {
  return (
    <tr className="vowelAakar_tableRow">
      <td className="akkha_vowelAakar">{akkha_VowelAakar}</td>
      <td className="kantipur_vowelAakar">{kantipur_VowelAakar}</td>
      <td className="nep_meaning_vowelAakar">{Nep_Meaning}</td>
      <td className="eng_meaning_vowelAakar">{Eng_Meaning}</td>
    </tr>
  );
}

export default GridRowVowelAakaar;
