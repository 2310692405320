import React from "react";
import { Switch, Redirect } from "react-router-dom";

import { RouteWithLayout } from "./components";
import { Main as MainLayout } from "./layouts";
import { BamaMain as BamaMainLayout } from "./layouts";

import {
  WelcomePageApp as HomeView,
  ConsonantPage as ConsonantPageView,
  VowelsPage as VowelsView,
  GyarakhariPage as GyarakhariView,
  NumberPage as NumberView,
  HalfFormsPage as HalfFormsView,
  BasicTermsPage as BasicTermsView,
  ReadingPracticePage as ReadingPracticeView,
  KeyboardPage as KeyboardTypingPracticeView,
  // archivePages as ArchivePageView
} from "./views";
import AnnMessPage from "./ANNOUNCEMENTS/CURRENT_ANNOUNCEMENT/Cur_AnnMessApp";
// import VideoLibraryPage from './ARCHIVES/VIDEO_LIBRARY/Vid_Lib'
import HistoryAndTimelinePage from "./ARCHIVES/HistoryAndTimeline/HisAndTimeline";
import BooksAndSahityaPage from "./ARCHIVES/HistoryAndTimeline/HisAndTimeline";
import LamaPage from "./LAMA/CoverLamaAppBack";
import ErrorPage from "./views/ErrorLandingPage/Error404";
import MagarSocietyPage from "./MS-HARRISBURG/MS_MainPage";
import GeneralMeetingNoticePage from "./MS-HARRISBURG/GeneralMeetingNotice";
import Buddha_Jayanti_Invitation_May_2022 from "./MS-HARRISBURG/Buddha_Jayanti2022/Buddha_Jayanti_Invitation_May2022";

import GeneralMeetingNoticeApril24_2022 from "./MS-HARRISBURG/GeneralMeetingNotice_April24_2022";
import GeneralMeetingNoticeJuly2_2022 from "./MS-HARRISBURG/GeneralMeetingNotice_July3_2022";
import MaghaySakrantin2022InvitationPage from "./MS-HARRISBURG/MaghaySakranti2022_Invitation";
import ObituaryPage from "./MS-HARRISBURG/obituary1";
import DhirajObituaryPage from "./MS-HARRISBURG/dhiraj_obituary";
import KulDarlamiObituaryPage from "./MS-HARRISBURG/kulDarlami_obituary";
import pampaLamichhaneObituaryPage from "./MS-HARRISBURG/pampaLamichhaneObituary";
import LamichhaneBabyGirlObituaryPage from "./MS-HARRISBURG/LamichhaneBabyGirl";
import Member_Fee_Notification_Page from "./MS-HARRISBURG/Member_Fee_Notice_July3_2022";
import bamaConventionInvitationPage from "./MS-HARRISBURG/BAMA_Convention2022/bamaConvention_Invitation";
import bamaConventionInvitationToAllPage from "./MS-HARRISBURG/BAMA_Convention2022/bamaConvention_Invitation_toAll";
import GumusthaBoardMemberMeetingNoticePage from "./MS-HARRISBURG/Gumustha_BoardMemberMeeting";
import GeneralMeetingNoticeSept24_2022Page from "./MS-HARRISBURG/GeneralMeetingNotice_Sept24_2022"
import GeneralMeetingNoticeforMaghaySakranti_2023 from "./MS-HARRISBURG/GeneralMeetingNoticeforMaghaySakranti2023";

const Routes = () => {
  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      <RouteWithLayout
        component={HomeView}
        exact
        layout={MainLayout}
        path="/home"
      />
      <RouteWithLayout
        component={VowelsView}
        exact
        layout={MainLayout}
        path="/Vowels"
      />
      <RouteWithLayout
        component={ConsonantPageView}
        exact
        layout={MainLayout}
        path="/consonant"
      />
      <RouteWithLayout
        component={GyarakhariView}
        exact
        layout={MainLayout}
        path="/gyarakhari"
      />
      <RouteWithLayout
        component={NumberView}
        exact
        layout={MainLayout}
        path="/numbers"
      />
      <RouteWithLayout
        component={HalfFormsView}
        exact
        layout={MainLayout}
        path="/halfForms"
      />
      <RouteWithLayout
        component={BasicTermsView}
        exact
        layout={MainLayout}
        path="/basicTerms"
      />
      <RouteWithLayout
        component={ReadingPracticeView}
        exact
        layout={MainLayout}
        path="/readingPractice"
      />
      <RouteWithLayout
        component={KeyboardTypingPracticeView}
        exact
        layout={MainLayout}
        path="/typingPractice"
      />
      <RouteWithLayout
        component={AnnMessPage}
        exact
        layout={MainLayout}
        path="/announcement"
      />
      <RouteWithLayout
        component={LamaPage}
        exact
        layout={MainLayout}
        path="/searchForLamaNearYou"
      />
      <RouteWithLayout
        component={HistoryAndTimelinePage}
        exact
        layout={MainLayout}
        path="/videoLibrary"
      />
      <RouteWithLayout
        component={HistoryAndTimelinePage}
        exact
        layout={MainLayout}
        path="/hisAndTimeline"
      />
      <RouteWithLayout
        component={BooksAndSahityaPage}
        exact
        layout={MainLayout}
        path="/booksAndSahitya"
      />
      <RouteWithLayout
        component={MagarSocietyPage}
        exact
        layout={MainLayout}
        path="/magar_society_mainpage"
      />
      <RouteWithLayout
        component={GeneralMeetingNoticePage}
        exact
        layout={MainLayout}
        path="/magar_society_mainpage/general_meeting_notice"
      />
      <RouteWithLayout
        component={GeneralMeetingNoticeApril24_2022}
        exact
        layout={MainLayout}
        path="/magar_society_mainpage/general_meeting_notice_April2022"
      />
      <RouteWithLayout
        component={GeneralMeetingNoticeforMaghaySakranti_2023}
        exact
        layout={MainLayout}
        path="/magar_society_mainpage/GeneralMeetingNoticeforMaghaySakranti2023"
      />
       <RouteWithLayout
        component={GeneralMeetingNoticeSept24_2022Page}
        exact
        layout={MainLayout}
        path="/magar_society_mainpage/general_meeting_notice_Sept24"
      />
      <RouteWithLayout
        component={GeneralMeetingNoticeJuly2_2022}
        exact
        layout={MainLayout}
        path="/magar_society_mainpage/general_meeting_notice_july2022"
      />

      <RouteWithLayout
        component={Buddha_Jayanti_Invitation_May_2022}
        exact
        layout={MainLayout}
        path="/magar_society_mainpage/buddha_jayanti_invitation_May_2022"
      />

      <RouteWithLayout
        component={MaghaySakrantin2022InvitationPage}
        exact
        layout={MainLayout}
        path="/magar_society_mainpage/maghaysakranti_2022_invitation"
      />
      <RouteWithLayout
        component={ObituaryPage}
        exact
        layout={MainLayout}
        path="/magar_society_mainpage/obituary_dhanMayaLungeli"
      />
      <RouteWithLayout
        component={DhirajObituaryPage}
        exact
        layout={MainLayout}
        path="/magar_society_mainpage/dhiraj_samal_obituary"
      />
      
      <RouteWithLayout
        component={KulDarlamiObituaryPage}
        exact
        layout={MainLayout}
        path="/magar_society_mainpage/kul_darlami_obituary"
      />

      <RouteWithLayout
        component={pampaLamichhaneObituaryPage}
        exact
        layout={MainLayout}
        path="/magar_society_mainpage/pampa_Lamichhane_Obituary"
      />
      
      <RouteWithLayout
        component={LamichhaneBabyGirlObituaryPage}
        exact
        layout={MainLayout}
        path="/magar_society_mainpage/lamichhane_baby_girl"
      />
      <RouteWithLayout
        component={Member_Fee_Notification_Page}
        exact
        layout={MainLayout}
        path="/magar_society_mainpage/Member_Fee_Notification"
      />
      <RouteWithLayout
        component={GumusthaBoardMemberMeetingNoticePage}
        exact
        layout={MainLayout}
        path="/magar_society_mainpage/HarrisburgMagarGumusthaBoardMeetingNotice"
      />
      <RouteWithLayout
        component={bamaConventionInvitationPage}
        exact
        layout={BamaMainLayout}
        path="/magar_society_mainpage/bama_ConventionInvitation"
      />
      <RouteWithLayout
        component={bamaConventionInvitationToAllPage}
        exact
        layout={BamaMainLayout}
        path="/magar_society_mainpage/bama_ConventionInvitation_toAll"
      />
      <RouteWithLayout
        component={ErrorPage}
        exact
        layout={MainLayout}
        path="/error404"
      />
      {/* <Redirect to= "/not-found" /> */}
      {/* <Redirect exact from="/not-found" to='/error404' />  */}
      <Redirect to="/error404" />
    </Switch>
  );
};

export default Routes;
