import { Divider } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import logo from '../assets/Images/magarsmajLogo.png'
import dhiraj_samal from '../assets/Images/dhiraj.JPG'
import FinancialDisclaimer from './FinancialDisclaimer'
// import ShareBtn from "react-share-button";

// const url = "magar_society_mainpage/general_meeting_notice"
// const text="Click the link below to see Invitaion/Announcement for upcoming General Meeting."

export default function GeneralMeetingNotice() {
  return (
    <div>
      <Link to="/magar_society_mainpage" onClick={() => window.scrollTo(0, 0)}>
        <button className="back_button">Back to Magar Society Page</button>
      </Link>
      <div className="generalMeetingNoticeHeader_container">
        <img src={logo} alt="" className="generalMeetingNotice_logoImage" />
        <div>
          <h2 className="generalMeetingNoticeHeaderText">भूटनिज मगर गुमस्ठ</h2>
          <h3 className="generalMeetingNoticeSubHeaderText">
            हेरिसबर्ग, पेन्सिलभेनिया
          </h3>
        </div>
      </div>

      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />
      <div className="obituaryMain_container">
        <div className="obituaryHeader">
          <img
            className="obituaryImage"
            src={dhiraj_samal}
            alt="dhiraj_samal"
          />
          <h3 style={{ fontSize: '25px' }}>*** हार्दिक संवेदना ***</h3>
        </div>
        <p className="generalMeetingNotice_para">पट्टकी मिझोर,</p>
        <p className="generalMeetingNotice_para">
          {' '}
          307 Kristy Lane हेरिसबर्ग, पेन्सिलभेनिया निवासी जंग समाल मगर का जेठा
          सुपुत्र <strong>धिराज समाल मगर</strong> को गत साता मंगलबार January 18,
          2022 का दिन ३४ वर्षको उमेरमा निधन भएको सबैलाई जानकारी गराउन चाहन्छौ।
          स्वर्गीय धिराज समाल मगर बेलडाँगी–२, सेक्टर D/4, हट नम्बर 349 मा आफ्ना
          एक छोरी र श्रीमतीका साथ बस्दै आएका थिए। उहाँको दिवंगत आत्माको चीर
          शान्तिको कामनाका साथै भावपूर्ण श्रद्धाञ्जली व्यक्त गर्दछौं । यस दु:खद
          घडीमा शोक सन्तप्त समाल परिवार र आफन्तजनमा धैर्य धारण गर्ने शक्ति
          प्राप्त होस् भनि हार्दिक संवेदना व्यक्त गर्दछौं । यो सबैलाई हुने
          प्राकृतिक घटना हो। त्यसैले हामी सबैलाई एक अर्काको सहयोगको खाँचो पर्दछ।
        </p>
        <p className="generalMeetingNotice_para">
          भूटनिज मगर गुमस्ठ नियम अनुसार सदाझैं यस्ता दुखद घडीमा समाल परिवारको
          निम्ति आर्थिक सहयोग प्रत्येक सदस्य परिवार बाट $50 संकलन गर्दछौं ।
          तसर्थ यो सूचना प्राप्त हुने बित्तिकै कोषाध्यक्षको ठेगानामा (5661
          Fordham Ave. Harrisburg, PA-17111) आफ्नो सहयोग बुझाउनु हुनेछ भन्ने आशा
          गर्दछु।
        </p>

        <p className="generalMeetingNotice_para">
          यसैगरी उहाँको अन्तिम सुधाई आज (सोमबार January 24, 2022) सम्पन्न भयो।
          यस सूचना बारे केहि जिज्ञासा भए 717-350-8009 मा सम्पर्क गर्न अनुरोध
          गर्दछौं।
        </p>
        <p className="generalMeetingNotice_salutation">धन्यवाद।</p>
        <p className="generalMeetingNotice_salutation">नर बलम्पाकी मगर</p>
        <p className="generalMeetingNotice_salutation">अध्यक्ष</p>
        <p className="generalMeetingNotice_salutation">भूटनिज मगर गुमस्ठ</p>
        <p className="generalMeetingNotice_salutation">
          हेरिसबर्ग, पेन्सिलभेनिया
        </p>
        {/* <ShareBtn url={url} text={text} className="ib" displayText="Share this Announcement" /> */}
      </div>
      <Divider
        style={{
          backgroundColor: 'white',
          maxWidth: '1124px',
          margin: '20px auto',
        }}
      />
      <FinancialDisclaimer />
    </div>
  )
}
