import React from "react";
import './Ukaar.css'
import GridRowVowelUkaar from './GridRowVowelUkaar';
import VowelUkaarData from './VowelUkaarData.json';

export default function VowelUkaar() {
  const VowelUkarContent = VowelUkaarData.map(
    ({ id, akkha_VowelUkar, kantipur_VowelUkar, Nep_Meaning, Eng_Meaning }) => (
      <GridRowVowelUkaar
        id={id}
        akkha_VowelUkar={akkha_VowelUkar}
        kantipur_VowelUkar={kantipur_VowelUkar}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );
  
  return (
    <div className="Vowel_ukaar_Container">
      <h3 className="Vowel_Ukar_Heading">
        {/* <p className="matra">q</p> */}
        "ु" उकारलाई प्रयोग गर्दा बनिने शब्दहरु:</h3>
      <div className="ukaar_table_div">
        <table align="center">
        <thead className="ukaar_tableHeader">
            <tr>
              <th className="ukaar_heading_table">Akkha</th>
              <th className="ukaar_heading_table">Nepali Trans</th>
              <th className="ukaar_heading_table">Nepali Meaning</th>
              <th className="ukaar_heading_table">English Meaning</th>
            </tr>
          </thead>
          <tbody className="eachCells">{VowelUkarContent}</tbody>
        </table>
      </div>
    </div>
  );
}
