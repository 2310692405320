import React from "react";
import "../Halfforms.css";

function GridRowHalf_forms_YaApp({
  id,
  akkha,
  nepali,
  akkha1,
  nepali1,
  akkha2,
  nepali2
}) {
  return (
    <tr className="HalfForms_tableRow">
      {/* <div className="first_column"> */}
      <td className="akkha_Half_forms">{akkha}</td>
      <td className="Nep_Half_forms">{nepali}</td>
      {/* </div> */}
      {/* <div className="second_column"> */}
      <td className="akkha_Half_forms">{akkha1}</td>
      <td className="Nep_Half_forms">{nepali1}</td>
      {/* </div> */}
      {/* <div className="third_column"> */}
      <td className="akkha_Half_forms">{akkha2}</td>
      <td className="Nep_Half_forms">{nepali2}</td>
      {/* </div> */}
    </tr>
  );
}

export default GridRowHalf_forms_YaApp;
