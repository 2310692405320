import React, { useState } from "react";
import "./LamaStyles.css";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import dataList from "./FrontDatalist";
import { Link } from "react-router-dom";

const LamaApp = () => {
// eslint-disable-next-line
  const [data, setData] = useState(dataList);
  return (
    <div className="main_container">
      <Link to="/searchForLamaNearYou" style={{textDecoration: "none", color: '#fff'}}><OpenInNewIcon/></Link>
      <h2 className="lamaHeading">Magar Lamas</h2>
      <div className="lamaCards">
        {data.map((d, i) => {
          return (
            <div
              key={i}
              className="lamaCard"
              style={{ backgroundColor: d.color }}
            >
              <img src={d.img} alt="" className="lamaCard_image" />
              <div className="card_text_container">
              <b className="magarName">{d.magarName}</b>
                <br />
                <b>Name: </b>
                {d.name}
                <br />
                {/* <b>Title: </b>
                {d.title}
                <br /> */}
                <b>Practicing since: </b>
                {d.year}
                <br />
                {/* <b>Color: </b>{d.color}<br /> */}
                <b>Specialize in: </b>
                {d.category}
                <br />
                {/* <b>Ranks: </b>
              {d.ranks}
              <br /> */}
                <b>Location: </b> {d.city}, {d.state}
                <br />
                {/* <div className="communicationContainer">
                <h4>
                  Contact this priest:{" "} */}
                  <a
                    href={d.mailUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ textDecoration: "none" }}
                    alt="contact this priest"
                  >
          
                    <button className="mediaIcon">📨 Contact me</button>
                  </a>
                {/* <ContactMailIcon fontSize="medium" color="success" />  */}
                {/* </h4>
              </div> */}
              </div>
            </div>
          );
        })}
      </div>
      <Link to="/searchForLamaNearYou"  style={{display: 'flex', justifyContent: 'center', marginBottom: '15px'}} onClick={() => window.scrollTo(0, 0)}><button className="loadMoreBtn">Show More...</button></Link>
      <h3 style={{ textAlign: "center", fontFamily: "monospace", fontSize: '20px' }}>Source credit: Prem Ale Magar</h3>
    </div>
  );
};

export default LamaApp;

      
      