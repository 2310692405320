import React, { Component } from 'react'
import VowelAekaar from './VowelAekaar';

export default class CoverVowelAekaar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          cameraAndPhotosID: "amzn-assoc-ad-63df27ac-6bdb-4bbf-83a1-24728db5aebe",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=63df27ac-6bdb-4bbf-83a1-24728db5aebe";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <VowelAekaar/>
                {/* <div id={this.state.cameraAndPhotosID}></div>  */}
            </div>
        )
    }
}