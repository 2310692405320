import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { makeStyles } from "@material-ui/styles";
import { Link as ScrollLink } from "react-scroll";
import { Link } from "react-router-dom";

import CoverVowelsPage from "../../pages/VowelsAndSymbols/CoverVowelsAndSymbols";
// import MusicalAdsApp from "../../AdsContainer/GourmetAdsApp";
import CoverVowelAakaarApp from "../../pages/VowelsAndSymbols/AakaarWords/CoverVowelAakaar";
import CoverVowelEeKaar from "../../pages/VowelsAndSymbols/EekaarWords/CoverVowelEekaar";
import CoverVowelUkaar from "../../pages/VowelsAndSymbols/UkaarWords/CoverVowelUkaar";
import CoverVowelUuKaar from "../../pages/VowelsAndSymbols/UukaarWords/CoverVowelUukaar";
import CoverVowelAekaar from "../../pages/VowelsAndSymbols/AekaarWords/CoverVowelAekaar";
import CoverVowelAikaar from "../../pages/VowelsAndSymbols/AikaarWords/CoverAikaar";
import CoverVowelOkaar from "../../pages/VowelsAndSymbols/OkaarWords/CoverOkaar";
import CoverVowelOukaarApp from "../../pages/VowelsAndSymbols/OukaarWords/CoverOukaar";
import CoverAllVowelWordsApp from "../../pages/VowelsAndSymbols/AllVowelWords/CoverAllVowelWords";
import VowelCarousel from "../../ImageCarousel/js/VowelCarousel";
import AffiliateDisclaimer from "../../pages/AffiliateDisclaimer";
// import VowelShare from './VowelShare'

// import AudibleAdsApp from '../../AdsContainer/AudibleAdsApp'
// import BeautyAdsApp from '../../AdsContainer/BeautyAdsApp'
// import GourmetAdsApp from '../../AdsContainer/GourmetAdsApp'
// import HealthAndPersonalCareAdsApp from '../../AdsContainer/HealthAndPersonalCareAdsApp'
// import HomeAndGardenAdsApp from '../../AdsContainer/HomeAndGardenAdsApp'
// import KindleAdsApp from '../../AdsContainer/KindleAdsApp'
// import KitchenAndHouseWareAdsApp from '../../AdsContainer/KitchenAndHouseWareAdsApp'
// import OutletAdsApp from '../../AdsContainer/OutletAdsApp'
// import PrimeSubsAds from '../../AdsContainer/PrimeSubsAds'
// import PrimeVideoAdsApp from '../../AdsContainer/PrimeVideoAdsApp'
// import SoftwareAdsApp from '../../AdsContainer/SoftwareAdsApp'

// Carousel start
const SLIDE_COUNT = 5;
const slides = Array.from(Array(SLIDE_COUNT).keys());
// Carousel end

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  linkContainer: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 5,
  },
  nav_buttons: {
    padding: 5,
    borderRadius: 5,
    fontSize: 15,
    color: "gray",
    fontWeight: "bold",
    cursor: "pointer",
  },
}));

const Vowels = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)} id="topofpage">
      <VowelCarousel slides={slides} />
      {/* <AffiliateDisclaimer /> */}
      <div className={classes.linkContainer}>
        <Link to="/consonant" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Prev: Consonants</button>
        </Link>
        <Link to="/gyarakhari" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Next: Gyarakhari</button>
        </Link>
      </div>
      <CoverVowelsPage />
      {/* <AudibleAdsApp /> */}
      <CoverVowelAakaarApp />
      {/* <GourmetAdsApp /> */}
      <CoverVowelEeKaar />
      {/* <HealthAndPersonalCareAdsApp /> */}
      <CoverVowelUkaar />
      {/* <HomeAndGardenAdsApp /> */}
      <CoverVowelUuKaar />
      {/* <KindleAdsApp /> */}
      <CoverVowelAekaar />
      {/* <KitchenAndHouseWareAdsApp /> */}
      <CoverVowelAikaar />
      {/* <OutletAdsApp /> */}
      <CoverVowelOkaar />
      {/* <PrimeSubsAds /> */}
      <CoverVowelOukaarApp />
      {/* <PrimeVideoAdsApp /> */}
      <CoverAllVowelWordsApp />
      {/* <SoftwareAdsApp /> */}
      <div className={classes.linkContainer}>
        <Link to="/consonant" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Prev: Consonants</button>
        </Link>
        <Link to="/gyarakhari" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Next: Gyarakhari</button>
        </Link>
      </div>
      <ScrollLink to="topofpage" smooth={true} className="to-top-btn">
        <button className="to-top-button">Back to top ↑</button>
      </ScrollLink>
      {/* <VowelShare/> */}
    </div>
  );
};

Vowels.propTypes = {
  className: PropTypes.string,
};

export default Vowels;
