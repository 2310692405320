import React from 'react'
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    disclaimer_container: {
        backgroundColor: "dodgerblue",
        borderRadius: "10px 10px 0 0",
        padding: 8,
        color: "white",
textAlign: 'right'
      },
      disclaimer_headingText: {
        color: "gold",
        fontFamily: "Tahoma",
        fontSize: 12,
      },
      disclaimer_para: {
        color: "aliceblue",
        fontFamily: "Tahoma",
        fontSize: 13,
        textAlign: "justify",
      },
}))

export default function AffiliateDisclaimer() {
    const classes = useStyles();
    return (
        <div className={classes.disclaimer_container}>
            <h3 className={classes.disclaimer_headingText}>
            Financial Disclaimer:
          </h3>
          <p className={classes.disclaimer_para}>
            Magar Bhasali lipi Sipal does not demand or make a direct representation of any organization for collecting money. The site is only allowing space to place their Announcement to pass along their Society Members. This site is not charging for the space provided.</p>
        </div>
    )
}
