import React from "react";
import Exercise3AccordionApp from "./Exercise3Accordion";
import './styles.css'

export default function Exercise3App() {
  return (
    <div className="Exercise_container">
      <h3 className="Exercise_akkha_Para">
        mAjA CAnA d bAdAkOlAk aAdr jAtkE prdEslQ. DlEz jSyOC jSyAnAz KAc CAnlQ. tI dnE zA lAhUr nYzlQ. </h3>
      <Exercise3AccordionApp />
    </div>
  );
}
