import React, { Component } from 'react'
import HalfFormsRaApp from './Half_forms_Ra'


export default class CoverHalfForms_Ra extends Component {
    constructor(props) {
        super(props);
        this.state = {
            kitchenAndDiningID: "amzn-assoc-ad-702616d5-79df-46b1-9e87-37780239bf7c",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=702616d5-79df-46b1-9e87-37780239bf7c";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <HalfFormsRaApp/>
                {/* <div id={this.state.kitchenAndDiningID}/>  */}
            </div>
        )
    }
}
