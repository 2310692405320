import React, { Component } from 'react'
import VowelAikaar from './VowelAikaar';

export default class CoverVowelAikaar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          classicalMusicID: "amzn-assoc-ad-9c4e60d7-81af-4f86-80cd-f12de806c6f4",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=9c4e60d7-81af-4f86-80cd-f12de806c6f4";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <VowelAikaar/>
                {/* <div id={this.state.classicalMusicID}></div>  */}
            </div>
        )
    }
}
