import React from "react";
import '../Halfforms.css'
import Half_Form_GeneralWordsData from './Half_forms_GeneralWordsData.json';
import Half_Form_GeneralNegationData from './Half_forms_GeneralNegationData.json';
import GridRowHalfFormsGeneralWords from "./GridRowHalf_forms_GeneralWords";
import GridRowHalfFormsGeneralWordsNegation from "./GridRowHalf_forms_GeneralWords_Negation";


export default function Half_Forms_GeneralApp() {
  const Half_forms_GeneralWordsContent = Half_Form_GeneralWordsData.map(
    ({ id, Nep_Num, akkha_Words, Nep_Transliteration, Nep_Meaning }) => (
      <GridRowHalfFormsGeneralWords
        id={id}
        Nep_Num={Nep_Num}
        akkha_Words={akkha_Words}
        Nep_Transliteration={Nep_Transliteration}
        Nep_Meaning={Nep_Meaning}
      />
    )
  );

  const Half_forms_GeneralNegationContent = Half_Form_GeneralNegationData.map(
    ({ id, akkha_Words, akkha_Meaning }) => (
      <GridRowHalfFormsGeneralWordsNegation
        id={id}
        akkha_Words={akkha_Words}
        akkha_Meaning={akkha_Meaning}
      />
    )
  );

  return (
    <div className="HalfFormsGeneral_bcg">

      <h2 className="halfForms_Heading_text">अर्ध अक्षरका नियमहरु:</h2>
      <p className="halfForms_para">मगर भाषामा शब्दहरु आफ्नो अक्खालिपिमा प्रयोग गर्दा यदि अर्ध अक्षरको उच्चारण आएमा ती अर्ध अक्षरलाई कहाँ, कसरी र कुन समयमा प्रयोग गर्न सकिने भन्ने बारेमा दुई तिनवटा अत्यन्तै महत्वपूर्ण नियम भएकोले गर्दा यस नियमलाई धेरै ख्याल र ध्यान दिनु पर्ने आवश्यकता भएकाले यहाँ उदाहरण सहित यसलाई सजिलो र सरल तरिकाले प्रस्टाउने कोशिस गरिएको छ । यी नियमहरु यस प्रकारका छन। </p>
      <ul className="halfForms_para_ul">
        <li>हामीले शब्द प्रयोग गर्दा यदि उच्चारण भएको अर्ध अक्षर मा कुनै मात्राहरु जोडिएर अथवा नजोडी अर्थ बुझाए या नबुझाए
          पनि यदि ती अर्ध अक्षर शब्दको पहिलो भागमा उच्चारण भए त्यसलाई प्रयोगमा ल्याउन सकिन्छ।  </li>
          <br />
        <li>यदि अर्ध अक्षर शब्दको दोश्रो तेश्रो अथवा कुनै अन्त भागमा उचारण भएर शब्द अर्थ बुझाउ छ भने ती अर्ध अक्षर लाई प्रयोगमा ल्याउन सकिन्छ। तर यदि अर्ध अक्षर शब्दको पहिलो भाग छाडि कुनै अन्त भागमा उच्चारण भएर पनि शब्द अर्थ बुझाउदैन भने ति अर्ध अक्षरलाई दुई देखि तिन पटक सम्म दोहोर्याई उच्चारण अनुसार प्रयोग गर्नुपर्छ।</li>
        <br />
        <li>तेश्रो ध्यान दिनु पर्ने कुनै सकर्मक (Positive) शब्द हरु यदि अर्ध अक्षर बाट सुरु भएर बनिएको छ र त्यस शब्दलाई अकर्मक (Negative) बनाउनु परेमा ती अर्ध अक्षर जस्ता को त्यस्तै हुन्छ, कुनै परिवर्तन हुदैन। </li>
        <br />
        <li>मगर भाषामा एक भन्दा बढी अर्ध अक्षर जोडिएर शब्द बनिएको हुन्छ।</li>
        <br />
      </ul>
      <div className="halfForms_bottom_part">
        <table align="center">
        <thead className="halfForms_tableHeader">
            <tr>
              <th className="halfForms_heading_table1">E.g.</th>
              <th className="halfForms_heading_table1">Akkha Words</th>
              <th className="halfForms_heading_table1">Nepali Trans</th>
              <th className="halfForms_heading_table1">Nepali Meaning</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_first_eachCells">{Half_forms_GeneralWordsContent}</tbody>
        </table>
      </div>
      <p className="hfgeneral_para">यहाँ उदाहरण १, २, र ३ मा अर्ध अक्षरहरु hSwA, jSyA, sSyA जोडिएरनै एउटा नयाँ शब्दहरु जस्तै hSwAkE, jSyAkE र sSyAtkE बनिएर सिंगो शब्द भएता पनि यी अर्ध अक्षर मात्रले पनि शब्द बुझाउछ र यो जोडिएका अक्षरहरु hSwA, jSyA, sSyA स्वतन्त्र र सिंगो शब्दहरु पनि हुन् ।</p>
      <p className="hfgeneral_para">जस्तै :- hSwA भन्नाले गयो भन्ने अर्थ बुझाउछ भने jSyA भन्नाले खायो र sSyA भन्नाले मासु भन्ने अर्थ बुझाउछ।  तसर्थ यस्ता शब्द-अर्थ बुझाउने कुनै पनि अर्ध अक्षर शब्दको कुनै पनि भागमा उच्चारण भएर अरु कुनै नयाँ शब्द बनिन्छ भने यसलाई नछुटाई प्रयोगमा ल्याउन सकिन्छ।  </p>
      <p className="hfgeneral_para">यसरीनै उदाहरण ४, ५ र ६ मा अर्ध अक्षरहरु lShQ, mShU, nShQ भएर कुनै शब्द अर्थ नबुझाएता पनि शब्दको पहिलो भागमा उच्चारण भएको हुँदा यसलाई प्रयोगमा ल्याउन सकिन्छ तर अरु भागमा प्रयोग गर्न सकिंदैन।</p>
      <p className="hfgeneral_para">त्यसैले अर्ध अक्षरमा कुनै मात्राहरु जोडिएर शब्दको कुनै पनि भागमा उच्चारण भएर शब्द बनिन्छ भने त्यो अर्ध अक्षर जस्ताको त्यस्तै लेख्न सकिन्छ।</p>
      <p className="hfgeneral_para">उदाहरण (६) मा शब्दको तेश्रो भागमा अर्ध अक्षर "क" को उच्चारण भएर अर्थ नबुझाएकाले त्यसलाई दुई पटक दोहोर्याएर लेख्नु पर्दछ।  उदाहरण ७ मा अर्ध अक्षर शब्द को दोश्रो भागमा छ भने उदाहरण ८ मा तेश्रो र उदाहरण ९ चौथो भागमा प्रयोग भएर शब्द बनिएको छ। कारण यी अर्ध अक्षर जोडिएर उच्चारण भएको शब्दहरु स्वोतन्त्र र सिंगो शब्द पनि हुन।  जस्तै rShA भन्नाले बाख्रा अर्थ बुझाउछ, jSyA भन्नाले खायो र hSyOkA भन्नाले भ्याउनु अथवा सक्नु अर्थ बुझाउछ। यसैले अर्ध अक्षरमा कुनै मात्राहरु जोडीएर शब्दको कुनै भागमा उच्चारण भएर शब्द बनिन्छ भने त्यो अर्ध अक्षर जस्ताको तस्तै लेख्न सकिन्छ।</p>
      <p className="hfgeneral_para">जस्तै:</p>
      <div className="halfForms_bottom_part">
        <table align="center">
        <thead className="halfForms_tableHeader">
            <tr>
              <th className="halfForms_heading_table1">Positive</th>
              <th className="halfForms_heading_table1">Negative</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_second_eachCells">{Half_forms_GeneralNegationContent}</tbody>
        </table>
      </div>
    </div>
  );
}
