import React, { Component } from 'react'
import Exercise3App from './Exercise3'

export default class Cover_Exercise3 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            sportsCollectiblesID: "amzn-assoc-ad-eff82bfc-7fb0-4a78-bd44-12335f9ded8f",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=eff82bfc-7fb0-4a78-bd44-12335f9ded8f";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <Exercise3App/>
                {/* <div id={this.state.sportsCollectiblesID}/>  */}
            </div>
        )
    }
}
