import React from "react";
import "./ConsonantStyles.css";
import PhonemeData from "./PhonemeData.json";
import GridRowPhonemes from "./GridRowPhonemes";

export default function PhonemeApp() {
  const PhonemesContent = PhonemeData.map(
    ({ id, pronounce, phonemeLetters, pronounce_Kantipur }) => (
      <GridRowPhonemes
        id={id}
        pronounce={pronounce}
        phonemeLetters={phonemeLetters}
        pronounce_Kantipur={pronounce_Kantipur}
      />
    )
  );

  return (
    <div className="consonant_Alphabet_grid" id="topofpage">
      <h1 className="Consonant_Heading_text">Phonemes</h1>
      <div className="phoneme_table_div">
        <table align="center">
          <thead className="consonant_tableHeader">
            <tr>
              <th className="consonant_heading_table">Phoneme Sounds</th>
              <th className="consonant_heading_table">Letters</th>
            </tr>
          </thead>
          <tbody className="phoneme_eachCells">{PhonemesContent}</tbody>
        </table>
      </div>
    </div>
  );
}
