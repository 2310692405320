import React from "react";
import "../Halfforms.css";

function GridRowHalf_forms_WaApp({
  akkha,
  nepali,
  akkha1,
  nepali1,
  akkha2,
  nepali2
}) {

  return (
    <tr className="HalfForms_tableRow">
      <td className="akkha_Half_forms">{akkha}</td>
      <td className="Nep_Half_forms">{nepali}</td>
      <td className="akkha_Half_forms">{akkha1}</td>
      <td className="Nep_Half_forms">{nepali1}</td>
      <td className="akkha_Half_forms">{akkha2}</td>
      <td className="Nep_Half_forms">{nepali2}</td>
    </tr>
  );
}

export default GridRowHalf_forms_WaApp;
