import "./MessageStyles.css";
import axios from "axios";
import Card from "./Card";
import React, { Component } from "react";

export default class MessageApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      isloading: true,
    };
  }

  async componentDidMount() {
    await axios.get("context.json").then((res) => {
      const users = res;
      this.setState({ users, isloading: false });
    });
  }

  render() {
    let res = this.state.users;
    return (
      <div className="cards">
        {res?.data?.data?.map((x) => {
          return (
            <Card
              img={x.avatar}
              author={x.first_name + " " + x.last_name}
              address={x.address}
              shortPara={x.shortPara}
              longParaGreet={x.longParaGreet}
              longParaBody={x.longParaBody}
              LongParaWish={x.LongParaWish}
              title={x.title}
              date={x.date}
            />
          );
        })}
      </div>
    );
  }
}
