import React from "react";
import './GyaraKharistyles.css'
import GyaraKhariExampleData from './GyaraKhariExampleData.json';
import GridRowGyaraKhariExample from './GridRowGyaraKhariExample'


export default function GyaraKhariApp() {
  const GyaraKhariExampleContent = GyaraKhariExampleData.map(
    ({ id, akkha_GyaraKhariExampleFirstColumn, kantipur_GyaraKhariExampleFirstColumn, Akkha_GyaraKhariExampleSecondColumn, kantipur_GyaraKhariExampleSecondColumn }) => (
      <GridRowGyaraKhariExample
        id={id}
        akkha_GyaraKhariExampleFirstColumn={akkha_GyaraKhariExampleFirstColumn}
        kantipur_GyaraKhariExampleFirstColumn={kantipur_GyaraKhariExampleFirstColumn}
        Akkha_GyaraKhariExampleSecondColumn={Akkha_GyaraKhariExampleSecondColumn}
        kantipur_GyaraKhariExampleSecondColumn={kantipur_GyaraKhariExampleSecondColumn}
      />
    )
  );
 
  return (
    <div className="gyaraKhari_container" id="topofpage"> 
      <p className="gyarakhari_para">मगर अक्खालिपिमा देवनागरी लिपिमा झैँ 'त', 'थ', 'द', 'ध' र 'ण' नभएकाले र यस अक्षरको सर्वप्रथम हाम्रो मगर भाषा बोल्दा यस अक्षरको उच्चारण नआउने र शब्द नभएकाले वा त्येस्ता शब्द प्रयोगमा नआउनाले त्यसका निम्ति "त" को सट्टा "ट", "थ" को "ठ", "द" को "ड", "ध" को "ढ" र "ण" को "न" हरुको प्रयोग गर्नुपर्छ।
        जस्तै:
      </p>
      <div className="gyaraKhari_table_div">
        <table align="center">
        <thead className="gyaraKhari_tableHeader">
            <tr>
              <th className="gyaraKhari_heading_table">Akkha Word</th>
              <th className="gyaraKhari_heading_table">Nepali Meaning</th>
              <th className="gyaraKhari_heading_table">Akkha Transliteration</th>
              <th className="gyaraKhari_heading_table">Nepali Transliteration</th>
            </tr>
          </thead>
          <tbody className="gyaraKhari_eachCells">{GyaraKhariExampleContent}</tbody>
        </table>
      </div>
    </div>
  );
}
