import React, { Component } from 'react'
import VowelEekaarApp from './VowelEekaar';

export default class CoverVowelEekaar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          babyAndNurseryID: "amzn-assoc-ad-fec64d4e-6da4-4db1-a37f-b09ffc687253",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=fec64d4e-6da4-4db1-a37f-b09ffc687253";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <VowelEekaarApp/>
                {/* <div id={this.state.babyAndNurseryID}></div>  */}
            </div>
        )
    }
}
