import React from "react";
import "./Uukaar.css";

function GridRowVowelUukaar({
  id,
  akkha_VowelUukar,
  kantipur_VowelUukar,
  Nep_Meaning,
  Eng_Meaning
}) {
  return (
    <tr className="vowelUkar_tableRow">
      <td className="akkha_vowelUukar">{akkha_VowelUukar}</td>
      <td className="kantipur_vowelUukar">{kantipur_VowelUukar}</td>
      <td className="nep_meaning_vowelUukar">{Nep_Meaning}</td>
      <td className="eng_meaning_vowelUukar">{Eng_Meaning}</td>
    </tr>
  );
}

export default GridRowVowelUukaar;
