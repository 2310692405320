import React from 'react'
// import "./Error404.css"
import {Link} from 'react-router-dom'
import { makeStyles } from '@material-ui/styles'
import Background from '../../assets/Images/error.jpg'

const useStyles = makeStyles(theme => ({
    Error_Container: {
        // backgroundImage: "url(" + Background + ")",
        backgroundImage: `url(${Background})`,
        color: "aliceblue",
        height: "100vh",
        display: 'flex',
        backgroundRepeat: 'no-repeat'
    },
    text_temp: {
        textShadow: '1px 1px 2px #000',
        fontFamily: "Georgia, 'Times New Roman', Times, serif",
    },
    text_container: {
        display: 'flex',
        flexDirection: 'column',
        marginLeft: "15%",
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    backToHomeBtn: {
        padding: '5px 15px',
        border: 'none',
        borderRadius: '15px',
        marginTop: "15px",
        cursor: 'pointer'
    }
}));

export default function Error404() {
    const classes = useStyles();
    return (
        <div className={classes.Error_Container}>
            <div className= {classes.text_container}>
            <h2 className={classes.text_temp}>⚠️ OOPS!</h2>
            <h3 className={classes.text_temp}>THE PAGE YOU ARE LOOKING FOR CAN'T BE FOUND!</h3>
            <Link to ="/home" onClick={() => window.scrollTo(0, 0)}>
            <button className={classes.backToHomeBtn}>Go to Homepage</button></Link>
            </div>
            <span style={{ padding: 15, fontFamily: "monospace" }}>
                Photo by{" "}
                <a style={{ textDecoration: null, color: 'gold' }} href="https://unsplash.com/@camsaadat" target="_blank" rel="noopener noreferrer">
                Ali Saadat
                </a>{" "}
                on <a style={{ textDecoration: null, color: 'gold' }} href="https://unsplash.com/photos/Q_y-y4JfYo0" target="_blank" rel="noopener noreferrer">Unsplash</a>
              </span>
        </div>
    )
}