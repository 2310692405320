import React, { Component } from 'react'
import GyaraKhari from '../GyaraKhari';

export default class CoverGyarakhari extends Component {constructor(props) {
    super(props);
    this.state = {
      airTagCollectiblesID: "amzn-assoc-ad-f977a1a4-f502-438c-b6e4-023832a88f34",
    };
  }

componentDidMount() {
    const script = document.createElement("script");

   script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=f977a1a4-f502-438c-b6e4-023832a88f34";
   script.async = true;

   document.body.appendChild(script);
  }
render() {
    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <GyaraKhari/>
            {/* <div id={this.state.airTagCollectiblesID}></div>  */}
        </div>
    )
}
}