import React from "react";
import './Uukaar.css'
import GridRowVowelUukaar from './GridRowVowelUukaar';
import VowelUukaarData from './VowelUukaarData.json';

export default function VowelUukaar() {
  const VowelUukarContent = VowelUukaarData.map(
    ({ id, akkha_VowelUukar, kantipur_VowelUukar, Nep_Meaning, Eng_Meaning }) => (
      <GridRowVowelUukaar
        id={id}
        akkha_VowelUukar={akkha_VowelUukar}
        kantipur_VowelUukar={kantipur_VowelUukar}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );
  return (
    <div className="Vowel_Uukar_Container">
      <h3 className="Vowel_Uukar_Heading">
        {/* <p className="matra">q</p> */}
        "ू" ऊकारलाई प्रयोग गर्दा बनिने शब्दहरु:</h3>
      <div className="Uukar_table_div">
        <table align="center">
        <thead className="Uukar_tableHeader">
            <tr>
              <th className="Uukar_heading_table">Akkha</th>
              <th className="Uukar_heading_table">Nepali Trans</th>
              <th className="Uukar_heading_table">Nepali Meaning</th>
              <th className="Uukar_heading_table">English Meaning</th>
            </tr>
          </thead>
          <tbody className="Uukar_eachCells">{VowelUukarContent}</tbody>
        </table>
      </div>
    </div>
  );
}
