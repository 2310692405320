import React from "react";
import '../Halfforms.css'
import Half_Form_YaData from './Half_forms_YaData.json';
import Half_Form_YaWordsData from './Half_forms_YaWordsData.json';
import GridRowHalfFormsYa from "./GridRowHalf_forms_Ya";
import GridRowHalfFormsYaWords from "./GridRowHalf_forms_YaWords";


export default function Half_Forms_YaApp() {
  const Half_forms_YaContent = Half_Form_YaData.map(
    ({ id, akkha, nepali, akkha1, nepali1, akkha2, nepali2 }) => (
      <GridRowHalfFormsYa
        id={id}
        akkha={akkha}
        nepali={nepali}
        akkha1={akkha1}
        nepali1={nepali1}
        akkha2={akkha2}
        nepali2={nepali2}
      />
    )
  );

  const Half_forms_YaWordsContent = Half_Form_YaWordsData.map(
    ({ id, akkha_Words, Nep_Words, Nep_Meaning, Eng_Meaning }) => (
      <GridRowHalfFormsYaWords
        id={id}
        akkha_Words={akkha_Words}
        Nep_Words={Nep_Words}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );

  return (
    <div className="HalfFormsYa_bcg">
      <h2 className="halfForms_Heading_text">अर्ध अक्षर "य्"</h2>
      <div className="halfForms_top_part">
        <table align="center">
        <thead className="halfForms_tableHeader">
            <tr>
              <th className="halfForms_heading_table">Akkha</th>
              <th className="halfForms_heading_table">Nepali Trans</th>
              <th className="halfForms_heading_table">Akkha</th>
              <th className="halfForms_heading_table">Nepali Trans</th>
              <th className="halfForms_heading_table">Akkha</th>
              <th className="halfForms_heading_table">Nepali Trans</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_first_eachCells">{Half_forms_YaContent}</tbody>
        </table>
      </div>
      <h2 className="halfForms_Heading_text">अर्ध अक्षर "य्" द्वारा बनिने शब्दहरु:</h2>
      <div className="halfForms_bottom_part"> 
        <table align="center">
        <thead className="halfForms_tableHeader">
            <tr>
              <th className="halfForms_heading_table1">Akkha Word</th>
              <th className="halfForms_heading_table1">Nepali Trans</th>
              <th className="halfForms_heading_table1">Nepali Meaning</th>
              <th className="halfForms_heading_table1">English Meaning</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_second_eachCells">{Half_forms_YaWordsContent}</tbody>
        </table>
      </div>
    </div>
  );
}
