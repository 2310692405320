import React, { useRef, useEffect, Fragment } from 'react'
import ReactPlayer from 'react-player'

// import './styles.css'

export default function App() {
  const videoRef = useRef()

  useEffect(() => {
    videoRef.current.addEventListener(
      'contextmenu',
      function (e) {
        e.preventDefault()
      },
      false,
    )

    return videoRef.current.removeEventListener(
      'contextmenu',
      function (e) {
        e.preventDefault()
      },
      false,
    )
  }, [])

  return (
    <Fragment>
      <div ref={videoRef}>
        <ReactPlayer
          width="100%"
          height="100%"
          controls={true}
          url={
            'https://firebasestorage.googleapis.com/v0/b/mymediastorage-e5bb2.appspot.com/o/wapaEndorsementForBuddhaJayanti.mp4?alt=media&token=a982de8f-935c-4d86-a0c1-e4bc7ce7776e'
          }
          config={{ file: { attributes: { controlsList: 'nodownload' } } }}
        />
      </div>

      {/* <div>Hello I'm noraml div</div> */}
    </Fragment>
  )
}
