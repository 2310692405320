import React from "react";
import { Link } from "react-router-dom";
import './msHarrisburg.css'

export default function OtherLinks() {
  return (
      <div
      className="otherLinks_container"
      >
        <h3>Other Links</h3>
        <Link
          to="/consonant"
          onClick={() => window.scrollTo(0, 0)}
          className="otherLinks"
        >
          Learn Akkha-Rika Consonants
        </Link>
        <Link
          to="/Vowels"
          onClick={() => window.scrollTo(0, 0)}
          className="otherLinks"
        >
          Learn Akkha-Rika Vowels
        </Link>
        <Link
          to="/gyarakhari"
          onClick={() => window.scrollTo(0, 0)}
          className="otherLinks"
        >
          Learn Akkha-Rika Gyarakhari
        </Link>
        <Link
          to="/numbers"
          onClick={() => window.scrollTo(0, 0)}
          className="otherLinks"
        >
          Learn Akkha-Rika Numbers
        </Link>
        <Link
          to="/halfForms"
          onClick={() => window.scrollTo(0, 0)}
          className="otherLinks"
        >
          Learn Akkha-Rika Half-Forms
        </Link>
        <Link
          to="/basicTerms"
          onClick={() => window.scrollTo(0, 0)}
          className="otherLinks"
        >
          Learn Akkha-Rika Basic Words
        </Link>
        <Link
          to="/readingPractice"
          onClick={() => window.scrollTo(0, 0)}
          className="otherLinks"
        >
          Learn Akkha-Rika Reading Practice
        </Link>
        <Link
          to="/typingPractice"
          onClick={() => window.scrollTo(0, 0)}
          className="otherLinks"
        >
          Learn Akkha-Rika Typing Practice
        </Link>
        <Link
          to="/searchForLamaNearYou"
          onClick={() => window.scrollTo(0, 0)}
          className="otherLinks"
        >
          Search for Magar Lama Near you.
        </Link>
      </div>
  );
}
