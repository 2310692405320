import React, { Component } from 'react'
import PhonemeApp from './Phoneme';

export default class CoverPhoneme extends Component {
    constructor(props) {
        super(props);
        this.state = {
          applianceID: "amzn-assoc-ad-0d4cbc0d-e687-4492-aa78-9d7b8a8c9a26",
        };
      }
      componentDidMount() {
        const script = document.createElement("script");

       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=0d4cbc0d-e687-4492-aa78-9d7b8a8c9a26";
       script.async = true;
    
       document.body.appendChild(script);
      }

    render() {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <PhonemeApp/>
                {/* <div id={this.state.applianceID} ></div> */}
            </div>
        )
    }
}
