import React from "react";
import Exercise1AccordionApp from "./Exercise1Accordion";
import './styles.css'

export default function Exercise1() {
  return (
    <div className="Exercise_container">
      {/* <h3 className="Exercise_akkha_Para">
        rEkSc dQc gQsmA DlEz KAyAkYz aAlQ. rEkSc dQc vrmE mAdYpSn lhQ lhW DUt fkIkE dQkE aAlQ. KAyAkYz aGQrA lOc vrmEkI mtStI rEkc dQc gQsAkmA gQsAkmAkE aGQrlAk lShOzSc dAzlQ. hOtO bAtI akSKArEkA kAn mgrkYz pYrKW KQwAkSc aAlQ. esQ zAsnAz kAnYz pYrKkO DlEz akl lQc lSyA dQyAd dQkE hSyOkSlQ. kAnYz mgr lEpE lOn bYdD fYngE dQnAz aGQrlAk lkEz lQyA aAtI kQtnnSyA dnE. atI ptSt dQnAz aGQrW lOn bYdDq wAkSk jAtSc bYdD DrmW DUt dnE esQ akSKA lEpEyAznI rEknAz. hOtObAtI aAbkAn ptSt mgrkO kAt CAnAd lhW rEtE rQwAs, lhW DYt d lEpEkE aGQrlAk lShOzkEz dQcI mEJOr jAtSlQ.
      </h3> */}
      <h3 className="Exercise_akkha_Para">
        rEkSc dQc gQsmA DlEz KAyAkYz aAlQ. rEkSc dQc vrmE mAdYpSn lhQ lhW DUt fkIkE dQkE aAlQ. KAyAkYz aGQrA lOc vrmEkI mtStI rEkc dQc gQsAkmA gQsAkmAkE aGQrlAk lShOzSc dAzlQ. hOtO bAtI akSKArEkA kAn mgrkYz pYrKI KQwAkSc aAlQ. esQ zOsnAz kAnYz pYrKkO DlEz akl lQc lSyA dQyAd dQkE hSyOkSlQ. kAnYz mgr lEpE lOn bYdD fYngE dQnAz aGQrlAk lkEz lSyA aAtI kQtAknnSyA dnE.</h3>
      <h3 className="Exercise_akkha_Para">
        atI ptSt dQnAz aGQrW lOn bYdDq wAkSk jAtSc bYdD DrmW DUt dnE esQ akSKA lEpEyAznI rEknnQ. hOtObAtI aAbkAn ptSt mgrkO kAt CAnAd lhW rEtE rQwAs, lhW DYt d lEpEkE aGQrlAk lShOzAkSkE dQcI mEJOr jAtSlQ.
      </h3>
      <Exercise1AccordionApp />
    </div>
  );
}
