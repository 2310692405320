import React from "react";
import "./VowelsAndSymbols.css";
import vowelData from "./VowelData.json";
import vowelAndSymbolData from "./VowelAndSymbolData.json";
import GridRowVowels from "./GridRowVowels";
import GridRowVowelsAndSymbols from "./GridRowVowels&Sym";
// import VowelShare from '../../views/Vowels/VowelShare'
// Carousel start
// const SLIDE_COUNT = 5;
// const slides = Array.from(Array(SLIDE_COUNT).keys());
// Carousel end

export default function VowelsAndSymbols() {
  const VowelContent = vowelData.map(
    ({ id, akkha_Vowel, kantipur_Vowel, eng_transliteration }) => (
      <GridRowVowels
        id={id}
        akkha_Vowel={akkha_Vowel}
        kantipur_Vowel={kantipur_Vowel}
      />
    )
  );

  const VowelAndSymContent = vowelAndSymbolData.map(
    ({
      id,
      akkha_VowelAndSym,
      kantipur_VowelAndSym,
      Akkha_matra,
      kantipur_matra,
    }) => (
      <GridRowVowelsAndSymbols
        id={id}
        akkha_VowelAndSym={akkha_VowelAndSym}
        kantipur_VowelAndSym={kantipur_VowelAndSym}
        Akkha_matra={Akkha_matra}
        kantipur_matra={kantipur_matra}
      />
    )
  );
  return (
    <div className="vs_container">
      {/* <VowelShare/> */}
      <h1 className="mainheading">स्वर-वर्ण (Vowels)</h1>

      <p className="vowelRules_para">
        श्री एम. एस. थापा मगर ज्युको वर्णमाला अनुसार (क) देखि (म) सम्म व्यञ्जन
        २० वटा, (स) देखि (य) सम्म अनि (ञ) गरी अर्ध स्वर ६ वटा र अ, आ, इ, ई, उ,
        ऊ, ए, ऐ सम्म स्वर ८ वटा गरी जम्मा ध्वनि स्वर ३३ वटा हुन्छ।
      </p>
      <div className="vowel_table_div">
        <table align="center">
          <thead className="vowel_tableHeader">
            <tr>
              <th className="vowel_heading_table">Akkha</th>
              <th className="vowel_heading_table">Nepali</th>
            </tr>
          </thead>
          <tbody className="eachCells">{VowelContent}</tbody>
        </table>
      </div>
      <h1 className="mainheading">स्वर-वर्णका मात्राहरु</h1>
      <div className="vowel_table_div">
        <table align="center">
          <thead className="vowel_tableHeader">
            <tr>
              <th className="vowelmatra_heading_table">Akkha Vowels</th>
              <th className="vowelmatra_heading_table">Nepali Vowels</th>
              <th className="vowelmatra_heading_table">Akkha Matra</th>
              <th className="vowelmatra_heading_table">Nepali Matra</th>
            </tr>
          </thead>
          <tbody className="eachCells">{VowelAndSymContent}</tbody>
        </table>
      </div>
    </div>
  );
}
