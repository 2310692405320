import React, { Component } from 'react'
import VowelAakaarApp from './VowelAakaar';

export default class CoverVowelAakaar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          AutomotiveID: "amzn-assoc-ad-912ce3de-5c9d-4ad3-a3a3-4743e0b32b93",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=912ce3de-5c9d-4ad3-a3a3-4743e0b32b93";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <VowelAakaarApp/>
                {/* <div id={this.state.AutomotiveID}></div>  */}
            </div>
        )
    }
}
