import React from 'react'
import "./VowelsAndSymbols.css";

function GridRowVowelsAndSymbols({
  id,
  akkha_VowelAndSym,
  kantipur_VowelAndSym,
  Akkha_matra,
  kantipur_matra
}) {
  return (
    <tr className="vowelSym_tableRow">
      <td className="akkha_vowelAndSym">{akkha_VowelAndSym}</td>
      <td className="kantipur_vowelAndSym">{kantipur_VowelAndSym}</td>
      <td className="Akkha_matra_vowelAndSym">{Akkha_matra}</td>
      <td className="kantipur_matra_vowelAndSym">{kantipur_matra}</td>
    </tr>
  );
}

export default GridRowVowelsAndSymbols;
