import React from "react";
import './Aikaar.css'
import GridRowVowelAikaar from './GridRowVowelAikaar';
import VowelAikaarData from './VowelAikaarData.json';

export default function VowelAikaar() {
  const VowelAikarContent = VowelAikaarData.map(
    ({ id, akkha_VowelAikar, kantipur_VowelAikar, Nep_Meaning, Eng_Meaning }) => (
      <GridRowVowelAikaar
        id={id}
        akkha_VowelAikar={akkha_VowelAikar}
        kantipur_VowelAikar={kantipur_VowelAikar}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );
  return (
    <div className="Vowel_Aikar_Container">
      <h3 className="Vowel_Aikar_Heading">
        {/* <p className="matra">q</p> */}
        "ै" ऐकारलाई प्रयोग गर्दा बनिने शब्दहरु:</h3>
      <div className="Aikar_table_div">
        <table align="center">
        <thead className="Aikar_tableHeader">
            <tr>
              <th className="Aikar_heading_table">Akkha</th>
              <th className="Aikar_heading_table">Nepali Trans</th>
              <th className="Aikar_heading_table">Nepali Meaning</th>
              <th className="Aikar_heading_table">English Meaning</th>
            </tr>
          </thead>
          <tbody className="eachCells">{VowelAikarContent}</tbody>
        </table>
      </div>
    </div>
  );
}
