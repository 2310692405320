import React from "react";
import './Aakaar.css'
import GridRowVowelAakaar from './GridRowVowelAakaar';
import VowelAakaarData from './VowelAakaarData.json';

export default function VowelAakaarApp() {
  const VowelAakarContent = VowelAakaarData.map(
    ({ id, akkha_VowelAakar, kantipur_VowelAakar, Nep_Meaning, Eng_Meaning }) => (
      <GridRowVowelAakaar
        id={id}
        akkha_VowelAakar={akkha_VowelAakar}
        kantipur_VowelAakar={kantipur_VowelAakar}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );
  return (
    <div 
    className="Vowel_Aakar_Container"
    >
      <h3 className="Vowel_Aakar_Heading">
        {/* <p className="matra">q</p> */}
        "ा" आकारलाई प्रयोग गर्दा बनिने शब्दहरु:</h3>
      <div className="Aakar_table_div">
        <table align="center">
        <thead className="Aakar_tableHeader">
            <tr>
              <th className="Aakar_heading_table">Akkha</th>
              <th className="Aakar_heading_table">Nepali Trans</th>
              <th className="Aakar_heading_table">Nepali Meaning</th>
              <th className="Aakar_heading_table">English Meaning</th>
            </tr>
          </thead>
          <tbody className="eachCells">{VowelAakarContent}</tbody>
        </table>
      </div>
    </div>
  );
}
