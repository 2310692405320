// image should be of 0:2.7 ratio with dimension of 950x350 pixel

import snacks from './snacks.jpg'
import graze from './graze.jpg'
import garlic from './garlic.jpg'
import hide from './Hide.jpg'
import sale from './sale.jpg'

const snackLink1 =
  'https://unsplash.com/@gillyberlin?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
const snackLink2 =
  'https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
const snackLink_Photographer = 'Gilly'

const grazeLink1 =
  'https://unsplash.com/@calepinaud?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
const grazeLink2 =
  'https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
const grazeLink_Photographer = 'Camilo Pinaud'

const garlicLink1 =
  'https://unsplash.com/@izgubljenausvemiru?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
const garlicLink2 =
  'https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
const garlicLink_Photographer = 'Tijana Drndarski'

const hideLink1 =
  'https://unsplash.com/@bady?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
const hideLink2 =
  'https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
const hideLink_Photographer = 'Bady abbas'

const saleLink1 =
  'https://unsplash.com/@belart84?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
const saleLink2 =
  'https://unsplash.com/?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText'
const saleLink_Photographer = 'Artem Beliaikin'

const snackText = 'arnE'
const snacks_Neptrans = '(अरनी)'
const snacks_Meaning = 'Snacks (खाजा/नास्ता)'

const grazeText = 'aArCAkE'
const graze_Neptrans = '(आर्छाकी)'
const graze_Meaning = 'Graze (चराउनु)'

const garlicText = 'aAryAk'
const garlic_Neptrans = '(आर्याक)'
const garlic_Meaning = 'Garlic (लसुन)'

const hideText = 'aArcSyYkE'
const hide_Neptrans = '(आर्च्युकी)'
const hide_Meaning = 'Hide (लुक्नु)'

const saleText = 'aArlAkE'
const sale_Neptrans = '(आर्लाकी)'
const sale_Meaning = 'Sale (विक्री गर्नु)'

export const media = [snacks, graze, garlic, hide, sale]
export const mediaByIndex = (index) => media[index % media.length]

export const link1 = [snackLink1, grazeLink1, garlicLink1, hideLink1, saleLink1]
export const linkByIndex1 = (index) => link1[index % link1.length]

export const link2 = [snackLink2, grazeLink2, garlicLink2, hideLink2, saleLink2]
export const linkByIndex2 = (index) => link2[index % link2.length]

export const linkPhotographer = [
  snackLink_Photographer,
  grazeLink_Photographer,
  garlicLink_Photographer,
  hideLink_Photographer,
  saleLink_Photographer,
]
export const linkPhotographerByIndex = (index) =>
  linkPhotographer[index % linkPhotographer.length]

export const text = [snackText, grazeText, garlicText, hideText, saleText]
export const TextkByIndex = (index) => text[index % text.length]

export const nep_trans = [
  snacks_Neptrans,
  graze_Neptrans,
  garlic_Neptrans,
  hide_Neptrans,
  sale_Neptrans,
]
export const TextkByIndex1 = (index) => nep_trans[index % nep_trans.length]

export const meaning = [
  snacks_Meaning,
  graze_Meaning,
  garlic_Meaning,
  hide_Meaning,
  sale_Meaning,
]
export const MeaningByIndex = (index) => meaning[index % meaning.length]
