import React from "react";
import "./AllVowelWords.css";

function GridRowAllVowelWords({
  id,
  akkha_AllVowelWords,
  kantipur_AllVowelWords,
  Nep_Meaning,
  Eng_Meaning
}) {
  return (
    <tr className="AllVowelWords_tableRow">
      <td className="akkha_AllVowelWords">{akkha_AllVowelWords}</td>
      <td className="kantipur_AllVowelWords">{kantipur_AllVowelWords}</td>
      <td className="nep_meaning_AllVowelWords">{Nep_Meaning}</td>
      <td className="eng_meaning_AllVowelWords">{Eng_Meaning}</td>
    </tr>
  );
}
export default GridRowAllVowelWords;
