import "./MessageStyles.css";
import React, { Component } from "react";
import ReactModal from "react-modal";
import Divider from '@material-ui/core/Divider'

ReactModal.defaultStyles.overlay.backgroundColor = "#334756";
ReactModal.defaultStyles.overlay.zIndex = "9999";
ReactModal.defaultStyles.overlay.overflow = "auto";
ReactModal.defaultStyles.overlay.outline = "none";
ReactModal.defaultStyles.overlay.WebkitOverflowScrolling = "touch";
ReactModal.defaultStyles.overlay.maxWidth = "1190px";
ReactModal.defaultStyles.overlay.maxHeight = "900px";
ReactModal.defaultStyles.overlay.margin = "auto";
// ReactModal.defaultStyles.overlay.marginRight = "auto";
ReactModal.defaultStyles.overlay.borderRadius = "15px";
ReactModal.defaultStyles.overlay.boxShadow = "0 0 20px 2px #fff";

export default class Card extends Component {
  constructor() {
    super();
    this.state = {
      showModal: false,
    };
    this.handleOpenModal = this.handleOpenModal.bind(this);

    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  handleOpenModal() {
    this.setState({ showModal: true });
  }
  handleCloseModal() {
    this.setState({ showModal: false });
  }
  render() {
    return (
      <div className="card">
        <img src={this.props.img} alt="" className="card_image" />
        <div className="card-body">
          <p className="shortpara">
            {this.props.shortPara}
            <button className="open_message_button" onClick={this.handleOpenModal}>Read More...</button>

            <p className="author">{this.props.author}</p>

            <ReactModal
              isOpen={this.state.showModal}
              // contentLabel="Modal #1 Global Style Override Example"
              onRequestClose={this.handleCloseModal}
              className="modalContainer"
            >
              <p className="date">Date: {this.props.date}</p>
              <p className="body_para">{this.props.longParaGreet}</p>
              <p className="long_body_para">{this.props.longParaBody}</p>
              <p className="long_body_paraWish">{this.props.LongParaWish}</p>
              <p className="modal_author">{this.props.author}</p>
              <p className="modal_title">{this.props.title}</p>
              <p className="modal_address">{this.props.address}</p>
              <Divider className="dividerColor"/>
              <button className="close_message_button" onClick={this.handleCloseModal}>Close Message</button>
            </ReactModal>
          </p>
        </div>
      </div>
    );
  }
}
