import React from "react";
import './Months.css'
import Months_Data from './MonthsData.json';
import GridRowMonthsWord from "./GridRowMonths";


export default function MonthsApp() {
  const MonthsContent = Months_Data.map(
    ({ id, akkha_Words, Nep_Words, Nep_Meaning, Eng_Meaning }) => (
      <GridRowMonthsWord
        id={id}
        akkha_Words={akkha_Words}
        Nep_Words={Nep_Words}
        Nep_Meaning={Nep_Meaning}
        Eng_Meaning={Eng_Meaning}
      />
    )
  );
  return (
    <div className="Months_bcg">
      <h2 className="basicTerms_headingText">12 Months (महिनाका नाम)</h2>
      <div className="basicTerms_table_div">
        <table align="center">
        <thead className="basicTerms_tableHeader">
            <tr>
              <th className="months_heading_table">Akkha Words</th>
              <th className="months_heading_table">Nepali Trans</th>
              <th className="months_heading_table">Nepali Meaning</th>
              <th className="months_heading_table">English Meaning</th>
            </tr>
          </thead>
          <tbody className="Half-Forms_second_eachCells">{MonthsContent}</tbody>
        </table>
      </div>
    </div>
  );
}
