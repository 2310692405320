import React from "react";
import Exercise4AccordionApp from "./Exercise4Accordion";
import './styles.css'

export default function Exercise4App() {
  return (
    <div className="Exercise_container">
      <h3 className="Exercise_akkha_Para">
        cY yAk CAnm vSyA mOe bOe rAC. nEnE nEbA emAz dI KAtA rAhA. mAmA gYmA vWjEkE zAskE rAhA. JW emAz kYdEk zYkE. sEzW mSyArtYz fYskE mACAnlQ.</h3>
      <Exercise4AccordionApp />
    </div>
  );
}
