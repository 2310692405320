import React, { useState, useEffect, useCallback } from "react";
import { PrevButton, NextButton } from "./EmblaCarouselButtons";
import { useRecursiveTimeout } from "./useRecursiveTimeout";
import useEmblaCarousel from "embla-carousel-react";
import {
  linkByIndex1,
  linkByIndex2,
  mediaByIndex,
  // TextkByIndex,
  linkPhotographerByIndex,
  DescriptionByIndex,
  LocationByIndex,
  SourceNameByIndex
} from "../HomePage_media";
import "./embla.css";
// import { Repeat } from "@material-ui/icons";

const AUTOPLAY_INTERVAL = 6000;

const EmblaCarousel = ({ slides }) => {
  const [viewportRef, embla] = useEmblaCarousel({ skipSnaps: false });
  const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

  const autoplay = useCallback(() => {
    if (!embla) return;
    if (embla.canScrollNext()) {
      embla.scrollNext();
    } else {
      embla.scrollTo(0);
    }
  }, [embla]);

  const { play, stop } = useRecursiveTimeout(autoplay, AUTOPLAY_INTERVAL);

  const scrollNext = useCallback(() => {
    if (!embla) return;
    embla.scrollNext();
    stop();
  }, [embla, stop]);

  const scrollPrev = useCallback(() => {
    if (!embla) return;
    embla.scrollPrev();
    stop();
  }, [embla, stop]);

  const onSelect = useCallback(() => {
    if (!embla) return;
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla]);

  useEffect(() => {
    if (!embla) return;
    onSelect();
    embla.on("select", onSelect);
    embla.on("pointerDown", stop);
  }, [embla, onSelect, stop]);

  useEffect(() => {
    play();
  }, [play]);
  return (
    <div className="embla">
      <div className="embla__viewport" ref={viewportRef}>
        <div className="embla__container">
          {slides.map((index) => (
            <div className="embla__slide" key={index}>
              <div
                className="embla__slide__inner_forHomePageOnly"
                style={{ backgroundImage: `url(${mediaByIndex(index)})` }}
              >
                {/* <span className="gurb_text">{TextkByIndex(index)}</span> */}
                <span className="des_text">{DescriptionByIndex(index)}</span>
                <span className="loc_text">{LocationByIndex(index)}</span>
              </div>
              <span style={{ padding: 15, fontFamily: "monospace" }}>
                Photo by{" "}
                <a style={{ textDecoration: null }} href={linkByIndex1(index)} target="_blank" rel="noopener noreferrer">
                  {linkPhotographerByIndex(index)}
                </a>{" "}
                on <a href={linkByIndex2(index)} target="_blank" rel="noopener noreferrer">{SourceNameByIndex(index)}</a>
              </span>
            </div>
          ))}
        </div>
      </div>
      <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled} />
      <NextButton onClick={scrollNext} enabled={nextBtnEnabled} />
    </div>
  );
};

export default EmblaCarousel;
