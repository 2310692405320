import React from "react";
import PropTypes from "prop-types";
import { Link as ScrollLink } from "react-scroll";
import clsx from "clsx";
import {Link} from 'react-router-dom';
import { makeStyles } from "@material-ui/styles";
import CoverGyarakhariPage from "../../pages/GyaraKhari/Gyarakhari_Screens/CoverGyarakhari";
import CoverGyaraKhariWords from "../../pages/GyaraKhari/Gyarakhari_Screens/CoverGyarakhariWords";
import CoverGyaraKhariExampleApp from "../../pages/GyaraKhari/Gyarakhari_Screens/CoverGyarakhariExample";
import GyarakhariCarousel from "../../ImageCarousel/js/GyarakhariCarousel";
import AffiliateDisclaimer from "../../pages/AffiliateDisclaimer";

// import MusicalAdsApp from "../../AdsContainer/GourmetAdsApp";
// import AudibleAdsApp from '../../AdsContainer/AudibleAdsApp'
// import BeautyAdsApp from '../../AdsContainer/BeautyAdsApp'
// import GourmetAdsApp from '../../AdsContainer/GourmetAdsApp'
// import HealthAndPersonalCareAdsApp from '../../AdsContainer/HealthAndPersonalCareAdsApp'
// import HomeAndGardenAdsApp from '../../AdsContainer/HomeAndGardenAdsApp'
// import KindleAdsApp from '../../AdsContainer/KindleAdsApp'
// import KitchenAndHouseWareAdsApp from '../../AdsContainer/KitchenAndHouseWareAdsApp'
// import OutletAdsApp from '../../AdsContainer/OutletAdsApp'
// import PrimeSubsAds from '../../AdsContainer/PrimeSubsAds'
// import PrimeVideoAdsApp from '../../AdsContainer/PrimeVideoAdsApp'
// import SoftwareAdsApp from '../../AdsContainer/SoftwareAdsApp'

// Carousel start
const SLIDE_COUNT = 26;
const slides = Array.from(Array(SLIDE_COUNT).keys());
// Carousel end

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  linkContainer: {
    display: "flex",
    justifyContent: 'space-between',
    flexDirection: 'row',
    marginTop: 5,
    // position: "fixed"
  },
  nav_buttons : {
    padding: 5,
    borderRadius: 5,
    fontSize: 15,
    color: 'gray',
    fontWeight: "bold",
    cursor: "pointer",
  }
}));

const Gyarakhari = (props) => {
  const { className, ...rest } = props;

  const classes = useStyles();

  return (
    <div {...rest} className={clsx(classes.root, className)} id="topofpage">
      <GyarakhariCarousel slides={slides} />
      {/* <AffiliateDisclaimer /> */}
      <div className={classes.linkContainer}>
        <Link to="/Vowels" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Prev: Vowels</button>
        </Link>
        <Link to="/numbers" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Next: Numbers</button>
        </Link>
      </div>
      {/* <MusicalAdsApp/> */}
      {/* <h1 className="Gyarakhari_Heading_text">ग्यारखरी</h1>
<p className="gyarakhari_para">मगर "अक्खारिका" मा मात्राहरु सबै अक्षरमा एक रुपान्तरमा नभएकाले र यस बैज्ञानिक युगमा प्राविधिक समस्या देखापर्न गएकाले यस मगर अक्षर गाईडमा सबैको सुविधा र सहजीकरणको निम्ति उपरोक्त मात्राहरु कहाँ र कसरी राख्नु पर्ने, यसलाई सुधार गरि तल उल्लेख गरिएको छ।  तसर्थ प्रत्येक अक्षरमा प्रयोग भएका मात्रालाई ध्यान दिई मनमा राख्ने चेस्टा गर्नुहोला।</p> */}
      <CoverGyarakhariPage />
      {/* <BeautyAdsApp/> */}
      <CoverGyaraKhariWords />
      {/* <HomeAndGardenAdsApp/> */}
      <CoverGyaraKhariExampleApp />
      {/* <PrimeVideoAdsApp/> */}
      <div className={classes.linkContainer}>
        <Link to="/Vowels" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Prev: Vowels</button>
        </Link>
        <Link to="/numbers" onClick={() => window.scrollTo(0, 0)}>
          <button className={classes.nav_buttons}>Next: Numbers</button>
        </Link>
      </div>
      <ScrollLink to="topofpage" smooth={true} className="to-top-btn">
        <button className="to-top-button">Back to top ↑</button>
      </ScrollLink>
    </div>
  );
};

Gyarakhari.propTypes = {
  className: PropTypes.string,
};

export default Gyarakhari;
