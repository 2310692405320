import React, { Component } from 'react'
import Numbers from './Numbers'

export default class CoverNumberApp extends Component {
    constructor(props) {
        super(props);
        this.state = {
          patioLawnAndGardenID: "amzn-assoc-ad-81e69ff8-b5bb-42c9-95e2-24fd8f605137",
        };
      }
    
      componentDidMount() {
        const patioLawnAndGarden_script = document.createElement("script");
    
       patioLawnAndGarden_script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=81e69ff8-b5bb-42c9-95e2-24fd8f605137";
       patioLawnAndGarden_script.async = true;
    
       document.body.appendChild(patioLawnAndGarden_script);
      }
    render() {
        return (
            <div>
                <Numbers/>
                {/* <div id={this.state.patioLawnAndGardenID}></div>  */}
            </div>
        )
    }
}