import React, { Component } from 'react'
import ConsonantApp from './Consonants'

export default class CoverConsonant extends Component {
    constructor(props) {
        super(props);
        this.state = {
          primeID: "amzn-assoc-ad-a2752786-6aed-4c4f-8414-e4a63a339aed",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=a2752786-6aed-4c4f-8414-e4a63a339aed";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <ConsonantApp/>
                {/* <div id={this.state.primeID}/>  */}
            </div>
        )
    }
}
