import React from "react";
import { Link } from "react-router-dom";
import Divider from "@material-ui/core/Divider";
import "./Cur_AnnStyles.css";

export default function IndividualMessageApp(props) {
  return (
    <div className="Ind_MessAnnCard">
      <img src={props.img} alt="" className="cur_AnnCard_image" />
      <div className="message_container">
        <p style={{ color: "gray", textAlign: "center", fontWeight: "bolder" }}>
          {props.announcementType}
        </p>
        <p style={{ color: "gray", textAlign: "center" }}>{props.name}</p>
        <Divider />
        <p style={{ color: "gray", marginTop: "5px" }}>{props.message}</p>
        <p style={{ color: "gray", marginTop: "5px" }}>{props.message_para2}</p>
        <p style={{ color: "gray" }}>{props.messageFrom}</p>
        <Divider style={{ marginTop: "5px", marginBottom: "5px" }} />
        <h4 style={{ color: "darkgray" }}>संक्षिप्त विवरण:</h4>
        <p style={{ color: "gray" }}>
          <b>जन्म:</b> {props.dob}
        </p>
        <p style={{ color: "gray" }}>
          <b>जन्म स्थान:</b> {props.birthPlace}
        </p>
        <p style={{ color: "gray" }}>
          <b>सन्तान:</b> {props.children}
        </p>
        <p style={{ color: "gray" }}>
          <b>स्वर्गारोहण:</b> {props.dayOfDeath}, {props.dodTime}
        </p>
        <p style={{ color: "gray" }}>
          <b>स्वर्गारोहण स्थान:</b> {props.deathplace}
        </p>
        <p style={{ color: "gray" }}>
          <b>दाहसंस्कार/Funeral Service:</b> {props.funeralServiceDate},{" "}
          {props.funeralServiceTime}
        </p>
        <p style={{ color: "gray" }}>
          <b>दाहसंस्कार ठेगाना/Funeral Service Address:</b>{" "}
          {props.FuneralAddress}
        </p>
        <p style={{ color: "gray" }}>
          <b>सुधाई कार्यक्रम:</b> {props.sudhai}, {props.deathplace}
        </p>

        <Link to="/home" onClick={() => window.scrollTo(0, 0)}>
          <button className="back_button">Go back to home</button>
        </Link>
      </div>
    </div>
  );
}

// import "./Cur_AnnStyles.css";
// import React, { Component } from "react";
// // import { Divider } from "@mui/material";

// export default class Cur_IndividualAnnouncement extends Component {
//   render() {
//     return (
//       <div className="cur_AnnCard">
//         <img src={this.props.img} alt="" className="cur_AnnCard_image" />
//         <div>
//             <p>{this.props.announcementType}</p>
//             <p>{this.props.name}</p>
//             <p>{this.props.message}</p>
//             <p>{this.props.marriageDate}</p>
//             <p>{this.props.causeOfDeath}</p>
//             <p>{this.props.hobbies}</p>
//             {/* <h1 style={{color: 'white'}}>This is my Message</h1>
//             <Link to="/card">Go back to home</Link> */}
//         </div>
//       </div>
//     );
//   }
// }
