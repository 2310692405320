import React from "react";
import Accordion from '../Accordion'

import "../ExerciseAccordion.css";

function Exercise1AccordionApp() {
  return (
      <Accordion>
        <div className="accor">
          <div className="head noselect">Nepali Transliteration</div>
          <div className="body">रिक्च डेच गेसमा ढलिङ खायाकुङ आले।  रिक्च डेच भर्मी माडुप्न लहे लहौ ढुट फकैकी डेकी आले। खायाकुङ अघेरा लोच भरमीकै मट्टै रिक्च डेच गेसाकमा गेसाकमाकै अघेर्लाक ल्होङ्च डाङ्ले। होटाे बाटै अक्खारिका कान मगरकुङ पुर्खौ खेवाक्च आले। इसे ङासनाङ कानुङ पुर्खको ढलिङ अकल लेच ल्या डेनाङ डेकी ह्योक्ले। कानुङ मगर लिपि लोन बुडढ फुनगी डेनाङ अघेर्लाक लकीङ ल्या आटई केटनन्या डनी। आटै पट्ट  डेनाङ अघेरौ लोन बुडढए वाक्क जाटच बुडढ ढर्मौ ढुट डनी इसे अक्खा लिपियाङनै रिकनने। होटोबाटै आबकान पट्ट मगरको काट छाना ड लहौ रिटी रेवास, लहौ ढुट ड लिपि की अघेर्लाक ल्होङकीङ डेचई मिझोर जाटले।</div>
        </div>
      </Accordion>
  );
}

export default Exercise1AccordionApp;
