import React from "react";
import Exercise5AccordionApp from "./Exercise5Accordion";
import './styles.css'

export default function Exercise4App() {
  return (
    <div className="Exercise_container">
      <h3 className="Exercise_akkha_Para">
        asOk kAlEn avElQK r mhAprEnErwAn pCE vgwAn budSDkO asTE rAKQkO mAtAkA asTE klsmA akSKA rEkA pEpSrAkO sAkSy estYpbAt pSrApSt. ysIlQ hAmE mgrhrYlQ yO hAmSrO lEpE lAi bEkAskO sEKr pYryAZn awsSy mdt grnY skSC.</h3>
      <Exercise5AccordionApp />
    </div>
  );
}
