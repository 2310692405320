import React from 'react'
import KeyboardPage from '../../pages/Keyboard/keyboardApp'
// import MusicalAdsApp from '../../AdsContainer/GourmetAdsApp'
// import AudibleAdsApp from '../../AdsContainer/AudibleAdsApp'
// import BeautyAdsApp from '../../AdsContainer/BeautyAdsApp'
// import GourmetAdsApp from '../../AdsContainer/GourmetAdsApp'
// import HealthAndPersonalCareAdsApp from '../../AdsContainer/HealthAndPersonalCareAdsApp'
// import HomeAndGardenAdsApp from '../../AdsContainer/HomeAndGardenAdsApp'
// import KindleAdsApp from '../../AdsContainer/KindleAdsApp'
// import KitchenAndHouseWareAdsApp from '../../AdsContainer/KitchenAndHouseWareAdsApp'
// import OutletAdsApp from '../../AdsContainer/OutletAdsApp'
// import PrimeSubsAds from '../../AdsContainer/PrimeSubsAds'
import PrimeVideoAdsApp from '../../AdsContainer/PrimeVideoAdsApp'
import AffiliateDisclaimer from '../../pages/AffiliateDisclaimer'
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/styles";
// import SoftwareAdsApp from '../../AdsContainer/SoftwareAdsApp'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0),
  },
  linkContainer: {
    display: "flex",
    justifyContent: "flex-start",
    flexDirection: "row",
    marginTop: 5,
  },
  nav_buttons: {
    padding: 5,
    borderRadius: 5,
    fontSize: 15,
    color: "gray",
    fontWeight: "bold",
    cursor: "pointer",
  },
}));

export default function KeyboardView() {
  const classes = useStyles();
  return (
    <div>
      {/* <AffiliateDisclaimer/> */}
      {/* <PrimeVideoAdsApp/> */}
      <div
          className={classes.linkContainer}
        >
          <Link to="/readingPractice" onClick={() => window.scrollTo(0, 0)}>
            <button
              className={classes.nav_buttons}
            >
              Prev: Reading Practice
            </button>
          </Link>
        </div>
      
      <KeyboardPage/>
    </div>
  )
}
