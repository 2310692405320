import React, { Component } from 'react'
import VowelUukaar from './VowelUukaar';

export default class CoverVowelUukaar extends Component {
    constructor(props) {
        super(props);
        this.state = {
          booksAndTextbooksID: "amzn-assoc-ad-e529ae0d-bcc3-43f8-9992-be2a14fb8019",
        };
      }

    componentDidMount() {
        const script = document.createElement("script");
    
       script.src = "//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=e529ae0d-bcc3-43f8-9992-be2a14fb8019";
       script.async = true;
    
       document.body.appendChild(script);
      }
    render() {
        return (
            <div>
                <VowelUukaar/>
                {/* <div id={this.state.booksAndTextbooksID}></div>  */}
            </div>
        )
    }
}
