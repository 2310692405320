import React from "react"
import './GyaraKharistyles.css'
import GyaraKhariData from './GyaraKhariData.json';
import GridRowGyaraKhari from "./GridRowGyaraKhari";

export default function GyaraKhariApp() {
  const GyaraKhariContent = GyaraKhariData.map(
    ({ id, akkha_GyaraKhariFirstColumn, kantipur_GyaraKhariFirstColumn, Akkha_GyaraKhariSecondColumn, kantipur_GyaraKhariSecondColumn }) => (
      <GridRowGyaraKhari
        id={id}
        akkha_GyaraKhariFirstColumn={akkha_GyaraKhariFirstColumn}
        kantipur_GyaraKhariFirstColumn={kantipur_GyaraKhariFirstColumn}
        Akkha_GyaraKhariSecondColumn={Akkha_GyaraKhariSecondColumn}
        kantipur_GyaraKhariSecondColumn={kantipur_GyaraKhariSecondColumn}
      />
    )
  );

  return (
    <div className="gyaraKhari_container" id="topofpage">
      <h1 className="Gyarakhari_Heading_text">ग्यारखरी</h1>
      <p className="gyarakhari_para">मगर "अक्खारिका" मा मात्राहरु सबै अक्षरमा एक रुपान्तरमा नभएकाले र यस बैज्ञानिक युगमा प्राविधिक समस्या देखापर्न गएकाले यस मगर अक्षर गाईडमा सबैको सुविधा र सहजीकरणको निम्ति उपरोक्त मात्राहरु कहाँ र कसरी राख्नु पर्ने, यसलाई सुधार गरि तल उल्लेख गरिएको छ।  तसर्थ प्रत्येक अक्षरमा प्रयोग भएका मात्रालाई ध्यान दिई मनमा राख्ने चेस्टा गर्नुहोला।</p>
      <div className="gyaraKhari_table_div">
        <table align="center">
        <thead className="gyaraKhari_tableHeader">
            <tr>
              <th className="gyaraKhari_heading_table">Akkha</th>
              <th className="gyaraKhari_heading_table">Nepali Transliteration</th>
              <th className="gyaraKhari_heading_table">Akkha</th>
              <th className="gyaraKhari_heading_table">Nepali Transliteration</th>
            </tr>
          </thead>
          <tbody className="gyaraKhari_eachCells">{GyaraKhariContent}</tbody>
        </table>
      </div>
    </div>
  );
}
